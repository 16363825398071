import {store} from "@/store/store-decorators";
import {RootStore} from "@/store/RootStore";
import ProductionOrder from "@/domain/production/ProductionOrder";
import {reactive} from "vue";
import SalesOrder from "@/domain/production/SalesOrder";

type State = {
    salesOrders: Array<SalesOrder>;
    productionOrders: Array<ProductionOrder>;
}

@store
export default class ProductionStore {
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    private state = reactive<State>({
        salesOrders: [],
        productionOrders: []
    })

    get salesOrders(): Array<SalesOrder> {
        return this.state.salesOrders;
    }

    set salesOrders(value: Array<SalesOrder>) {
        this.state.salesOrders = value;
    }

    get productionOrders(): Array<ProductionOrder> {
        return this.state.productionOrders;
    }

    set productionOrders(value: Array<ProductionOrder>) {
        this.state.productionOrders = value;
    }
}