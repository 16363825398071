
import type {ColorVariant, TextColorVariant} from '../../types'
import BCardHeadFoot from './BCardHeadFoot.vue'
import {defineComponent} from "vue";

export default defineComponent({
  name: 'b-card-footer',
  props: {
    text: {type: String},
    bgVariant: {type: Object as () => ColorVariant},
    borderVariant: {type: Object as () => ColorVariant},
    html: {type: String},
    tag: {type: String, default: 'div'},
    textVariant: {type: Object as () => TextColorVariant}
  }
})

