export default class CostingRecalcRequest {
    public recalculateID!: number; // pk
    public proposalID!: number;
    public project!: string;
    public processState!: string;
    public transition!: string;
    public ranBy!: string;
    public componentNumber?: string;

    constructor(init?: Partial<CostingRecalcRequest>) {
        Object.assign(this, init);
    }

}