
import {computed, defineComponent, onMounted, PropType, reactive} from "vue";
import rootStore from "@/store/RootStore";
import UserService from "@/service/UserService";
import {TableDefinition} from "@/types";
import FormOrderable from "@/views/core/components/inputs/FormOrderable.vue";
import TextInput from "@/views/core/components/inputs/TextInput.vue";
import BButton from "@/components/bootstrap-library/BButton.vue";
import BModal from "@/components/bootstrap-library/BModal.vue";
import SelectInput from "@/views/core/components/inputs/SelectInput.vue";
import BCol from "@/components/bootstrap-library/BCol.vue";
import BRow from "@/components/bootstrap-library/BRow.vue";
import SaveableUITablePreferences from "@/dto/SaveableUITablePreferencesDTO";

type State = {
  show: boolean;
  columnOrder: SaveableUITablePreferences;
  tmpColumnOrderName: string;
}

export default defineComponent ({
  name: "saveable-column-form",
  components: {BRow, BCol, SelectInput, BModal, BButton, TextInput, FormOrderable},
  props: {
    modelValue: {
      type: Object as PropType<TableDefinition<any>>,
      default: undefined
    },
    dynamicColumns: {
      type: Array as PropType<Array<string>>,
      default: () => []
    }
  },
  emits: ['columnChange'],
  setup(props, context) {
    const profileStore = rootStore.getInstance().profileStore;
    const userService = new UserService();

    const userColumnOrders = computed(() => {
      return profileStore.ownTblColumnSetups.filter(cs => cs.tableName === props.modelValue?.name);
    })

    const state = reactive<State>({
      show: false,
      columnOrder: new SaveableUITablePreferences(),
      tmpColumnOrderName: ''
    })

    onMounted(() => {
      state.columnOrder.tablePreferenceId = 0;
      state.columnOrder.columns = props.dynamicColumns;
    })

    function columnChange(values: Array<string>) {
      state.show = false;
      let colOrder = [];
      for (const col of values) {
        if (state.columnOrder.columns.includes(col)) {
          colOrder.push(col);
        }
      }
      state.columnOrder.columns = colOrder;
      context.emit('columnChange', colOrder);
    }

    function toggleSaveAs() {
      state.show = !state.show;
      state.tmpColumnOrderName = state.columnOrder.tablePreferenceName;
    }

    function setSelectedColumnOrder() {
      let selectedOrder = profileStore.ownTblColumnSetups.find(cs => cs.tablePreferenceId === state.columnOrder.tablePreferenceId);
      if (selectedOrder) {
        state.columnOrder.tablePreferenceName = selectedOrder.tablePreferenceName;
        state.columnOrder.columns = selectedOrder.columns;
        context.emit('columnChange', state.columnOrder.columns);
      }
    }

    async function saveColumnOrder() {
      if (props.modelValue) {
        if (state.columnOrder.tablePreferenceName !== state.tmpColumnOrderName) {
          //add new
          state.columnOrder = new SaveableUITablePreferences({
            tablePreferenceName: state.tmpColumnOrderName,
            tablePreferenceId: 0,
            userId: profileStore.userID,
            active: true,
            tableName: props.modelValue.name,
            columns: props.dynamicColumns
          });
        } else {
          //update current state
          state.columnOrder.tableName = props.modelValue.name;
          state.columnOrder.userId = profileStore.userID;
          state.columnOrder.active = true;
        }
        const response = await userService.saveUserTableColumnOrder(state.columnOrder);
        if (response) {
          state.show = false;
        } else {
          window.alert("Column setup failed to save.");
        }
      }
    }

    async function deleteColumnOrder() {
      const selectedColumnOrder = profileStore.ownTblColumnSetups.find(cs => cs.tablePreferenceId === state.columnOrder.tablePreferenceId);
      if (selectedColumnOrder) {
        state.columnOrder = new SaveableUITablePreferences();
        let columnOrder = props.modelValue?.columnDefinition.map(cd => cd.key.toString());
        state.columnOrder.columns = columnOrder ? columnOrder : [];
        state.tmpColumnOrderName = '';
        const response = await userService.deleteTableColumnOrder(selectedColumnOrder);
        if (response) {
          state.show = false;
        }
      }
    }

    return {
      state,
      profileStore,
      userColumnOrders,
      saveColumnOrder,
      deleteColumnOrder,
      columnChange,
      toggleSaveAs,
      setSelectedColumnOrder
    }
  }
})
