
import {
    computed, defineComponent, onBeforeMount, PropType, reactive, watch,
} from 'vue';
import { Option, TableDefinition } from '@/types';
import useStringFormatter from '@/composable/useStringFormatter';
import BFormSelect from '@/components/bootstrap-library/BFormSelect.vue';
import BTable, { SidePaneOptions } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import BFormCheckbox from '@/components/bootstrap-library/BFormCheckbox.vue';
import SubHeader from '@/components/SubHeader.vue';
import {isEqual} from 'lodash';

type State = {
    tableKey: string;
    tableName: string;
    search: string;
    searchNumFrom: number | null;
    searchNumTo: number | null;
    searchKey: string;
    dynamicColumns: Array<string>;
}

type SearchType = 'string' | 'number' | 'array';

export default defineComponent({
    name: 'b-advanced-table',
    components: {
        SubHeader,
        BFormSelect,
        BTable,
        BFormCheckbox,
    },
    props: {
        instanceIdentity: {
          type: String,
          default: 'default-instance'
        },
        tableArray: {
            type: Array as () => Array<TableDefinition<any>>,
            required: true,
        },
        toggleRow: { type: Boolean, default: ()=> false },
        stickyHeader: String,
        loading: Boolean,
        dynamicColumns: { type: [Boolean], default: () => false },
        sidePaneOptions: Object as PropType<SidePaneOptions>,
        showPagination: { type: Boolean, default: false },
        perPage: {
          type: Number,
          default: () => 25,
        }
    },
    emits: ['columnChange', 'onTableChange','rowClick'],
    setup(props, context) {
        const hasSidePane = computed((): boolean => !!context.slots['side-pane'] || !!selectedTable.value.sidePane);

        const hasSidePaneHead = computed((): boolean => !!context.slots['side-pane-head']);

        const { getLabelFromFormDefinition } = useStringFormatter();

        // used to create the table dropdown select
        const tableOptions = computed((): Array<Option> => {
            const options: Array<Option> = [];

            for (const table of props.tableArray) {
                options.push({
                    text: table.name,
                    value: table.key,
                });
            }
            return options;
        });

        const hasActionSlotOrField = computed((): boolean => {
            const hasActionSlot = !!context.slots.action;
            const hasActionField = !!selectedTable.value.columnDefinition.find((def) => def.key === 'action');
            return hasActionSlot || hasActionField;
        });

        const slots = computed(() => context.slots);

        // show top row if slots are present
        const showTopRow = computed(() => !!context.slots.toprow);

        const computedStickyHeader = computed((): string | null => {
            if (props.stickyHeader) {
                let baseOffset = 2;
                if (showTopRow.value) baseOffset += 40;
                return `calc(${props.stickyHeader} - ${baseOffset}px)`;
            }
            return null;
        });

        const initialActiveTable = localStorage.getItem(`${props.instanceIdentity}-active-table`) || tableOptions.value[0].value as string;
        const state = reactive<State>({
            tableKey: initialActiveTable,
            search: '',
            searchNumFrom: null,
            searchNumTo: null,
            searchKey: '', // what key on the object we search
            tableName: props.tableArray.find((table) => table.key === initialActiveTable)?.name || tableOptions.value[0].text as string,
            dynamicColumns: [],
        });

        const selectedTable = computed((): TableDefinition<any> => {
            const tableFound = props.tableArray.find((table) => table.key === state.tableKey);
            if (tableFound) return tableFound;
            throw new Error('cannot find table');
        });

        const searchOptions = computed((): Array<Option> => {
            const options: Array<Option> = [
                {
                    value: '',
                    text: 'Select Search Option',
                },
            ];
            for (const item of selectedTable.value.columnDefinition) {
                if (item.searchable) {
                    options.push({
                        text: item.label ? item.label : formattedLabel(item.key as string),
                        value: item.key as string,
                    });
                }
            }
            return options;
        });

        const searchType = computed((): SearchType | null => {

            if (selectedTable.value && selectedTable.value.items && selectedTable.value.items.length > 0) {
                let itemTypeForSearch;

                if (Array.isArray(state.searchKey)) {
                    itemTypeForSearch = state.searchKey.reduce((prevVal, curVal) => (prevVal === null ? prevVal : prevVal[curVal]), selectedTable.value.items[0]);

                    itemTypeForSearch = typeof itemTypeForSearch;
                } else {
                    // find a populated value so we can get the type
                    const value = selectedTable.value.items.find((x) => x[state.searchKey] !== null);
                    itemTypeForSearch = typeof value[state.searchKey];
                }

                if (itemTypeForSearch === 'string') return 'string';
                if (itemTypeForSearch === 'number') return 'number';
            }

            return null;
        });

        const filteredItems = computed(() => {
            // if user filtered out all of their columns, dont show any rows
            // if (userColumnKeys.value.length === 0) return [];

            let items = [...selectedTable.value.items];

            if (state.searchKey.length > 0 && state.search.length > 0) {
                // check if there are searchable columns then proceed with filter if defined
                // prevents issue of filtering on a key that doesn't exist (with values that also don't exist for that non-existent key)
                // where no searchable fields present (else searchable keys and value reset for table or go to last saved)
                if (selectedTable.value.columnDefinition.find(colDef => colDef.searchable)) {
                  items = items.filter((item: unknown) => {
                    let itemValue;

                    if (Array.isArray(state.searchKey)) {
                      itemValue = state.searchKey.reduce((prevVal, curVal) => (prevVal === null ? prevVal : prevVal[curVal]), item);
                    } else {
                      // @ts-ignore
                      itemValue = item[state.searchKey];
                    }

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (itemValue === undefined || itemValue === null) {
                      return false;
                    }
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (itemValue.toString() && itemValue.toString().toLowerCase().includes(state.search.toLowerCase())) {
                      return true;
                    }
                  });
                }
            }

            if (state.searchKey.length > 0 && state.searchNumFrom) {
                items = items.filter((item: unknown) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (item[state.searchKey] >= state.searchNumFrom) {
                        return true;
                    }
                });
            }

            if (state.searchKey.length > 0 && state.searchNumTo) {
                items = items.filter((item: unknown) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (item[state.searchKey] <= state.searchNumTo) {
                        return true;
                    }
                });
            }

            return items;
        });

        function resetSearch() {
            state.search = '';
            state.searchNumTo = null;
            state.searchNumFrom = null;
        }

        function getTableByKey(tableKey: String): TableDefinition<any> | undefined {
          return props.tableArray.find((def) => {
            return def.key == tableKey;
          })
        }

        function onTableChange(tableKey: string) {
          state.tableName = getTableByKey(tableKey)?.name ?? ''; // Update state's table name here, as there is no binding to automatically update it when key changes
            if(!hydrateState(tableKey)){
              // init columns immediately after hydrate fails in listener. When state watcher runs after this listener, session data is created and hydrate will not fail again.
              initColumns(getTableByKey(tableKey));
            }
            context.emit('onTableChange', tableKey);
        }

        function formattedLabel(label: string): string {
            const result = label.replace(/([A-Z])/g, ' $1');
            return result.charAt(0)
                .toUpperCase() + result.slice(1);
        }

        // dynamic columns
        const allFields = computed(() => {
            const arr = [
                {
                    key: 'action',
                    label: '',
                    ignoreSort: true,
                    width: '100px',
                },
                ...selectedTable.value.columnDefinition,
            ];
            return arr;
        });

        function toggleColumn(value: boolean, key: string) {
            if (value && !state.dynamicColumns.find((x) => isEqual(key, x))) {
                state.dynamicColumns.push(key);
            }
            if (!value) {
                const index = state.dynamicColumns.findIndex((k) => isEqual(k, key));
                if (index > -1) state.dynamicColumns.splice(index, 1);
            }
            context.emit('columnChange', state.dynamicColumns);
        }

        // updates active table in session storage. Then sets state values by session storage of table, if it existed. Returns true if there was session data, else false.
        function hydrateState(tableKey?: string): boolean {
            const key = tableKey ?? state?.tableKey;
            if (key) {
                if (props.tableArray.length > 1) {
                    localStorage.setItem(`${props.instanceIdentity}-active-table`, key);
                }

                const data = localStorage.getItem(key);
                if (data) {
                    const res = JSON.parse(data);
                    state.search = res.search;
                    state.searchNumFrom = res.searchNumFrom;
                    state.searchNumTo = res.searchNumTo;
                    state.searchKey = res.searchKey;
                    state.dynamicColumns = res.dynamicColumns;
                    return true;
                }
            }
            return false;
        }

        // Sets state's columns by the table def's column defs' hidden prop (default falsy)
        function initColumns(table?: TableDefinition<any>) {
          const targetTable = table ?? selectedTable.value;
          state.dynamicColumns = targetTable.columnDefinition.filter((columnDef) => !columnDef.hidden).map((columnDef) => columnDef.key as string);
        }


        onBeforeMount(() => {
            if(!hydrateState()) {
              initColumns();
            }
        });

        watch(() => ({ ...state }), () => {
            if (state?.tableKey) {
                localStorage.setItem(state.tableKey, JSON.stringify(state));
            }
        }, { deep: true });

        const filteredFields = computed(() => {
            if (state.dynamicColumns) {
                return allFields
                    .value
                    .filter((field) => state.dynamicColumns?.findIndex((x) => isEqual(x, field.key)) > -1 || field.key === 'action');
            }
            return allFields.value;
        });

        function onChangeSearch(val: string) {
            state.search = val;
        }

        function onChangeSearchNumFrom(val: number) {
            state.searchNumFrom = val;
        }

        function onChangeSearchNumTo(val: number) {
            state.searchNumTo = val;
        }

        function rowClick(item: any) {
          context.emit('rowClick', item);
        }

        return {
            state,
            tableOptions,
            selectedTable,
            filteredItems,
            searchOptions,
            resetSearch,
            onTableChange,
            showTopRow,
            searchType,
            filteredFields,
            toggleColumn,
            getLabelFromFormDefinition,
            hasActionSlotOrField,
            computedStickyHeader,
            slots,
            hasSidePane,
            hasSidePaneHead,
            onChangeSearch,
            onChangeSearchNumFrom,
            onChangeSearchNumTo,
            isEqual,
            rowClick
        };
    },
});
