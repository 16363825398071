
import {computed, defineComponent, PropType, Ref, ref} from "vue";
import TextInput from "@/views/core/components/inputs/TextInput.vue";
import BForm from "@/components/bootstrap-library/BForm.vue";
import BCol from "@/components/bootstrap-library/BCol.vue";
import BRow from "@/components/bootstrap-library/BRow.vue";
import BButton from "@/components/bootstrap-library/BButton.vue";
import SelectInput from "@/views/core/components/inputs/SelectInput.vue";
import SaveableSearch from "@/dto/SaveableSearchDTO";
import ProposalSearchFilter from "@/components/filters/ProposalSearchFilter";
import clone from "lodash.clone";
import rootStore from "@/store/RootStore";


export default defineComponent({
  name: "SaveFilterPrompt",
  components: {SelectInput, TextInput, BForm, BCol, BRow, BButton},
  props: {
    configuredSearch: {
      type: ProposalSearchFilter,
      required: true
    },
    tableKey: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const profileStore = rootStore.getInstance().profileStore;
    const newName = ref('');
    const overwriteTargetID: Ref<number> = ref(0);

    const overwriteableFilters = computed(() => {
      return [...profileStore.ownSearches.filter((search) => {return search.tableName === props.tableKey}),
        ...profileStore.sharedSearches.filter((search) => {return (search.editable && search.tableName === props.tableKey)})]
    })

    const canOverwrite = computed(() => {
      if(overwriteableFilters.value.find((filter) => {return filter.searchID == overwriteTargetID.value})) {
        return true; // If owner or has permission to edit.
      }
      return false;
    })

    function saveNew() {
      const saveableSearch = new SaveableSearch();
      saveableSearch.searchParameters = clone(props.configuredSearch);
      saveableSearch.searchName = newName.value;
      saveableSearch.editable = true;
      saveableSearch.ownerID = profileStore.userID;
      saveableSearch.tableName = props.tableKey;
      context.emit('save', saveableSearch);
    }

    function saveExisting() {
      const overwriteTarget = overwriteableFilters.value.find((filter) => {return filter.searchID == overwriteTargetID.value});
      if(overwriteTarget) {
        const saveableSearch = clone(overwriteTarget);
        saveableSearch.searchParameters = clone(props.configuredSearch);
        context.emit('save', saveableSearch);
      }
    }



    return {
      newName,
      overwriteTargetID,
      canOverwrite,
      overwriteableFilters,
      saveNew,
      saveExisting
    }
  }
})

