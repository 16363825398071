export default class SearchByMultipleProposals {
    public daysOnHold!: string;
    public daysOpen!: string;
    public customerMarketingProgram!: string;
    public customerMarketingProgramAndOr!: string;
    public containerID!: string;
    public containerIdAndOr!: string;
    public customerName!: string;
    public customerNameAndOr!: string;
    public customerPartDesc!: string;
    public customerPartDescAndOr!: string;
    public productNumber!: string;
    public productNumberAndOr!: string;
    public designReleased!: boolean;
    public designReleasedAndOr!: string;
    public designer!: string;
    public designerAndOr!: string;
    public estimator!: string;
    public estimatorAndOr!: string;
    public plantLocation!: string;
    public plantLocationAndOr!: string;
    public fobPlant!: string;
    public fobPlantAndOr!: string;
    public proposalID!: string;
    public proposalIdAndOr!: string;
    public alternateOwner!: string;
    public alternateOwnerAndOr!: string;
    public salesRepName!: string;
    public salesRepNameAndOr!: string;
    public opportunityOwner!: string;
    public opportunityOwnerAndOr!: string;
    public location!: string;
    public locationAndOr!: string;
    public sampleStatus!: string;
    public sampleStatusAndOr!: string;
    public qaStatus!: string;
    public qaStatusAndOr!: string;
    public reasonCode!: string;
    public reasonCodeAndOr!: string;
    public filter!: string;
    public urgencyLevel!: string;
    public urgencyLevelAndOr!: string;

    constructor(init?: Partial<SearchByMultipleProposals>) {
        Object.assign(this, init);
        this.customerMarketingProgram = "";
        this.customerMarketingProgramAndOr = "and";
        this.containerID = "";
        this.containerIdAndOr = "and";
        this.customerName = "";
        this.customerNameAndOr = "and";
        this.customerPartDesc = "";
        this.customerPartDescAndOr = "and";
        this.productNumber = "";
        this.productNumberAndOr = "and";
        this.designReleased = false;
        this.designReleasedAndOr = "and";
        this.designer = "";
        this.designerAndOr = "and";
        this.estimator = "";
        this.estimatorAndOr = "and";
        this.plantLocation = "";
        this.plantLocationAndOr = "and"
        this.fobPlant = "";
        this.fobPlantAndOr = "and";
        this.proposalID = "";
        this.proposalIdAndOr = "and";
        this.alternateOwner = "";
        this.alternateOwnerAndOr = "and";
        this.salesRepName = "";
        this.salesRepNameAndOr = "and";
        this.opportunityOwner = "";
        this.opportunityOwnerAndOr = "and";
        this.location = "";
        this.locationAndOr = "and";
        this.sampleStatus = "";
        this.sampleStatusAndOr = "and";
        this.qaStatus = "";
        this.qaStatusAndOr = "and";
        this.reasonCode = "";
        this.reasonCodeAndOr = "and";
        this.urgencyLevel = "";
        this.urgencyLevelAndOr = "and";
        this.filter = "";
    }
}