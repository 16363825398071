
import {defineComponent, computed, onBeforeMount, Ref, ref, reactive} from "vue";
import rootStore from "@/store/RootStore";
import SaveableSearch, {SharedPermission} from "@/dto/SaveableSearchDTO";
import SelectInput from "@/views/core/components/inputs/SelectInput.vue";
import BTable, {BTableField} from "@/components/bootstrap-library/table/BTable/BTable.vue";
import CheckboxInput from "@/views/core/components/inputs/CheckboxInput.vue";
import BCol from "@/components/bootstrap-library/BCol.vue";
import BRow from "@/components/bootstrap-library/BRow.vue";
import clone from "lodash.clone";
import BFormSelect from "@/components/bootstrap-library/BFormSelect.vue";

export default defineComponent({
  name: "ShareFilterPrompt",
  props: {
    pageLength: {
      type: Number,
      default: 20
    },
    tableKey: {
      type: String,
      required: true
    }
  },
  components: {BFormSelect, CheckboxInput, SelectInput, BTable, BCol, BRow},
  setup(props, context) {
    const profileStore = rootStore.getInstance().profileStore;
    const userGroupStore = rootStore.getInstance().userGroupStore;
    const shareableSearches = computed( () => {
      return [...profileStore.ownSearches.filter((search) => {return search.tableName === props.tableKey})]; // Only owned searches may be shared with others. You cannot currently share a search someone else shared with you.
    })
    const targetSearchID: Ref<number> = ref(0)

    const allIndividualPermissions: Ref<{name: string, userID: number, hasAccess: boolean, canEdit: boolean}[]> = ref([]);
    const allGroupPermissions: Ref<{groupName: string, groupID: number, hasAccess: boolean, canEdit: boolean}[]> = ref([]);

    const tableKeyIndividual = "shareTableIndividual";
    const tableKeyGroup = "shareTableGroup";
    const individualsTableFields: Array<BTableField<any>> = [
      {
        key: 'name',
        label: 'Name'
      },
      {
        key: 'hasAccess',
        label: 'Read'
      },
      {
        key: 'canEdit',
        label: 'Write'
      }
    ]

    const groupsTableFields: Array<BTableField<any>> = [
      {
        key: 'groupName',
        label: 'Group'
      },
      {
        key: 'hasAccess',
        label: 'Read'
      },
      {
        key: 'canEdit',
        label: 'Write'
      }
    ]

    function populatePermissionLists() {
      userGroupStore.users.forEach((user) => {
        if(user.userID != profileStore.userID) {
          allIndividualPermissions.value.push({name: user.fullName, userID: user.userID, hasAccess: false, canEdit: false})
        }
      })
      userGroupStore.groups.forEach((group) => {
        allGroupPermissions.value.push({groupName: group.userGroupName, groupID: group.userGroupId, hasAccess: false, canEdit: false})
      })
    }

    function initPermissions(searchID: number) {
      const selectedSearch = shareableSearches.value.find((search) => search.searchID == searchID);
      clearPermissions();
      if(selectedSearch) {
        selectedSearch.sharedWith.forEach((sharedPermission) => {
          if (sharedPermission.userID && sharedPermission.userID > 0) {
            const permissionInList = allIndividualPermissions.value.find((p) => p.userID == sharedPermission.userID);
            if (permissionInList) {
              permissionInList.hasAccess = true;
              permissionInList.canEdit = sharedPermission.canEdit;
            }
          } else if (sharedPermission.groupID && sharedPermission.groupID > 0) {
            const permissionInList = allGroupPermissions.value.find((p) => p.groupID == sharedPermission.groupID);
            if (permissionInList) {
              permissionInList.hasAccess = true;
              permissionInList.canEdit = sharedPermission.canEdit;
            }
          }
        })
      }
    }

    function clearPermissions() {
      allIndividualPermissions.value.forEach((p) => {p.hasAccess = false;p.canEdit = false;})
      allGroupPermissions.value.forEach((p) => {p.hasAccess = false; p.canEdit = false;})
    }

    function onReadableChange(value: boolean, permission: {name: string, userID: number, hasAccess: boolean, canEdit: boolean} | {groupName: string, groupID: number, hasAccess: boolean, canEdit: boolean} ) {
      if(!value) {
        permission.canEdit = false;
      }
    }

    function onSubmit() {
      context.emit('share', generateSearchWithNewPermissions());
    }

    function onCancel() {
      initPermissions(targetSearchID.value); // Resets the permission of currently selected search, in case user opens window again.
      context.emit('cancel');
    }

    function generateSearchWithNewPermissions() : SaveableSearch | undefined {
      const selectedSearch = shareableSearches.value.find((search) => {return search.searchID == targetSearchID.value});
      if(selectedSearch) {
        const clonedSearch = clone(selectedSearch);
        clonedSearch.sharedWith = [];
        allIndividualPermissions.value.forEach((p) => {
          if(p.hasAccess) {
            clonedSearch.sharedWith.push({userID: p.userID, groupID: 0, canEdit: p.canEdit})
          }
        })
        allGroupPermissions.value.forEach((p) => {
          if(p.hasAccess) {
            clonedSearch.sharedWith.push({userID: 0, groupID: p.groupID, canEdit: p.canEdit})
          }
        })
        return clonedSearch;
      }
      return undefined;
    }

    onBeforeMount(() => {
      populatePermissionLists();
    })

    return {
      allIndividualPermissions,
      allGroupPermissions,
      individualsTableFields,
      groupsTableFields,
      shareableSearches,
      targetSearchID,
      initPermissions,
      onReadableChange,
      tableKeyIndividual,
      tableKeyGroup,
      onSubmit,
      onCancel
    }
  }
})
