import {store} from "@/store/store-decorators";
import {RootStore} from "@/store/RootStore";
import User from "@/domain/profile/User";
import Group from "@/domain/profile/Group";

@store
export default class UserGroupStore {

    private _users: User[] = [];
    private _groups: Group[] = [];

    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }


    get users(): User[] {
        return this._users;
    }

    set users(value: User[]) {
        this._users = value;
    }

    get groups(): Group[] {
        return this._groups;
    }

    set groups(value: Group[]) {
        this._groups = value;
    }
}