import ProductBom from "@/domain/proposal/ProductBom";
import Sample from "@/domain/proposal/Sample";
import ProposalComment from "@/domain/proposal/ProposalComment";


export default class Product {
    public productID!: number
    public productNumber!: string;
    public productDescription!: string;
    public internalComments!: string; // Deprecated
    public projectRequirements!: string; // Deprecated
    public productNumberBase!: number;
    public opportunityNumber!: number; // Deprecated
    public proposalComments!: Array<ProposalComment>;
    public productRevision!: number;

    //Product_BOM table
    public productBoms: Array<ProductBom> = [];

    //Samples table
    public samples!: Array<Sample>;

    constructor(init?: Partial<Product>) {
        Object.assign(this, init);
        // Ensure nested objects have their constructors called.
        if (init?.samples && init.samples.length > 0) this.samples = Product.initSamples(init.samples);
        if (init?.productBoms && init.productBoms.length > 0) this.productBoms = Product.initBoms(init.productBoms);
        if(init?.proposalComments && init.proposalComments.length > 0) this.proposalComments = Product.initComments(init.proposalComments);
    }

    private static initSamples(samples: Array<Sample>): Array<Sample> {
        const array: Array<Sample> = [];
        for (const sample of samples) {
            array.push(new Sample(sample))
        }
        return array;
    }

    private static initBoms(boms: Array<ProductBom>): Array<ProductBom> {
        const array: Array<ProductBom> = [];
        for (const bom of boms) {
            array.push(new ProductBom(bom))
        }
        return array;
    }

    private static initComments(comments: Array<ProposalComment>): Array<ProposalComment> {
        const array: Array<ProposalComment> = [];
        for (const comment of comments) {
            array.push(new ProposalComment(comment));
        }
        return array;
    }

}
