
import { computed, defineComponent, ref } from 'vue';
// TODO: Compare for merging with Brian
export default defineComponent({
    name: 'b-pagination',
    props: {
        modelValue: { type: Number, default: () => 1 },
        totalRows: { type: Number, required: true },
        perPage: { type: Number, default: () => 25 },
        limit: {type: Number, default: 7}, // how many page numbers & ellipses can be displayed total
    },
    emits: ['update:modelValue'],
    setup(props, context) {

      const frontEllipsisVisible = ref(false);
      const backEllipsisVisible = ref(false);

      // Tracks what number is displayed at the start
      const startNumber = computed(()=>{
        let lStartNumber: number = 1;
        const remainingPages: number = numberOfPages.value - props.modelValue;

        if(numberOfPages.value > props.limit) { // Ellipsis will be required
          // Front ellipsis required, back ellipsis not
          if(props.modelValue >= props.limit - 1 && remainingPages <= Math.ceil((props.limit-1)/2) ) {
            // start page should be half the limit (rounded down, not including current page) before current page. As remainingPages goes down, start another space before current page
            lStartNumber = props.modelValue - Math.floor((numberOfLinks.value-1)/2) - (Math.ceil((numberOfLinks.value-1)/2) - remainingPages);
          } else if(props.modelValue >= props.limit - 1) { // Both ellipses required
            lStartNumber = props.modelValue - Math.floor((numberOfLinks.value-1)/2);
          } // If only back ellipsis required, retain start of 1
        }

        return lStartNumber;
      });

      // Number of numbered page buttons to display (basically limit - required number of ellipses)
      const numberOfLinks = computed(() => {
        const remainingPages = numberOfPages.value - props.modelValue;

        let n;
        // If entire collection of pages fits within limit, display only buttons for each page (ellipses not required)
        if(numberOfPages.value <= props.limit) {
          n = numberOfPages.value;
          frontEllipsisVisible.value = false;
          backEllipsisVisible.value = false;
        } else { // If entire collection of pages cannot fit in limit
          // If current page is far enough to require front ellipsis and doesn't need back ellipsis
          if(props.modelValue >= props.limit - 1 && remainingPages <= Math.ceil((props.limit-1)/2) ) {
            n = props.limit - 1; // Reserve space for front ellipsis
            frontEllipsisVisible.value = true;
            backEllipsisVisible.value = false;
          } else if (props.modelValue >= props.limit - 1) { // Both ellipses present
            n = props.limit - 2; // Reserve space for both ellipses
            frontEllipsisVisible.value = true;
            backEllipsisVisible.value = true;
          } else { // Only back ellipse present
            n = props.limit - 1; // Reserve space for back ellipsis
            frontEllipsisVisible.value = false;
            backEllipsisVisible.value = true;
          }
        }
        return n;
      })

        function next() {
            if (props.modelValue < numberOfPages.value) {
                context.emit('update:modelValue', props.modelValue + 1);
            }
        }

        function previous() {
            if (props.modelValue > 1) {
                context.emit('update:modelValue', props.modelValue - 1);
            }
        }

        function first() {
            context.emit('update:modelValue', 1);
        }

        function last() {
            context.emit('update:modelValue', numberOfPages.value);
        }

        function goToNum(num: number) {
            context.emit('update:modelValue', num);
        }

        const numberOfPages = computed((): number => {
            const value = Math.ceil(props.totalRows / props.perPage);
            if (value) return value;
            return 1;
        });

        return {
            numberOfPages,
            goToNum,
            next,
            previous,
            last,
            first,
            startNumber,
            numberOfLinks,
            frontEllipsisVisible,
            backEllipsisVisible
        };
    },
});
