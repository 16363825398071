import ProdRouteItem from "@/domain/proposal/ProdRouteItem";
import ProdAmortItem from "@/domain/proposal/ProdAmortItem";
import ComponentBom from "@/domain/proposal/ComponentBom";
import PurchasedItem from "@/domain/proposal/PurchasedItem";
import editable from "@/domain/decorators/editable";

export default class ProductBom extends editable {
    public productID!: number;
    public quantity!: number;
    public componentID!: number; // Deprecated. Although a value in DB table, the relationship is also managed by Component_Bom table and we should be using that instead.
    public amortItemID!: number; // Deprecated. Although a value in DB table, the relationship is also managed by PROD_Amort_Items table and we should be using that instead.
    public bomLineID!: number;
    public productBomID = 0;
    public itemNumber?: string;
    public purchasedItemID!: number; // Deprecated
    public lineNumber!: number;
    public routeItemID!: number; // Deprecated. Although a value in DB table, the relationship is also managed by PROD_Route_Items table and we should be using that instead.

    public itemNumberDescription!: string;

    //PROD_Route_Items table
    public prodRouteItem: ProdRouteItem | null = null;

    //PROD_Amort_Items table
    public prodAmortItem: ProdAmortItem | null = null;

    //Component_BOM table
    public componentBom: ComponentBom | null = null;

    //Purchased_Items table
    public purchasedItem: PurchasedItem | null = null;

    //Material Hold Code table
    allPlantsMaterialHold!: string;
    allPlantsMaterialHoldSeverityCSS!: string;
    allPlantsMaterialHoldDescription!: string;
    plantSpecificMaterialHold!: string;
    plantSpecificMaterialHoldSeverityCSS!: string;
    plantSpecificMaterialHoldDescription!: string;

    public static ASSEMBLY_ROUTE = 'prod-route-item';
    public static AMORTIZED = 'prod-amort-item';
    public static COMBO = 'component-bom-item';
    public static PURCHASED_ITEMS = 'item-number';

    constructor(init?: Partial<ProductBom>) {
        super();
        Object.assign(this, init);

        if(init?.componentBom) this.componentBom = new ComponentBom(init.componentBom); // Ensures constructor is used
        if(init?.purchasedItem) this.purchasedItem = new PurchasedItem(init.purchasedItem); // Ensures constructor is used
        if(init?.prodRouteItem) this.prodRouteItem = new ProdRouteItem(init.prodRouteItem); // Ensures constructor is used
        if(init?.prodAmortItem) this.prodAmortItem = new ProdAmortItem(init.prodAmortItem); // Ensures constructor is used

        if(this.itemNumber) {
            this.itemNumberDescription = this.itemNumber;
        }

        if (this.prodRouteItem != null && this.prodRouteItem.description != null) {
            this.itemNumberDescription += (' | ' + this.prodRouteItem.description);
        }
        else if (this.prodAmortItem != null && this.prodAmortItem.description != null) {
            this.itemNumberDescription += (' | ' + this.prodAmortItem.description);
        }
        else if (this.componentBom != null && this.componentBom.description != null) {
            this.itemNumberDescription = (' | ' + this.componentBom.description);
        }
    }
}
