import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_card_title = _resolveComponent("b-card-title")!
  const _component_b_card_sub_title = _resolveComponent("b-card-sub-title")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.bodyTag), {
    class: _normalizeClass(["card-body", _ctx.classes])
  }, {
    default: _withCtx(() => [
      (!!_ctx.title || _ctx.$slots.title)
        ? (_openBlock(), _createBlock(_component_b_card_title, {
            key: 0,
            tag: _ctx.titleTag
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "title", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
              ])
            ]),
            _: 3
          }, 8, ["tag"]))
        : _createCommentVNode("", true),
      (!!_ctx.subTitle || !!_ctx.$slots.subTitle)
        ? (_openBlock(), _createBlock(_component_b_card_sub_title, {
            key: 1,
            tag: _ctx.subTitleTag,
            "text-variant": _ctx.subTitleTextVariant
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "subTitle", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.subTitle), 1)
              ])
            ]),
            _: 3
          }, 8, ["tag", "text-variant"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.text), 1)
      ])
    ]),
    _: 3
  }, 8, ["class"]))
}