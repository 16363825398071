import UserTag from "@/dto/UserTag";
import {reactive} from "vue";
import {RootStore} from "@/store/RootStore";
import {store} from "@/store/store-decorators";

type State = {
    taggableUsers: Array<UserTag>;
}

@store
export default class NotificationStore {
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    private state = reactive<State>({
        taggableUsers: []
    });

    set taggableUsers(value: Array<UserTag>) {
        this.state.taggableUsers = value;
    }

    get taggableUsers() {
        return this.state.taggableUsers;
    }
}