import ProfileStore from "@/store/ProfileStore";
import {CreateStore} from "@/store/store-decorators";
import LoadingStore from "@/store/LoadingStore";
import ProposalStore from "@/store/ProposalStore";
import MaintenanceStore from "@/store/MaintenanceStore";
import AttentionReasonStore from "@/store/AttentionReasonStore";
import NotificationStore from "@/store/NotificationStore";
import UserGroupStore from "@/store/UserGroupStore";
import DesignQueueStore from "@/store/DesignQueueStore";
import UserLocationStore from "@/store/UserLocationStore";
import SidebarLogStore from "@/views/sidebar/store/SidebarLogStore";
import ProductionStore from "@/store/ProductionStore";


export class RootStore {
    profileStore = new ProfileStore(this);
    loadingStore = new LoadingStore(this);
    proposalStore = new ProposalStore(this);
    maintenanceStore = new MaintenanceStore(this);
    attentionReasonStore = new AttentionReasonStore(this);
    notificationStore = new NotificationStore(this);
    userGroupStore = new UserGroupStore(this);
    designQueueStore = new DesignQueueStore(this);
    userLocationStore = new UserLocationStore(this);
    sidebarLogStore = new SidebarLogStore(this);
    productionStore = new ProductionStore(this);
}

const rootStore = new CreateStore<RootStore>(new RootStore());
export default rootStore;
