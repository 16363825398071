import WorkflowGARule from "@/domain/maintenance/workflow/WorkflowGARule";
import WorkflowCondition from "@/domain/maintenance/workflow/WorkflowCondition";

export default class WorkflowRuleDTO {
    public workflowGARules!: Array<WorkflowGARule>;
    public workflowConditions!: Array<WorkflowCondition>;

    constructor(init?: Partial<WorkflowRuleDTO>) {
        Object.assign(this, init);
    }
}