import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass(_ctx.classes)
  }, {
    default: _withCtx(() => [
      (!!_ctx.html)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: _ctx.html
          }, null, 8, _hoisted_1))
        : _renderSlot(_ctx.$slots, "default", { key: 1 }, () => [
            _createTextVNode(_toDisplayString(_ctx.text), 1)
          ])
    ]),
    _: 3
  }, 8, ["class"]))
}