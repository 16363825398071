

import Proposal from "@/domain/proposal/Proposal";
import {computed, defineComponent, onBeforeMount, h, defineAsyncComponent, ref, reactive} from "vue";
import VersionService from "@/service/VersionService";
import {ColumnDefinition, TableDefinition} from "@/types";
import BFormSelectOption from "@/components/bootstrap-library/BFormSelectOption.vue";
import CheckboxInput from "@/views/core/components/inputs/CheckboxInput.vue";
import ComboBoxInput from "@/views/core/components/inputs/ComboBoxInput.vue";
import SelectInput from "@/views/core/components/inputs/SelectInput.vue";
import TextInput from "@/views/core/components/inputs/TextInput.vue";
import BButton from "@/components/bootstrap-library/BButton.vue";
import {useFilterSearch} from "@/components/filters/UseFilterSearch";
import ProposalSearchFilter from "@/components/filters/ProposalSearchFilter";
import WarningIcon from "@/components/WarningIcon.vue";
import rootStore from "@/store/RootStore";
import { GetResultSetWithCountDTO } from "@/service/api/types/ApiResponseTypes";
import SaveFilterPrompt from "@/components/filters/SaveFilterPrompt.vue";
import SaveableSearch from "@/dto/SaveableSearchDTO";
import BModal from "@/components/bootstrap-library/BModal.vue";
import SearchService from "@/service/SearchService";
import ShareFilterPrompt from "@/components/filters/ShareFilterPrompt.vue";
import OrderableAdvancedTable from "@/views/core/components/OrderableAdvancedTable.vue";
import ProposalSearchResultDTO from "@/dto/ProposalSearchResultDTO";
import router from "@/router";

type State = {
  tab: string;
  searchValueOneProposal: string;
}

export default defineComponent({
  name: "AdvancedSearch",
  props: {
    actionName: {
      type: String,
      default: 'Select'
    }
  },
  components: {
    OrderableAdvancedTable,
    ShareFilterPrompt,
    SaveFilterPrompt, CheckboxInput, BFormSelectOption, BButton, TextInput, SelectInput, ComboBoxInput, BModal},
  emits: ['select'],
  setup(props, context) {
    const showSavePrompt = ref(false);
    const showSharePrompt = ref(false);
    const searchService = new SearchService();
    const versionService = new VersionService();

    const version = ref("");
    const busy = ref(false);

    const profileStore = rootStore.getInstance().profileStore;

    const state = reactive<State>({
      searchValueOneProposal: '',
      tab: 'multiple'
    })

    onBeforeMount(() => {
      let searchTab = sessionStorage.getItem('search-tab');
      if (searchTab) {
        state.tab = searchTab;
      } else {
        state.tab = 'multiple';
      }
    })

    const asyncAdvancedFilter = defineAsyncComponent(() =>
        import('@/components/filters/ProposalAdvancedFilters.vue'));

    const {
      filterState, isFilterDefault, isFilterDirty, reset, state: filterSearchState, submit: filterSearch, recordCountWarning,
    } = useFilterSearch<ProposalSearchResultDTO, ProposalSearchFilter>({
      tableKey: 'ProposalData',
      defaultFilters: new ProposalSearchFilter(),
      searchFunction: async (filter: ProposalSearchFilter) => await proposalSearch(filter),
    });

    function handleOpenSavePrompt() {
      showSavePrompt.value = true;
    }

    function handleOpenSharePrompt() {
      showSharePrompt.value = true;
    }

    function hideSharePrompt() {
      showSharePrompt.value = false;
    }

    const table = computed((): TableDefinition<ProposalSearchResultDTO> => ({
        items: filterSearchState.resultSet,
        key: 'ProposalData',
        name: 'Search Proposals',
        columnDefinition: [
          {key: 'proposalNumber', label: 'Proposal Number', searchable: true},
          {key: 'proposalStatus', label: 'Status', searchable: true},
          {key: 'reasonCode', label: 'Reason Code', searchable: true},
          {key: 'dateRequested', label: 'Date Requested', searchable: true},
          {key: 'productNumber', label: 'Product Number', searchable: true},
          {key: 'customerName', label: 'Customer Name', searchable: true},
          {key: 'customerPartDesc', label: 'Customer Part Description', searchable: true},
          {key: 'designer', label: 'Designer', searchable: true},
          {key: 'estimator', label: 'Estimator', searchable: true},
          {key: 'attentionRequiredBy', label: 'Action Required By', searchable: true},
          {key: 'sampleLocation', label: 'Sample Location', searchable: true},
          {key: 'sampleStatus', label: 'Sample Status', searchable: true},
          {key: 'holdCode', label: 'Proposal Hold', searchable: true},
          {key: 'daysOpen', label: 'Days Open', hidden: true},
          {key: 'productRev', label: 'Product Rev', hidden: true},
          {key: 'estimatorDueDate', label: 'Estimator Due Date', hidden: true},
          {key: 'designerDueDate', label: 'Designer Due Date', hidden: true},
          {key: 'autoBuild', label: 'AutoBuild', hidden: true},
          {key: 'containerId', label: 'Container', hidden: true},
          {key: 'sampleRequired', label: 'Sample Req', hidden: true},
          {key: 'promisedDate', label: 'Promise Date', hidden: true},
          {key: 'plant', label: 'Plant', hidden: true},
          {key: 'fobPlant', label: 'FOB Plant', hidden: true},
          {key: 'designReleased', label: 'Design Released', hidden: true},
          {key: 'customerOrderReceived', label: 'Customer Order Recd', hidden: true},
          {key: 'designComplete', label: 'Design Complete', hidden: true},
          {key: 'releasedToProd', label: 'Released To Production', hidden: true},
          {key: 'outsideProcess', label: 'Outside Process', hidden: true},
          {key: 'urgencyCode', label: 'Urgency', hidden: true},
          {key: 'alternateOwner', label: 'Sales Engineer', hidden: true},
          {key: 'opportunityOwner', label: 'Ram/Sam', hidden: true},
          {key: 'qty1', label: 'Qty 1', hidden: true},
          {key: 'qty2', label: 'Qty 2', hidden: true},
          {key: 'qty3', label: 'Qty 3', hidden: true},
          {key: 'qty4', label: 'Qty 4', hidden: true},
          {key: 'qty5', label: 'Qty 5', hidden: true},
          {key: 'cost1', label: 'Cost 1', hidden: true},
          {key: 'cost2', label: 'Cost 2', hidden: true},
          {key: 'cost3', label: 'Cost 3', hidden: true},
          {key: 'cost4', label: 'Cost 4', hidden: true},
          {key: 'cost5', label: 'Cost 5', hidden: true},
        ],
        headIcon: () => h(WarningIcon, {
          message: recordCountWarning.value,
        }),
      sidePane: () => h(asyncAdvancedFilter, {
        onSubmit: filterSearch,
        onReset: reset,
        onOpenSavePrompt: handleOpenSavePrompt,
        onOpenSharePrompt: handleOpenSharePrompt,
        modelValue: filterState.value,
        loading: busy.value,
        filterDirty: isFilterDirty.value,
        filterDefault: isFilterDefault.value
      }),
      }),
    )

    onBeforeMount(() => {
      getCurrentVersion();
      filterSearch();
    })

    function displayTab(tab: string) {
      sessionStorage.setItem('search-tab', tab);
      state.tab = tab;
    }

    // Function provided to useFilterSearch that determines search behavior.
    async function proposalSearch(filter: ProposalSearchFilter): Promise<GetResultSetWithCountDTO<ProposalSearchResultDTO>> {
      if(isFilterDefault.value || !filter) { // Using isFilterDefault to determine if filter has no parameters filled.
        return await searchService.getProposalSearchResults();
      } else {
        return await searchService.getProposalSearchResults(filter);
      }
    }

    function handleSearchProposal() {
      router.push({name: 'Proposal', params: {proposalNumber: state.searchValueOneProposal}});
    }

    async function getCurrentVersion() {
      busy.value = true;
      const response = await versionService.currentVersion();
      if (response.success) {
        version.value = response.version;
      }
      busy.value = false;
    }

    function emitSelection(proposal: Proposal) {
      context.emit('select', proposal);
    }

    async function saveSearch(saveableSearch: SaveableSearch) {
      const searchID = await searchService.saveProposalSearch(saveableSearch.toDTO());
      if(searchID > 0 ) {
        if(saveableSearch.searchID == 0) {
          saveableSearch.searchID = searchID;
          profileStore.ownSearches.push(saveableSearch); // New search was created, update locally with new ID from DB and put in store.
        }
        showSavePrompt.value = false;
      }
    }

    async function shareSearch(saveableSearch: SaveableSearch) {
      const success = await searchService.updateSearchSharePermissions(saveableSearch);
      if(success) {
        hideSharePrompt();
        const search = profileStore.ownSearches.find((s) => {return s.searchID == saveableSearch.searchID});
        if(search) {
          search.sharedWith = saveableSearch.sharedWith; // On success, update local
        }
      }
    }

    return {
      state,
      version,
      table,
      emitSelection,
      filterState,
      filterSearchState,
      showSavePrompt,
      showSharePrompt,
      hideSharePrompt,
      saveSearch,
      shareSearch,
      displayTab,
      handleSearchProposal,
    }
  }
})
