import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6874447e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group search-type-and-or input-standard-size" }
const _hoisted_2 = {
  class: "col-form-label",
  style: {"padding-top":"0px","padding-bottom":"0px"}
}
const _hoisted_3 = {
  key: 0,
  style: {"color":"red","font-size":"14px"}
}
const _hoisted_4 = { class: "combo-box-input-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_form_select_option = _resolveComponent("b-form-select-option")!
  const _component_b_col = _resolveComponent("b-col")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createBlock(_component_b_col, {
    class: "combo-box",
    cols: _ctx.cols,
    lg: _ctx.lg,
    md: _ctx.md,
    sm: _ctx.sm,
    xs: _ctx.xs
  }, {
    default: _withCtx(() => [
      _createElementVNode("fieldset", _hoisted_1, [
        _createElementVNode("legend", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.label), 1),
          (_ctx.getFlag())
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, " * "))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: "combo-box-container",
          ref: "comboBoxRef",
          style: _normalizeStyle({ flexDirection: _ctx.state.direction === 'down' ? 'column' : 'column-reverse' })
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_b_form_input, {
              type: "text",
              id: "input-box",
              style: {"margin-bottom":"0"},
              modelValue: _ctx.textInput,
              onFocus: _ctx.showList,
              onBlur: _ctx.hideList,
              autocomplete: "false",
              onChange: _ctx.emitChange,
              onInput: _ctx.onInput,
              required: _ctx.required,
              disabled: _ctx.disabled
            }, null, 8, ["modelValue", "onFocus", "onBlur", "onChange", "onInput", "required", "disabled"]),
            _createVNode(_component_b_button, {
              disabled: _ctx.disabled,
              onClick: _ctx.toggleList,
              class: "chevron-box"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: _ctx.state.isOpen ?  'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'
                  }, null, 8, ["icon"])
                ])
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _withDirectives((_openBlock(), _createElementBlock("div", {
            class: "combo-box-dropdown-container",
            key: _ctx.state.componentKey
          }, [
            (_ctx.textField && _ctx.valueField)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.filteredOptions, (option) => {
                  return (_openBlock(), _createBlock(_component_b_form_select_option, {
                    class: "dropdown-item",
                    value: option[_ctx.valueField],
                    textContent: _toDisplayString(option[_ctx.textField]),
                    onMousedown: ($event: any) => (_ctx.setInput(option[_ctx.textField]))
                  }, null, 8, ["value", "textContent", "onMousedown"]))
                }), 256))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.filteredOptions, (option) => {
                  return (_openBlock(), _createBlock(_component_b_form_select_option, {
                    class: "dropdown-item",
                    textContent: _toDisplayString(option),
                    value: option,
                    onMousedown: ($event: any) => (_ctx.setInput(option))
                  }, null, 8, ["textContent", "value", "onMousedown"]))
                }), 256))
          ])), [
            [_vShow, _ctx.state.isOpen]
          ])
        ], 4)
      ])
    ]),
    _: 1
  }, 8, ["cols", "lg", "md", "sm", "xs"])), [
    [_directive_click_outside, _ctx.hideList]
  ])
}