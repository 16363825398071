import {GeneralApiProblem, getGeneralApiProblem} from "@/service/api/types/ApiResponseProblem";
import {AxiosResponse} from "axios";
import APIResponseDTO from "@/dto/APIResponseDTO";

export type ApiDetailResponseProblem =
    | { success: boolean; error?: GeneralApiProblem | void; message?: APIResponseDTO<any>; }

export function getDetailApiProblem(response: AxiosResponse): ApiDetailResponseProblem | void {
    if (response.status) {
        return { success: false, error: getGeneralApiProblem(response), message: response.data }
    }
}