import EmailRequestDTO from "@/dto/EmailRequestDTO";
import axios, {AxiosResponse} from "axios";
import {BASE_API_URL} from "@/config/env";
import {
    GetGeneralListSuccessResult,
    GetGeneralResponseResult,
    GetSingleItemResult
} from "@/service/api/types/ApiResponseTypes";
import UserTag from "@/dto/UserTag";
import APIResponseDTO from "@/dto/APIResponseDTO";
import AirCellRules from "@/domain/maintenance/AirCellRules";

export default class NotificationApiService {

    public async sendNotification(emailRequestDTO: EmailRequestDTO): Promise<GetGeneralResponseResult> {
        const data = JSON.stringify(emailRequestDTO);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/email/notifyUser`,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if (!response.data) {
            return { success: false, body: "No response received" }
        } else {
            return response.data;
        }
    }

    public async getTaggableUsers(): Promise<GetGeneralListSuccessResult<UserTag>> {
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/email/getTaggableUsers`,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<UserTag>>(response.data);

        try {
            const items: Array<UserTag> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getTaggableUsersByGroup(groupId: number): Promise<GetGeneralListSuccessResult<UserTag>> {
        const data = JSON.stringify(groupId);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/email/getUserTagsByGroup`,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<UserTag>>(response.data);

        try {
            const items: Array<UserTag> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getUserTags(tag: string): Promise<GetGeneralListSuccessResult<UserTag>> {
        const data = JSON.stringify(tag);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/email/getUserTag`,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<UserTag>>(response.data);

        try {
            const items: Array<UserTag> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }
}