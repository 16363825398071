import axios, {AxiosResponse} from 'axios';
import {BASE_API_URL} from "@/config/env";
import APIResponseDTO from "@/dto/APIResponseDTO";

export default class VersionService {

    public async currentVersion() {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/version`,
        })
        if (!response.data) {
            return {success: false, version: "Not Found"}
        }

        const apiResponseDTO = new APIResponseDTO<string>(response.data);

        try {
            const version = apiResponseDTO.payload;
            return {success: true, version}
        } catch {
            return {success: false, version: "Failed to get version"}
        }
    }
}
