

import {computed, defineComponent, ref, watch} from 'vue';
import BFormSelect from "@/components/bootstrap-library/BFormSelect.vue";

    type Option = {
        text: string;
        value: string | number | null; // casing is manipulated so mismatched casings match
        disabled?: boolean;
    }

    export default defineComponent({
        name: 'select-input',
        components: {BFormSelect},
        props: {
            options: {
                type: Array as () => Array<any>,
                required: true,
            },
            valueField: {
                type: String,
                required: true,
            },
            textField: {
                type: [String, Array as () => Array<string>],
                required: true,
            },
            modelValue: {
                type: [String, Number, Array as () => Array<string>],
            },
            cols: String,
            lg: String,
            md: String,
            sm: String,
            xs: String,
            label: String,
            noPadding: Boolean,
            moreInfo: String,
            fontSize: {
                type: Number,
                default: 12,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            noOptionsText: {
                type: String,
                required: false,
            },
            dummyOptions: {
                type: Array as () => Array<Option>,
                required: false,
            },
            readonly: Boolean,
            multiple: Boolean,
            radioLabel: String,
            needsValue: Boolean
        },
        setup(props, context) {

            const error = computed(() => {
              let e = (props.needsValue && !props.disabled && (props.modelValue === null
                  || props.modelValue === 0 || (typeof props.modelValue === 'string'
                      && props.modelValue.length === 0))) ? "Must have a selection" : "";
              return e;
            })

            const radioOptions = [
                {
                    text: "AND",
                    value: "and"
                },
                {
                    text: "OR",
                    value: "or"
                }
            ]

            const and = "and";

            const componentModel = ref<string | number | string[] | null>(null);
            if (props.modelValue) {
                componentModel.value = props.modelValue;
                if (typeof props.modelValue === 'string') {
                    componentModel.value = (componentModel.value as string).toLowerCase().trim();
                }
            }

            watch(() => props.modelValue, () => {
              if(props.modelValue) {
                componentModel.value = props.modelValue;
                if (typeof props.modelValue === 'string') {
                  componentModel.value = (componentModel.value as string).toLowerCase().trim();
                }
              } else {
                //to handle if props.modelValue is '' otherwise dropdown selection will not update to user
                componentModel.value = '';
              }
            })

            const componentOptions = initComponentOptions()

            function initComponentOptions(): Array<Option> {
                const array: Array<Option> = [];
                for (const propOption of props.options) {
                    array.push({
                        text: createDisplayText(propOption),
                        value: formatPropValue(propOption[props.valueField]),
                        disabled: propOption.disabled,
                    })
                }
                if (props.dummyOptions) array.unshift(...props.dummyOptions)
                return array
            }

            function formatPropValue(value: string | number | null) {
                if (typeof value === 'string') return value.toLowerCase();
                return value
            }

            function createDisplayText(propOption: Array<Option>): string {
                if (Array.isArray(props.textField)) {
                    let displayText = "";
                    const length = props.textField.length
                    for (let i = 0; i < length; i++) {
                        if (i === length - 1) {
                            // @ts-ignore
                            displayText = displayText.concat(propOption[props.textField[i]])
                        } else {
                            // @ts-ignore
                            displayText = displayText.concat(propOption[props.textField[i]] + ' | ')
                        }
                    }
                    return displayText
                }
                // @ts-ignore
                return propOption[props.textField]
            }

            function emitChange(value: any) {
                context.emit('update:modelValue', value) // updates value
                context.emit('change', getOptionFromValue(value)); // emits entire option to @change
                //context.emit('update:componentModel', value);
            }

            function handleRadioChange(e: any) {
                context.emit('radioChange', e); // triggers optional function in parent
            }


            function getOptionFromValue(value: any): any {
                for (const option of props.options) {
                    if (option[props.valueField] === value) {
                        return option
                    }
                }
            }

            function isEmpty(): boolean {
                return props.options.length === 0;
            }

            return {
                error,
                componentOptions,
                componentModel,
                emitChange,
                handleRadioChange,
                isEmpty,
                radioOptions,
                and
            }
        }
    })

