// import useAlignment from './useAlignment'
// import useBooleanish from './useBooleanish'
// import {createBreadcrumb, useBreadcrumb} from './useBreadcrumb'
import useEventListener from './useEventListener'
import {
    bindGroupProps,
    getClasses,
    getGroupAttr,
    getGroupClasses,
    getInputClasses,
    getLabelClasses,
    optionToElement,
    slotsToElements,
} from './useFormCheck'
// import useFormInput, {COMMON_INPUT_PROPS} from './useFormInput'
// import {normalizeOptions} from './useFormSelect'
import useId from './useId'

export {
    // useAlignment,
    // createBreadcrumb,
    // useBreadcrumb,
    useEventListener,
    bindGroupProps,
    // useBooleanish,
    getClasses,
    getGroupAttr,
    getGroupClasses,
    getInputClasses,
    getLabelClasses,
    optionToElement,
    slotsToElements,
    // useFormInput,
    // COMMON_INPUT_PROPS,
    // normalizeOptions,
    useId,
}

export default {
    // useAlignment,
    // createBreadcrumb,
    // useBooleanish,
    // useBreadcrumb,
    useEventListener,
    bindGroupProps,
    getClasses,
    getGroupAttr,
    getGroupClasses,
    getInputClasses,
    getLabelClasses,
    optionToElement,
    slotsToElements,
    // useFormInput,
    // COMMON_INPUT_PROPS,
    // normalizeOptions,
    useId,
}