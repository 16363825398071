import axios, {AxiosResponse} from "axios";

export type GeneralApiProblem =
/**
 * Times up.
 */
    | { success: boolean; error?: "timeout"; url: string; temporary: true }
    /**
     * Cannot connect to the server for some reason.
     */
    | { success: boolean; error?: "cannot-connect"; url: string; temporary: true }
    /**
     * The server experienced a problem. Any 5xx error.
     */
    | { success: boolean; error?: "server"; url: string }
    /**
     * Unable to find that resource.  This is a 400.
     */
    | { success: boolean; error?: "bad-request"; url: string }
    /**
     * We're not allowed because we haven't identified ourself. This is 401.
     */
    | { success: boolean; error?: "unauthorized"; url: string }
    /**
     * We don't have access to perform that request. This is 403.
     */
    | { success: boolean; error?: "forbidden"; url: string }
    /**
     * Unable to find that resource.  This is a 404.
     */
    | { success: boolean; error?: "url-not-found"; url: string }
    /**
     * All other 4xx series errors.
     */
    | { success: boolean; error?: "rejected"; url: string }
    /**
     * Something truly unexpected happened. Most likely can try again. This is a catch all.
     */
    | { success: boolean; error?: "unknown"; url: string; temporary: true }
    /**
     * The data we received is not in the expected format.
     */
    | { success: boolean; error?: "bad-data" }
    | { success: boolean; error?: "no-data" }
    /**
     * The response was not able to return any data
     */
    | { success: boolean; error?: "not found" }

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */
export function getGeneralApiProblem(response: AxiosResponse): GeneralApiProblem | void {

    const url = response.config.url || "url not found";
    switch (response.status) {
        case 400:
            return {error: "bad-request", url: url, success: false, }
        case 401:
            return {error: "unauthorized", url: url, success: false, };
        case 403:
            return {error: "forbidden", url: url, success: false, };
        case 404:
            return {error: "url-not-found", url: url, success: false, };
        default:
            return {error: "rejected", url: url, success: false, }
    }
}
