import Skill, {Proficiency, QualifiedSkill} from "@/domain/maintenance/Skill";
import MomentumCustomer from "@/domain/maintenance/MomentumCustomer";
import MomentumProduct from "@/domain/MomentumProduct";

export default class User {

    public username: string = '';
    public email: string = '';
    public fullName: string = '';
    public initials: string = '';
    public salesForceName: string = '';
    public userID: number = 0;
    public userSkills: Array<QualifiedSkill> = [];
    public approvedProducts: Array<MomentumCustomer> = [];

    constructor(init?: Partial<User>) {
        if(init) Object.assign(this, init);
        if(init && init.userSkills) {
            this.userSkills = [];
            init.userSkills.forEach((skill) => {
                this.addUserSkill(new QualifiedSkill(skill)); // Ensure proper skill objects are created instead of assigning raw JSON value;
            })
        }
        if(init && init.approvedProducts) {
            this.approvedProducts = [];
            init.approvedProducts.forEach((customerJSON) => {
                this.approvedProducts.push(new MomentumCustomer(customerJSON));
            })
        }
    }


    addUserSkill(value: QualifiedSkill) {
        this.userSkills.push(value);
    }

    removeSkill(skillID: number) {
        this.userSkills = this.userSkills.filter((skill) => {
            return skill.id != skillID;
        })
    }


    /**
     * Adds a product based on a customer and product type. If this already has the customer, adds it to existing customer. Otherwise creates a new one with only the provided product.
     * @param momentumCustomer - A momentum customer object to clone from. Will not copy its product type list.
     * @param productType - A momentum product type to place inside the provided customer.
     */
    addApprovedProduct(momentumCustomer: MomentumCustomer, productType: MomentumProduct) {
        const customerExists = this.approvedProducts.filter((customer) => {
            return customer.id == momentumCustomer.id;
        }).length > 0;
        if(customerExists) {
            this.approvedProducts.forEach((customer) => {
                if(customer.id == momentumCustomer.id) {
                    customer.productTypes.push(productType);
                }
            })
        } else {
            const newCustomer = new MomentumCustomer(momentumCustomer);
            newCustomer.productTypes = [productType];
            this.approvedProducts.push(newCustomer);
        }
    }

    /**
     * Removes a product type from the approved products. If no products remain under that customer, removes the customer as well.
     * @param momentumCustomer - The customer which the product type is associated to
     * @param productType
     */
    removeApprovedProducts(momentumCustomer: MomentumCustomer, productType: MomentumProduct) {
        const customerExists = this.approvedProducts.filter((customer) => {
            return customer.id == momentumCustomer.id;
        }).length > 0;
        let removeCustomer = false;
        if(customerExists) {
            this.approvedProducts.forEach((customer) => {
                if(customer.id == momentumCustomer.id) {
                    customer.productTypes = customer.productTypes.filter((product) => {
                        return product.id != productType.id;
                    })
                    removeCustomer = customer.productTypes.length <= 0;
                }
            })
            if(removeCustomer) {
                this.approvedProducts = this.approvedProducts.filter((customer) => {
                    return customer.id != momentumCustomer.id;
                })
            }
        }

    }
}