import rootStore, {RootStore} from "@/store/RootStore";

export default class BaseService {

    protected rootStore: RootStore;

    constructor() {
        this.rootStore = rootStore.getInstance();
    }

}
