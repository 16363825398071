import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4ba06b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pBoms"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (!_ctx.state.isStale)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.componentBoms, (cmp_bom) => {
          return (_openBlock(), _createElementBlock("div", null, [
            _createElementVNode("div", {
              class: _normalizeClass({'btn-nav-sub-parent-sub': true, 'btn-nav-parent-active': _ctx.$route.fullPath.includes(cmp_bom.componentBomID.toString())}),
              onClick: ($event: any) => (_ctx.toggleAccordion(cmp_bom))
            }, [
              _createElementVNode("span", null, _toDisplayString(cmp_bom.componentNumber), 1),
              _createVNode(_component_font_awesome_icon, {
                icon: _ctx.item.icon,
                class: _normalizeClass({'rotate-chevron-90': _ctx.state.accordion.includes(cmp_bom.componentBomID), 'rotate-chevron-0': !_ctx.state.accordion.includes(cmp_bom.componentBomID), 'chevron-icon': true})
              }, null, 8, ["icon", "class"])
            ], 10, _hoisted_2),
            _createElementVNode("div", {
              class: _normalizeClass({'btn-accordion-open': true, 'accordion-content-open': _ctx.state.accordion.includes(cmp_bom.componentBomID), 'accordion-content-closed': !(_ctx.state.accordion.includes(cmp_bom.componentBomID))})
            }, [
              (_ctx.item.children)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (child) => {
                      return (_openBlock(), _createElementBlock("div", null, [
                        (child?.show )
                          ? (_openBlock(), _createElementBlock("div", {
                              key: child.key
                            }, [
                              (child?.children && child?.type===_ctx.SideBarMenuChild.TYPE.PARENT)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                    _createElementVNode("div", {
                                      class: _normalizeClass({'btn-nav-sub-parent-sub': true, 'btn-nav-parent-active': _ctx.$route.meta.parent === _ctx.item.title.toLowerCase()}),
                                      onClick: ($event: any) => (_ctx.toggleAccordion(cmp_bom))
                                    }, [
                                      _createElementVNode("span", null, _toDisplayString(child.title), 1),
                                      _createVNode(_component_font_awesome_icon, {
                                        icon: child.icon,
                                        class: _normalizeClass({'rotate-chevron-90': _ctx.state.accordion.includes(child.id), 'rotate-chevron-0': !_ctx.state.accordion.includes(child.id), 'chevron-icon': true})
                                      }, null, 8, ["icon", "class"])
                                    ], 10, _hoisted_5),
                                    _createElementVNode("div", {
                                      class: _normalizeClass({'btn-accordion-open': true, 'accordion-content-open': _ctx.state.accordion.includes(child.id), 'accordion-content-closed': !_ctx.state.accordion.includes(child.id)})
                                    }, [
                                      (child.children)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                                        : _createCommentVNode("", true)
                                    ], 2)
                                  ]))
                                : (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    onClick: ($event: any) => (_ctx.toggleNavItem(child.routeName, cmp_bom)),
                                    class: _normalizeClass({'btn-nav-sub-parent-sub-items': true,
                     'btn-nav-sub-active': _ctx.profileStore.cBomViews.includes(child.routeName) && _ctx.$route.fullPath.includes(cmp_bom.componentBomID.toString())  })
                                  }, [
                                    _createElementVNode("span", null, _toDisplayString(child.title), 1)
                                  ], 10, _hoisted_7))
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 256))
                  ]))
                : _createCommentVNode("", true)
            ], 2)
          ]))
        }), 256))
      ]))
    : _createCommentVNode("", true)
}