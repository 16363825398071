
export default class SaveableUITablePreferences {
    public tablePreferenceId: number = 0; // ID of 0 should trigger identity insert.
    public userId!: number;
    public tablePreferenceName!: string;
    public tableName!: string;
    public columns: Array<string> = [];
    public active!: boolean;

    constructor(init?: Partial<SaveableUITablePreferences>) {
        if(init) Object.assign(this, init);
    }

    public toDTO() {
        return new SaveableUITablePreferencesDTO(this);
    }
}

export class SaveableUITablePreferencesDTO {
    public tablePreferenceId!: number;
    public userId!: number;
    public tablePreferenceName!: string;
    public tableName!: string;
    public columns!: string;
    public active!: boolean;

    constructor(init: Partial<SaveableUITablePreferences>) {
        if(init.tablePreferenceId) this.tablePreferenceId = init.tablePreferenceId;
        if(init.tablePreferenceName) this.tablePreferenceName = init.tablePreferenceName;
        if(init.tableName) this.tableName = init.tableName;
        if(init.columns) {
            let columns = "";
            for (const col of init.columns) {
                columns += col + ",";
            }
            this.columns = columns.substring(0, columns.length - 1);
        }
        if(init.userId) this.userId = init.userId;
        if(init.active) this.active = init.active;
    }
}