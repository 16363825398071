import WorkCenter from "@/domain/maintenance/WorkCenter";
import editable from "@/domain/decorators/editable";

export default class ComponentRouteItem extends editable {
    routeItemID!: number;
    componentBomID!: number;
    lineNumber!: number;
    description?: string;
    workCenter!: string;
    assemblyOp!: string;
    numberRequired!: number;
    setupTime!: number;
    setupRate!: number;
    machineTime!: number;
    machineRate!: number;
    laborTime!: number;
    laborRate!: number;
    cycleCount!: number;
    text!: string;
    costQty1!: number;
    costQty2!: number;
    costQty3!: number;
    costQty4!: number;
    costQty5!: number;
    blankCalcDnd!: boolean;

    show = true; // used to refresh components

    //WorkCenters table
    workCenterObj!: WorkCenter;

    constructor(init?: Partial<ComponentRouteItem>) {
        super();
        Object.assign(this, init);
        if(init?.workCenterObj) {
            this.workCenterObj = new WorkCenter(init.workCenterObj);
        }
    }

}
