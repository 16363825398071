
import {computed, defineComponent, ref} from 'vue'

export default defineComponent({
  name: 'b-card-img',
  props: {
    alt: {type: String, default: undefined},
    bottom: {type: Boolean, default: false},
    end: {type: Boolean, default: false},
    height: {type: [Number, String]},
    left: {type: Boolean, default: false},
    right: {type: Boolean, default: false},
    src: {type: String},
    lazy: {type: Boolean, default: false},
    start: {type: Boolean, default: false},
    top: {type: Boolean, default: false},
    width: {type: [Number, String]}
  },
  setup(props, context) {

    const bottomBoolean = ref(props.bottom);
    const endBoolean = ref(props.end);
    const leftBoolean = ref(props.left);
    const rightBoolean = ref(props.right);
    const startBoolean = ref(props.start);
    const topBoolean = ref(props.top);
    const lazyBoolean = ref(props.lazy);

    const attrs = computed(() => ({
      loading: lazyBoolean.value ? 'lazy' : 'eager',
      src: props.src,
      alt: props.alt,
      width:
          (typeof props.width === 'number' ? props.width : parseInt(props.width as string, 10)) ||
          undefined,
      height:
          (typeof props.height === 'number' ? props.height : parseInt(props.height as string, 10)) ||
          undefined,
    }))

    const alignment = computed(() =>
        leftBoolean.value ? 'float-left' : rightBoolean.value ? 'float-right' : ''
    )

    const baseClass = computed(() =>
        topBoolean.value
            ? 'card-img-top'
            : rightBoolean.value || endBoolean.value
                ? 'card-img-right'
                : bottomBoolean.value
                    ? 'card-img-bottom'
                    : leftBoolean.value || startBoolean.value
                        ? 'card-img-left'
                        : 'card-img'
    )

    const classes = computed(() => ({
      [alignment.value]: !!alignment.value,
      [baseClass.value]: !!baseClass.value,
    }))

    return {
      attrs,
      classes
    }
  }
})



