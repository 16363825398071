import editable from "@/domain/decorators/editable";

export default class PurchasedItem extends editable {
    purchasedItemID = 0;
    productBomID!: number;
    number!: string;
    description!: string;
    subProposalFlag!: boolean;
    vendor!: string;
    costQty1!: number;
    costQty2!: number;
    costQty3!: number;
    costQty4!: number;
    costQty5!: number;
    quoteAttached!: boolean;
    referenceNumber!: string;
    proposalReferenceNumber!: number;
    operationUOfM!: string;
    weight!: number;
    lineNumber!: number;
    productBomLineNumber!: number; //From productBom
    quantity!: number; //From productBom
    allPlantsMaterialHold!: string;
    allPlantsMaterialHoldSeverityCSS!: string;
    allPlantsMaterialHoldDescription!: string;
    plantSpecificMaterialHold!: string;
    plantSpecificMaterialHoldSeverityCSS!: string;
    plantSpecificMaterialHoldDescription!: string;
    overrideCost!: boolean;
    comment!: string;

    constructor(init?: Partial<PurchasedItem>) {
        super()
        Object.assign(this, init)
    }

}
