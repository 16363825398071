export default class TimeZoneTranslator {
    public static toServerTime(val: string) {
        if (val) {
            const tmpDate = new Date(val);
            tmpDate.setTime(tmpDate.getTime() + tmpDate.getTimezoneOffset() * 60 * 1000);
            return tmpDate.toISOString();
        }
        return '';
    }

    public static toClientTime(val: string) {
        if (val) {
            const tmpDate = new Date(val);
            tmpDate.setTime(tmpDate.getTime() - tmpDate.getTimezoneOffset() * 60 * 1000);
            return tmpDate.toISOString();
        }
        return '';
    }
}