import axios, {AxiosResponse} from "axios";
import {BASE_API_URL} from "@/config/env";
import User from "@/domain/profile/User";
import Group from "@/domain/profile/Group";

export default class UserGroupApiService {

    public async getAllUsers(): Promise<{success: boolean, users: User[]}> {
        const response: AxiosResponse = await axios({
            method: "GET",
            url: `${BASE_API_URL}/users/get-users/all`
        });
        if(response.data) {
            return {success: true, users: response.data};
        } else {
            return {success: false, users: []};
        }
    }

    public async getAllGroups(): Promise<{success: boolean, groups: Group[]}> {
        const response: AxiosResponse = await axios({
            method: "GET",
            url: `${BASE_API_URL}/users/get-groups/all`
        });
        if(response.data) {
            return {success: true, groups: response.data};
        } else {
            return {success: false, groups: []};
        }
    }
}