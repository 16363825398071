import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27d72fee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-alert-container" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
      return (_openBlock(), _createElementBlock("div", null, [
        (notification.visible)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: ($event: any) => (notification.visible=false),
              class: "custom-alert"
            }, [
              _createElementVNode("span", null, _toDisplayString(notification.message), 1)
            ], 8, _hoisted_2))
          : _createCommentVNode("", true)
      ]))
    }), 256))
  ]))
}