
// import type {BFormTextProps} from '../../types/components'
import {computed, defineComponent, ref} from 'vue'
import type {TextColorVariant} from '../../types';

export default defineComponent({
  name: 'b-form-text',
  props: {
    id: {type: String, required: false},
    inline: {type: Boolean, default: false},
    tag: {type: String, default: 'small'},
    text: {type: String, required: false},
    textVariant: {type: Object as () => TextColorVariant, default: 'muted'}
  },
  setup(props, context) {
    const inlineBoolean = ref(props.inline);

    const classes = computed(() => ({
      'form-text': !inlineBoolean.value,
      [`text-${props.textVariant}`]: !!props.textVariant,
    }))

    const attrs = computed(() => ({
      id: props.id,
    }))

    return {
      inlineBoolean,
      classes,
      attrs
    }
  }
})
