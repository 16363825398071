import {store} from "@/store/store-decorators";
import {RootStore} from "@/store/RootStore";
import User from "@/domain/profile/User";
import UserLocationDTO from "@/dto/UserLocationDTO";
import {reactive} from "vue";
import Location from "@/domain/maintenance/Location";
import UserLocation from "@/domain/maintenance/UserLocation";

type State = {
    userLocations: Array<UserLocation>;
    locations: Array<Location>
    users: Array<User>;
}

@store
export default class UserLocationStore {
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    // state
    private state = reactive<State>({
      userLocations: [],
      locations: [],
      users: []
    })

    get userLocations(): Array<UserLocation> {
        return this.state.userLocations;
    }

    set userLocations(value: Array<UserLocation>) {
        this.state.userLocations = value;
    }

    get locations(): Array<Location> {
        return this.state.locations;
    }

    set locations(value: Array<Location>) {
        this.state.locations = value;
    }

    get users(): Array<User> {
        return this.state.users;
    }

    set users(value: Array<User>) {
        this.state.users = value;
    }
}