
// import type {BCollapseEmits, BCollapseProps} from '../types/components'
import {computed, defineComponent, onMounted, ref, toRef, watch} from 'vue'
import {Collapse} from 'bootstrap'
import {useEventListener} from './composables'
import {getId} from './utils'


export default defineComponent({
  name: 'b-collapse',
  props: {
    accordion: {type: String},
    id: {type: String, default: getId()},
    modelValue: {type: Boolean, default: false},
    tag: {type: String, default: 'div'},
    toggle: {type: Boolean, default: false},
    visible: {type: Boolean, default: false},
    isNav: {type: Boolean, default: false}
  },
  emits: ['update:modelValue', 'show', 'shown', 'hide', 'hidden'],
  setup(props, context) {
    const modelValueBoolean = ref(props.modelValue);
    const toggleBoolean = ref(props.toggle);
    const visibleBoolean = ref(props.visible);
    const isNavBoolean = ref(props.isNav);

    const element = ref<HTMLElement>()
    const instance = ref<Collapse>()
    const classes = computed(() => ({
      'show': modelValueBoolean.value,
      'navbar-collapse': isNavBoolean.value,
    }))

    const close = () => context.emit('update:modelValue', false)

    useEventListener(element, 'show.bs.collapse', () => {
      context.emit('show')
      context.emit('update:modelValue', true)
    })

    useEventListener(element, 'hide.bs.collapse', () => {
      context.emit('hide')
      context.emit('update:modelValue', false)
    })

    useEventListener(element, 'shown.bs.collapse', () => context.emit('shown'))
    useEventListener(element, 'hidden.bs.collapse', () => context.emit('hidden'))

    onMounted(() => {
      instance.value = new Collapse(element.value as HTMLElement, {
        parent: props.accordion ? `#${props.accordion}` : undefined,
        toggle: toggleBoolean.value,
      })
      if (visibleBoolean.value || modelValueBoolean.value) {
        context.emit('update:modelValue', true)
        instance.value?.show()
      }
    })

    watch(
        () => modelValueBoolean.value,
        (value) => {
          if (value) {
            instance.value?.show()
          } else {
            instance.value?.hide()
          }
        }
    )
    watch(
        () => visibleBoolean.value,
        (value) => {
          if (value) {
            context.emit('update:modelValue', !!value)
            instance.value?.show()
          } else {
            context.emit('update:modelValue', !!value)
            instance.value?.hide()
          }
        }
    )

    return {
      classes,
      isNavBoolean,
      modelValueBoolean,
      close
    }

  }
})

