import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_card = _resolveComponent("b-card")!

  return (_openBlock(), _createBlock(_component_b_card, {
    header: `${_ctx.profileStore.firstName} ${_ctx.profileStore.lastName}`
  }, _createSlots({
    default: _withCtx(() => [
      _createTextVNode(" Groups: "),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.profileStore.groups, (group, index) => {
        return (_openBlock(), _createElementBlock("span", null, [
          _createTextVNode(_toDisplayString(group.userGroupName), 1),
          (index != _ctx.profileStore.groups.length-1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, ", "))
            : _createCommentVNode("", true)
        ]))
      }), 256))
    ]),
    _: 2
  }, [
    (_ctx.hasFooterSlot)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "footer-content")
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["header"]))
}