import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-261dd4d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_b_col = _resolveComponent("b-col")!

  return (_openBlock(), _createBlock(_component_b_col, {
    id: "date-input",
    cols: _ctx.cols,
    lg: _ctx.lg,
    md: _ctx.md,
    sm: _ctx.sm,
    xs: _ctx.xs
  }, {
    default: _withCtx(() => [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_VueDatePicker, {
        modelValue: _ctx.picked,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.picked) = $event)),
          _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInput($event)))
        ],
        class: _normalizeClass([{'readonly': _ctx.readonly}]),
        "min-date": _ctx.lowerLimit,
        "max-date": _ctx.upperLimit,
        "prevent-min-max-navigation": _ctx.disableMinMaxNav,
        "enable-time-picker": _ctx.enableTimePicker,
        style: {"font-size":"10px"},
        "week-start": "0",
        "menu-class-name": 'dp-custom-menu',
        required: _ctx.required,
        timezone: "",
        "auto-apply": "",
        readonly: _ctx.readonly
      }, null, 8, ["modelValue", "class", "min-date", "max-date", "prevent-min-max-navigation", "enable-time-picker", "required", "readonly"])
    ]),
    _: 1
  }, 8, ["cols", "lg", "md", "sm", "xs"]))
}