
import SideBarMenuItem from "@/views/sidebar/domain/SideBarMenuItem";
import SideBarMenuChild from "@/views/sidebar/domain/SideBarMenuChild";
import {defineComponent, reactive} from "vue";
import SideBarNavProposalPBoms from "@/views/sidebar/SideBarNavProposalPBoms.vue";
import router from "@/router";

type State = {
  accordion: Array<number>;
}

export default defineComponent ({
  name: "sidebar-nav-proposal",
  components: {SideBarNavProposalPBoms},
  props: {
    item: {
      type: Object as () => SideBarMenuItem,
      required: true,
    }
  },
  setup(props, context){
    const state = reactive<State>({
      accordion: []
    });

    function toggleAccordion(value: number): void {
      if (state.accordion.includes(value)) {
        const index = state.accordion.indexOf(value);
        if (index !== -1) {
          state.accordion.splice(index, 1);
        }
      } else {
        state.accordion.push(value);
      }
    }

    function navAccordionClick(route: string, value:number): void {
      toggleAccordion(value);
      navClick(route);
    }

    function navClick(route: string): void {
      router.push({name: route});
    }

    return {
      state,
      SideBarMenuItem,
      SideBarMenuChild,
      navAccordionClick,
      navClick
    }
  }
})
