export default class ProjectTypeCode {

    pType!: string;
    projectTypeDesc!: string;
    prefix!: string;
    dwCostProject!: string;

    displayOptionDesc!: string;

    constructor(init?: ProjectTypeCode) {
        Object.assign(this, init);
        this.displayOptionDesc = this.prefix + ' - ' + this.projectTypeDesc;
    }
}