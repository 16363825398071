import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3208191f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", _mergeProps({
      id: _ctx.computedId,
      ref: "element",
      class: ["modal", _ctx.modalClasses],
      tabindex: "-1"
    }, _ctx.$attrs), [
      _createElementVNode("div", {
        class: _normalizeClass(["modal-dialog", _ctx.modalDialogClasses])
      }, [
        (
            !_ctx.lazyBoolean ||
            (_ctx.lazyBoolean && _ctx.lazyLoadCompleted) ||
            (_ctx.lazyBoolean && _ctx.modelValueBoolean === true)
          )
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["modal-content", _ctx.contentClass])
            }, [
              (!_ctx.hideHeaderBoolean)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["modal-header", _ctx.computedHeaderClasses])
                  }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.titleTag), {
                      class: _normalizeClass(["modal-title", _ctx.computedTitleClasses])
                    }, {
                      default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "title", {}, () => [
                          _createTextVNode(_toDisplayString(_ctx.title), 1)
                        ], true)
                      ]),
                      _: 3
                    }, 8, ["class"])),
                    (!_ctx.hideHeaderCloseBoolean)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          type: "button",
                          class: _normalizeClass(["btn-close", _ctx.computedCloseButtonClasses]),
                          "data-bs-dismiss": "modal",
                          "aria-label": _ctx.headerCloseLabel,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hide()))
                        }, [
                          _renderSlot(_ctx.$slots, "header-close", {}, undefined, true)
                        ], 10, _hoisted_2))
                      : _createCommentVNode("", true)
                  ], 2))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["modal-body", _ctx.computedBodyClasses])
              }, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ], 2),
              (!_ctx.hideFooterBoolean)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(["modal-footer", _ctx.computedFooterClasses])
                  }, [
                    _renderSlot(_ctx.$slots, "footer", {}, () => [
                      (!_ctx.okOnlyBoolean)
                        ? (_openBlock(), _createBlock(_component_b_button, {
                            key: 0,
                            type: "button",
                            class: "btn",
                            disabled: _ctx.disableCancel,
                            size: _ctx.buttonSize,
                            variant: _ctx.cancelVariant,
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hide(), _ctx.$emit('cancel')))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.cancelTitle), 1)
                            ]),
                            _: 1
                          }, 8, ["disabled", "size", "variant"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_b_button, {
                        type: "button",
                        class: "btn",
                        disabled: _ctx.disableOk,
                        size: _ctx.buttonSize,
                        variant: _ctx.okVariant,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hide(), _ctx.$emit('ok')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.okTitle), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "size", "variant"])
                    ], true)
                  ], 2))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.hideBackdropBoolean === false)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "modal-backdrop fade show",
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.noCloseOnBackdropBoolean === false && _ctx.hide()), ["prevent"]))
          }))
        : _createCommentVNode("", true)
    ], 16, _hoisted_1)
  ]))
}