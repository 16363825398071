
// import type {BFormValidFeedbackProps} from '../../types/components'
import {computed, defineComponent, ref} from 'vue'

export default defineComponent({
  name: 'b-form-valid-feedback',
  props: {
    ariaLive: {type: String, required: false},
    forceShow: {type: Boolean, default: false},
    id: {type: String, required: false},
    role: {type: String, required: false},
    text: {type: String, required: false},
    state: {type: Boolean, default: undefined},
    tag: {type: String, default: 'div'},
    tooltip: {type: Boolean, default: false}
  },
  setup(props, context) {
    const forceShowBoolean = ref(props.forceShow);
    const stateBoolean = ref(props.state);
    const tooltipBoolean = ref(props.tooltip);

    const computedShow = computed<boolean>(
        () => forceShowBoolean.value === true || stateBoolean.value === true
    )

    const classes = computed(() => ({
      'd-block': computedShow.value,
      'valid-feedback': !tooltipBoolean.value,
      'valid-tooltip': tooltipBoolean.value,
    }))

    const attrs = computed(() => ({
      'id': props.id || null,
      'role': props.role || null,
      'aria-live': props.ariaLive || null,
      'aria-atomic': props.ariaLive ? 'true' : null,
    }))

    return {
      classes,
      attrs
    }
  }
})
