
// import type {BCardProps} from '../../types/components'
import type {ClassValue, ColorVariant, TextColorVariant} from '../../types'
import {computed, defineComponent, PropType, ref, toRef} from 'vue'
import BCardImg from './BCardImg.vue'
import BCardHeader from './BCardHeader.vue'
import BCardBody from './BCardBody.vue'
import BCardFooter from './BCardFooter.vue'

export default defineComponent({
  name: 'b-card',
  props: {
    align: {type: String as PropType<'start' | 'end' | 'center' | 'fill'>},
    bgVariant: {type: Object as () => ColorVariant},
    bodyBgVariant: {type: Object as () => ColorVariant},
    bodyClass: {type: Object as () => ClassValue},
    bodyTag: {type: String, default: 'div'},
    bodyTextVariant: {type: Object as () => TextColorVariant},
    borderVariant: {type: Object as () => ColorVariant},
    footer: {type: String},
    footerBgVariant: {type: Object as () => ColorVariant},
    footerBorderVariant: {type: Object as () => ColorVariant},
    footerClass: {type: Object as () => ClassValue},
    footerHtml: {type: String, default: ''},
    footerTag: {type: String, default: 'div'},
    footerTextVariant: {type: Object as () => TextColorVariant},
    header: {type: String},
    headerBgVariant: {type: Object as () => ColorVariant},
    headerBorderVariant: {type: Object as () => ColorVariant},
    headerClass: {type: Object as () => ClassValue},
    headerHtml: {type: String, default: ''},
    headerTag: {type: String, default: 'div'},
    headerTextVariant: {type: Object as () => TextColorVariant},
    imgAlt: {type: String},
    imgBottom: {type: Boolean, default: false},
    imgEnd: {type: Boolean, default: false},
    imgHeight: {type: [String, Number]},
    imgLeft: {type: Boolean, default: false},
    imgRight: {type: Boolean, default: false},
    imgSrc: {type: String},
    imgStart: {type: Boolean, default: false},
    imgTop: {type: Boolean, default: false},
    imgWidth: {type: [String, Number]},
    noBody: {type: Boolean, default: false},
    overlay: {type: Boolean, default: false},
    subTitle: {type: String},
    subTitleTag: {type: String, default: 'h6'},
    subTitleTextVariant: {type: Object as () => TextColorVariant, default: 'muted'},
    tag: {type: String, default: 'div'},
    textVariant: {type: Object as () => TextColorVariant},
    title: {type: String},
    titleTag: {type: String, default: 'h4'},
    bodyText: {type: String, default: ''}
  },
  setup(props, context) {
    const imgBottomBoolean = ref(props.imgBottom);
    const imgEndBoolean = ref(props.imgEnd);
    const imgLeftBoolean = ref(props.imgLeft);
    const imgRightBoolean = ref(props.imgRight);
    const imgStartBoolean = ref(props.imgStart);
    const noBodyBoolean = ref(props.noBody);

    const classes = computed(() => ({
      [`text-${props.align}`]: props.align !== undefined,
      [`text-${props.textVariant}`]: props.textVariant !== undefined,
      [`bg-${props.bgVariant}`]: props.bgVariant !== undefined,
      [`border-${props.borderVariant}`]: props.borderVariant !== undefined,
      'flex-row': imgLeftBoolean.value || imgStartBoolean.value,
      'flex-row-reverse': imgEndBoolean.value || imgRightBoolean.value,
    }))

    const headerAttrs = computed(() => ({
      bgVariant: props.headerBgVariant,
      borderVariant: props.headerBorderVariant,
      html: props.headerHtml,
      tag: props.headerTag,
      textVariant: props.headerTextVariant,
    }))

    const bodyAttrs = computed(() => ({
      overlay: props.overlay,
      bodyBgVariant: props.bodyBgVariant,
      bodyTag: props.bodyTag,
      bodyTextVariant: props.bodyTextVariant,
      subTitle: props.subTitle,
      subTitleTag: props.subTitleTag,
      subTitleTextVariant: props.subTitleTextVariant,
      title: props.title,
      titleTag: props.titleTag,
    }))

    const footerAttrs = computed(() => ({
      bgVariant: props.footerBgVariant,
      borderVariant: props.footerBorderVariant,
      html: props.footerHtml,
      tag: props.footerTag,
      textVariant: props.footerTextVariant,
    }))

    const imgAttr = computed(() => ({
      src: props.imgSrc,
      alt: props.imgAlt,
      height: props.imgHeight,
      width: props.imgWidth,
      bottom: props.imgBottom,
      end: props.imgEnd,
      left: props.imgLeft,
      right: props.imgRight,
      start: props.imgStart,
      top: props.imgTop,
    }))

    return {
      classes,
      imgBottomBoolean,
      imgAttr,
      headerAttrs,
      noBodyBoolean,
      bodyAttrs,
      footerAttrs
    }
  }
})


