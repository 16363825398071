

import {computed, defineComponent, StyleValue} from "vue";
import {ColorVariant, SkeletonAnimation, SkeletonType} from "@/types";

export default defineComponent({
  name: 'b-skeleton',
  props: {
    height: {type: String},
    width: {type: String},
    size: {type: String},
    animation: {type: [String as () => SkeletonAnimation,  Boolean as () => SkeletonAnimation], default: 'wave'},
    type: {type: String as () => SkeletonType, default: 'text'},
    variant: {type: String as () => ColorVariant}
  },
  setup(props, context) {
    const classes = computed(() => [
      `b-skeleton-${props.type}`,
      {
        [`b-skeleton-animate-${props.animation}`]: typeof props.animation === 'boolean' ? false : props.animation,
        [`bg-${props.variant}`]: props.variant !== undefined
      }
    ])

    const style = computed<StyleValue>(() => ({
      width: props.size || props.width,
      height: props.size || props.height
    }))

    return {
      classes,
      style
    }
  }
})
