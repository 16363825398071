import {GetGeneralListSuccessResult, GetGeneralSuccessResult} from "@/service/api/types/ApiResponseTypes";
import axios, {AxiosResponse} from "axios";
import {BASE_API_URL} from "@/config/env";
import {SaveableUITablePreferencesDTO} from "@/dto/SaveableUITablePreferencesDTO";

export default class UserApiService {

    public async getUserOrderableTableSetups(userId: number): Promise<GetGeneralListSuccessResult<SaveableUITablePreferencesDTO>> {
        const response: AxiosResponse = await axios({
            method: 'GET',
            url: `${BASE_API_URL}/users/get-table-preferences/` + userId
        })

        try {
            const items: Array<SaveableUITablePreferencesDTO> = response.data;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async saveUserTableColumnSetup(tableColumnOrderDTO: SaveableUITablePreferencesDTO): Promise<GetGeneralListSuccessResult<SaveableUITablePreferencesDTO>> {
        const data = JSON.stringify(tableColumnOrderDTO);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/users/table-preferences/update`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        try {
            const items: Array<SaveableUITablePreferencesDTO> = response.data;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async deleteUserTableColumnOrder(tablePreferences: SaveableUITablePreferencesDTO): Promise<GetGeneralSuccessResult> {
        const data = JSON.stringify(tablePreferences);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/users/table-preferences/delete`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (!response.data) return { success: false }

        try {
            return { success: true }
        } catch {
            return { success: false }
        }
    }
}