export default class CostHistory {
    public proposalID!: number;
    public productID!: number;
    public amCost1!: number;
    public amCost2!: number;
    public amCost3!: number;
    public amCost4!: number;
    public amCost5!: number;
    public asmTimePP!: number;
    public bomCost1!: number;
    public bomCost2!: number;
    public bomCost3!: number;
    public bomCost4!: number;
    public bomCost5!: number;
    public extTTLCost1!: number;
    public extTTLCost2!: number;
    public extTTLCost3!: number;
    public extTTLCost4!: number;
    public extTTLCost5!: number;
    public purchCost1!: number;
    public purchCost2!: number;
    public purchCost3!: number;
    public purchCost4!: number;
    public purchCost5!: number;
    public routeCost1!: number;
    public routeCost2!: number;
    public routeCost3!: number;
    public routeCost4!: number;
    public routeCost5!: number;
    public toolCost!: number;
    public ttlAsmRouteCost1!: number;
    public ttlAsmRouteCost2!: number;
    public ttlAsmRouteCost3!: number;
    public ttlAsmRouteCost4!: number;
    public ttlAsmRouteCost5!: number;
    public ttlCmpRouteCost1!: number;
    public ttlCmpRouteCost2!: number;
    public ttlCmpRouteCost3!: number;
    public ttlCmpRouteCost4!: number;
    public ttlCmpRouteCost5!: number;
    public totalCost1!: number;
    public totalCost2!: number;
    public totalCost3!: number;
    public totalCost4!: number;
    public totalCost5!: number;
    public subAsmMargin1!: number;
    public subAsmMargin2!: number;
    public subAsmMargin3!: number;
    public subAsmMargin4!: number;
    public subAsmMargin5!: number;
    public recostStatus!: string;
    public dateAdded!: Date
    public exchangeRate!: number;

    constructor(init?: Partial<CostHistory>) {
        Object.assign(this, init);
    }
}