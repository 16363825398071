
import {
  computed,
  defineComponent,
  onMounted,
  onBeforeUnmount,
  PropType,
  watch, reactive,
} from 'vue';
import useTableSort, { TableSortObject } from '@/components/bootstrap-library/table/BTable/composables/useTableSort';
import useTablePagination from '@/components/bootstrap-library/table/BTable/composables/useTablePagination';
import BBadge from '@/components/bootstrap-library/BBadge.vue';
import { UseUnitOfMeasure } from '@/measurement/types';
import useTableWidth from '@/components/bootstrap-library/table/BTable/composables/useTableWidth';
import { ColumnData } from '@/components/bootstrap-library/table/BTable/ColumnData';
import router from '@/router';
// import EmptyState, { EmptyStateProps } from '@/components/EmptyState.vue';
type Field<T> = {
  // Keep in sync with ColumnDefinition until consolidated
  key: keyof T;
  label?: string;
  searchable?: boolean;
  formatter?: (value: any) => any;
  ignoreSort?: boolean;
  width?: string;
  hidden?: boolean;
  uom?: UseUnitOfMeasure;
  headerBadge?: any;
}
export type BTableField<T> = Field<T> | keyof T;
export type SidePaneOptions = {
  title?: string;
  width?: string;
  hideHeader?: boolean;
}
type State = {
  highlightedRows: Array<number>;
}
export default defineComponent({
  name: 'b-table',
  components: { BBadge },
  props: {
    items: {
      type: Array as PropType<Array<unknown>>,
      required: true
    },
    fields: {
      type: Array as PropType<Array<BTableField<unknown> | string>>,
      default: () => [],
    },
    defaultSort: {
      type: Array as PropType<Array<TableSortObject>>,
      default: () => [],
    },
    stickyHeader: {
      type: String,
    },
    striped: Boolean,
    perPage: {
      type: Number,
      default: () => 25,
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
    showEmpty: {
      type: Boolean,
      default: () => true,
    },
    addTableFooter: {
      type: Boolean,
      default: () => false,
    },
    rowHeight: {
      type: String,
      default: () => '20px',
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    dynamicColumns: {
      type: Boolean,
      default: () => true,
    },
    fitToPage: {
      type: Boolean,
      default: () => false,
    },
    sidePaneOptions: {
      type: Object as PropType<SidePaneOptions>,
      default: () => {
      },
    },
    tableKey: {
      type: String,
      default: router?.currentRoute.value?.name,
    },
    minTableHeight: {
      type: String,
      default: () => '500px'
    },
    toggleRow: {
      type: Boolean,
      default: () => false
    },
    singleSelect: {
      type: Boolean,
      default: false
    }
  },
  emits: ['rowClick'],
  setup(props, context) {
    const {
      resetSort,
      handleSort,
      sortDirection,
      sortPosition,
      sortArray,
      switchTable: switchSortTable,
    } = useTableSort({
      tableKey: props.tableKey,
      defaultSort: props.defaultSort,
    });
    const {
      currentPage,
      firstRowInPaginated,
      lastRowInPaginated,
      goToFirstPage,
      switchTable: switchPageTable,
    } = useTablePagination({
      tableKey: props.tableKey,
      perPage: props.perPage,
    });
    const state = reactive<State>({
      highlightedRows: new Array<number>
    })
    const columnKeys = computed((): Array<string> => {
      const fields: Array<string> = [];
      if (props.fields) {
        const arr = props.fields.map((field) => {
          if (typeof field === 'string') {
            return field;
          }
          return field.key;
        });
        fields.push(...arr);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fields.push(Object.keys(props.items[0]));
      }
      return fields;
    });
    watch(() => columnKeys.value, () => {
      // If any columns were removed that are part of the sort, clear the sort
      for (let i = 0; i < sortArray.value.length; i++) {
        if (columnKeys.value.find((key) => key === sortArray.value[i].key)) {
          continue;
        }
        resetSort();
        return;
      }
    });
    function camelCaseToLabel(key: string): string {
      const result = key.replace(/([A-Z])/g, ' $1');
      return result.charAt(0)
          .toUpperCase() + result.slice(1);
    }
    function getLabelByKey(key: string): string {
      if (props.fields) {
        const fieldData = props.fields.find((field) => typeof field !== 'string' && field.key === key) as Field<unknown> | undefined;
        if (fieldData && fieldData.label) {
          return fieldData.label;
        }
        return camelCaseToLabel(key);
      }
      return camelCaseToLabel(key);
    }
    const numberOfColumns = computed((): number => columnKeys.value.length);
    const columnDataArray = computed((): Array<ColumnData> => {
      const arr: Array<ColumnData> = [];
      // build column data by item data
      if (!props.fields && props.items.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Object.keys(props.items[0])
            .forEach((key, index) => {
              arr.push({
                key,
                index,
                label: getLabelByKey(key),
                width: null,
              });
            });
      } else {
        // build column data from fields
        const data = (props.fields as Array<BTableField<unknown>>).map((field, index) => {
          if (typeof field === 'string') {
            return {
              key: field,
              index,
              label: getLabelByKey(field),
              width: null,
            };
          }
          return {
            key: field.key,
            index,
            label: field.label ? field.label : getLabelByKey(field.key),
            width: field.width || null,
            uom: field.uom,
            headerBadge: field.headerBadge,
          };
        });
        if (data) arr.push(...data);
      }
      return arr;
    });
    const {
      initTableWidth,
      isTableFixed,
      getColumnWidthByKey,
      startDrag,
      stopDrag,
      isDragging,
      show,
      switchTable: switchWidthTable,
    } = useTableWidth({
      tableKey: props.tableKey,
      columnData: columnDataArray,
    });
    let resizeTimeout: number | undefined;
    function handleResize() {
      clearTimeout(resizeTimeout);
      // debounce the width calculation to avoid unnecessay redraws
      resizeTimeout = setTimeout(initTableWidth, 500);
    }
    onMounted(() => {
      state.highlightedRows = [];
      initTableWidth();
      window.addEventListener('resize', handleResize);
      window.addEventListener('mouseup', stopDrag);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mouseup', stopDrag);
      clearTimeout(resizeTimeout);
    });
    watch(() => props.tableKey, (newKey) => {
      switchSortTable(newKey);
      switchPageTable(newKey);
      switchWidthTable(newKey);
    });
    function getIgnoreSortByKey(key: string): boolean {
      if (props.fields) {
        const fieldData = props.fields.find((field) => typeof field !== 'string' && field.key === key) as Field<unknown> | undefined;
        return fieldData?.ignoreSort === true;
      }
      return false;
    }
    const headClass = computed(() => {
      if (props.stickyHeader) {
        return {
          position: 'sticky',
          top: '0px',
        };
      }
      return {};
    });
    const tableClass = computed(() => {
      if (props.stickyHeader) {
        return {
          height: `calc(${props.stickyHeader} - ${props.showPagination ? '50px' : '0px'})`,
        };
      }
      return {};
    });
    function getColumnDataByIndex(index: number): ColumnData {
      const data = columnDataArray.value.find((columnData) => columnData.index === index);
      if (data) return data;
      throw new Error('Cannot find column data');
    }
    function getColumnDataForUI(index: number, item: object): string | number {
      const data = columnDataArray.value.find((columnData) => columnData.index === index);
      if (Array.isArray(data?.key)) {
        const itemValue = data?.key.reduce((prevVal, curVal) => (prevVal === null ? prevVal : prevVal[curVal]), item);
        return itemValue;
      }
      if (data?.uom) {
        // @ts-ignore
        return data.uom.convertFromBase(item[getColumnDataByIndex(index).key]);
      }
      // TODO: Check with Brian, why do we getColumnDataByIndex here when data above already does the same?
      // TODO: Check with Brian, doesn't seem to work when key is formatted as object.property (ex: salesforceProposalData.customerMarketingProgram, where salesforceProposalData is a property of proposal entries in table)
      // @ts-ignore
      return item[getColumnDataByIndex(index).key];
    }
    function getColumnDataByKey(key: string): ColumnData {
      const data = columnDataArray.value.find((columnData) => columnData.key === key);
      if (data) return data;
      throw new Error('Cannot find column data');
    }
    function sortByTableColumn(key: string) {
      if (isDragging.value) return;
      const definition = props.fields.find((field) => typeof field !== 'string' && field.key === key);
      if (definition && typeof definition !== 'string' && definition.ignoreSort) {
        return;
      }
      handleSort(key);
    }
    function isEven(n: number): boolean {
      return n % 2 === 0;
    }
    function onRowClick(item: unknown, index: number) {
      if (props.toggleRow) {
        if (state.highlightedRows.includes(index)) {
          let ind = state.highlightedRows.indexOf(index);
          state.highlightedRows.splice(ind, 1);
        } else {
          state.highlightedRows.push(index);
        }
      } else if (props.singleSelect) {
        if(!state.highlightedRows.includes(index)) {
          state.highlightedRows = [index]
        }
      }
      context.emit('rowClick', item);
    }
    const hasRowClick = computed((): boolean => !!(context.attrs.onRowClick));
    function formatValue(key: any) {
      return typeof key === 'string' ? key.trim().toLowerCase() : key;
    }
    const filteredItems = computed(() => props.items);
    watch(() => filteredItems.value.length, () => {
      // only go back to first page if we don't have enough results
      if (firstRowInPaginated.value > filteredItems.value.length) {
        goToFirstPage();
      }
    });
    const sortedItems = computed(() => {
      const items = [...filteredItems.value];
      if (sortArray.value.length === 0) return items;
      // needs to be in reverse
      for (let i = sortArray.value.length - 1; i >= 0; i--) {
        const sortObj = sortArray.value[i];
        items.sort((a, b) => {
          let valueA;
          let valueB;
          if (Array.isArray(sortObj.key)) {
            valueA = sortObj.key.reduce(
                // @ts-ignore
                (prevVal, curVal) => (prevVal === null ? prevVal : prevVal[curVal]),
                a,
            );
            valueB = sortObj.key.reduce(
                // @ts-ignore
                (prevVal, curVal) => (prevVal === null ? prevVal : prevVal[curVal]),
                b,
            );
            valueA = formatValue(valueA);
            valueB = formatValue(valueB);
          } else {
            // @ts-ignore
            valueA = formatValue(a[sortObj.key]);
            // @ts-ignore
            valueB = formatValue(b[sortObj.key]);
          }
          if (valueA === null || valueA === undefined) {
            return 1;
          }
          if (valueB === null || valueB === undefined) {
            return -1;
          }
          if (valueA < valueB) {
            return sortObj.direction === 'descending' ? -1 : 1;
          }
          if (valueA > valueB) {
            return sortObj.direction === 'descending' ? 1 : -1;
          }
          return 0;
        });
      }
      return items;
    });
    const paginatedItems = computed(() => {
      if (props.showPagination) {
        return sortedItems.value.filter((item, index) => {
          const rowNumber = index + 1;
          if (rowNumber >= firstRowInPaginated.value && rowNumber <= lastRowInPaginated.value) {
            return item;
          }
        });
      }
      return sortedItems.value;
    });
    const hasSidePane = computed(() => !!context.slots['side-pane']);
    const computedSidePaneOptions = computed((): SidePaneOptions => ({
      width: props.sidePaneOptions?.width || '300px',
      title: props.sidePaneOptions?.title || 'Side Pane',
      hideHeader: props.sidePaneOptions?.hideHeader || false,
    }));
    return {
      columnKeys,
      numberOfColumns,
      getColumnDataByIndex,
      getColumnDataByKey,
      isEven,
      tableClass,
      headClass,
      onRowClick,
      hasRowClick,
      currentPage,
      paginatedItems,
      filteredItems,
      sortByTableColumn,
      sortPosition,
      sortDirection,
      sortArray,
      isTableFixed,
      getColumnWidthByKey,
      show,
      getColumnDataForUI,
      getIgnoreSortByKey,
      hasSidePane,
      computedSidePaneOptions,
      startDrag,
      stopDrag,
      isDragging,
      state
    };
  },
});
