import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38629661"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "name", "form", "multiple", "disabled", "required", "aria-required", "aria-invalid", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_select_option_group = _resolveComponent("b-form-select-option-group")!
  const _component_b_form_select_option = _resolveComponent("b-form-select-option")!

  return (_openBlock(), _createElementBlock("select", _mergeProps({
    id: _ctx.computedId,
    ref: "input",
    class: _ctx.classes,
    name: _ctx.name,
    form: _ctx.form || null,
    multiple: _ctx.multiple || null,
    disabled: _ctx.isDisabled,
    required: _ctx.required,
    "aria-required": _ctx.required ? 'true' : null,
    "aria-invalid": _ctx.computedAriaInvalid
  }, _ctx.$attrs, {
    value: _ctx.modelValue,
    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChange($event)))
  }), [
    _renderSlot(_ctx.$slots, "first", {}, undefined, true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formOptions, (option, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (Array.isArray(option.options))
          ? (_openBlock(), _createBlock(_component_b_form_select_option_group, {
              key: `option_${index}`,
              label: option.label,
              options: option.options
            }, null, 8, ["label", "options"]))
          : (_openBlock(), _createBlock(_component_b_form_select_option, {
              key: `option_${index}`,
              value: option.value,
              disabled: option.disabled,
              textContent: _toDisplayString(option.text)
            }, null, 8, ["value", "disabled", "textContent"]))
      ], 64))
    }), 256)),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 16, _hoisted_1))
}