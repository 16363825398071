
// import type {BFormRowProps} from '../../types/components'
import {defineComponent} from 'vue';


export default defineComponent({
  name: 'b-form-row',
  props: {
    tag: {type: String, default: 'div'}
  },
  setup() {}
})
// interface BFormRowProps {
//   tag?: string
// }
//
// withDefaults(defineProps<BFormRowProps>(), {
//   tag: 'div',
// })
