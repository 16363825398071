import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_b_form = _resolveComponent("b-form")!

  return (_openBlock(), _createBlock(_component_b_form, null, {
    default: _withCtx(() => [
      _createVNode(_component_b_row, { class: "row-spacing" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_button, {
                class: "submit-buttons",
                disabled: (!_ctx.newName || _ctx.newName.trim() == ''),
                onClick: _ctx.saveNew
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Save as New")
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, { cols: "8" }, {
            default: _withCtx(() => [
              _createVNode(_component_text_input, {
                modelValue: _ctx.newName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newName) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, { class: "row-spacing" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_button, {
                class: "submit-buttons",
                disabled: !_ctx.canOverwrite,
                onClick: _ctx.saveExisting
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Update")
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, { cols: "8" }, {
            default: _withCtx(() => [
              _createVNode(_component_select_input, {
                options: _ctx.overwriteableFilters,
                modelValue: _ctx.overwriteTargetID,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.overwriteTargetID) = $event)),
                "text-field": "searchName",
                "value-field": "searchID"
              }, null, 8, ["options", "modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}