import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_SideBarNavProposalPBoms = _resolveComponent("SideBarNavProposalPBoms")!

  return (_ctx.item.children)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (child) => {
          return (_openBlock(), _createElementBlock("div", null, [
            (child?.show)
              ? (_openBlock(), _createElementBlock("div", {
                  key: child.key
                }, [
                  (child?.children && child?.type===_ctx.SideBarMenuChild.TYPE.PARENT)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("div", {
                          class: _normalizeClass({'btn-nav-sub-parent': true, 'btn-nav-parent-active': child.activeRouteNameArray.includes(_ctx.$route.name)}),
                          onClick: ($event: any) => (_ctx.navAccordionClick(_ctx.item.routeName,child.id))
                        }, [
                          _createElementVNode("span", null, _toDisplayString(child.title), 1),
                          _createVNode(_component_font_awesome_icon, {
                            icon: child.icon,
                            class: _normalizeClass({'rotate-chevron-90': _ctx.state.accordion.includes(child.id), 'rotate-chevron-0': !_ctx.state.accordion.includes(child.id), 'chevron-icon': true})
                          }, null, 8, ["icon", "class"])
                        ], 10, _hoisted_3),
                        _createElementVNode("div", {
                          class: _normalizeClass({'btn-accordion-open': true, 'accordion-content-open': _ctx.state.accordion.includes(child.id), 'accordion-content-closed': !_ctx.state.accordion.includes(child.id)})
                        }, [
                          _createVNode(_component_SideBarNavProposalPBoms, { item: child }, null, 8, ["item"])
                        ], 2)
                      ]))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        onClick: ($event: any) => (_ctx.navClick(child.routeName)),
                        class: _normalizeClass({'btn-nav-sub': true, 'btn-nav-sub-active': child.activeRouteNameArray.includes(_ctx.$route.name)})
                      }, [
                        _createElementVNode("span", null, _toDisplayString(child.title), 1)
                      ], 10, _hoisted_4))
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 256))
      ]))
    : _createCommentVNode("", true)
}