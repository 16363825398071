export default class Skill {
    public id!: number;
    public description!: string;
    public groupID!: number;

    constructor(init?: Partial<Skill>) {
        Object.assign(this, init);
    }
}

export class QualifiedSkill extends Skill {
    public proficiency!: Proficiency;

    constructor(init?: Partial<QualifiedSkill>) {
        super();
        Object.assign(this, init);
    }

    public static createFromSkill(skill: Skill, proficiency: Proficiency): QualifiedSkill {
        const qSkill = new QualifiedSkill();
        qSkill.id = skill.id;
        qSkill.description = skill.description;
        qSkill.groupID = skill.groupID;
        qSkill.proficiency = proficiency;
        return qSkill;
    }
}

export enum Proficiency {
    NT = 0,
    NE,
    SL,
    GP,
    EX
}

export class SkillGroup {
    public id!: number;
    public description!: string;
    public skillList!: Array<Skill>

    constructor(init?: Partial<SkillGroup>) {
        Object.assign(this, init);
        if(init && init.skillList) {
            this.skillList = [];
            init.skillList.forEach((skill) => {
                this.skillList.push(new Skill(skill)); // Use constructor to create proper Skill object rather than asserting JSON value is a Skill.
            })
        }
    }
}