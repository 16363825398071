export default class ReasonCode {
    public code!: number; // pk
    public description!: string;
    public deletion!: boolean;
    public allowSampleOnly!: boolean;
    public qaQuestionSet!: number;
    public allowCopy!: boolean;
    public allowCostUpdate!: boolean;
    public voidPrevious!: boolean;
    public allowOrderAcceptanceStatus!: boolean;
    public allowItemMasterCreation!: boolean;
    public allowToolingOrderPrinting!: boolean;
    public estimatorRequired!: boolean;
    public subGroup!: number;

    constructor(init?: Partial<ReasonCode>) {
        Object.assign(this, init);
    }

}
