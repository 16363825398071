import WCOperation from "@/domain/maintenance/WCOperation";
import editable from "@/domain/decorators/editable";

export default class ProdRouteItem extends editable{
    public routeItemID = 0; // 0 indicates new
    public description!: string;
    public workCenter!: string;
    public workCenterOperation!: number;
    public numberRequired!: number;
    public setupTime!: number;
    public setupRate!: number;
    public machineTime!: number;
    public machineRate!: number;
    public laborTime!: number;
    public laborRate!: number;
    public cycleCount!: number;
    public productBomID!: number;
    public wcOperationID?: number;
    public lineNumber!: number; //From productBom
    public quantity!: number; //Client side only/moved to Product BOM
    public overrideFlag!: boolean;
    public assemblyStepTime!: number;
    public totalAsmTime!: string;

    //WC_Operations table
    public wcOperation!: WCOperation;

    constructor(init?: Partial<ProdRouteItem>) {
        super();
        Object.assign(this, init);
    }

}
