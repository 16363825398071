
// import type {BModalEmits, BModalProps} from '../types/components'
import {Modal} from 'bootstrap'
import {computed, defineComponent, nextTick, onMounted, ref, useSlots, watch} from 'vue'
import {useEventListener, useId} from './composables';
import type {ColorVariant, InputSize} from '@/types';
import BButton from './BButton/BButton.vue'

//TODO: Integrate with Brian's bootstrap package.
export default defineComponent({
  name: 'b-modal',
  props: {
    bodyBgVariant: {type: String as () => ColorVariant},
    bodyClass: {type: String},
    bodyTextVariant: {type: String as () => ColorVariant},
    busy: {type: Boolean, default: false},
    lazy: {type: Boolean, default: false},
    buttonSize: {type: String as () => InputSize, default: 'md'},
    cancelDisabled: {type: Boolean, default: false},
    cancelTitle: {type: String, default: 'Cancel'},
    cancelVariant: {type: String as () => ColorVariant, default: 'secondary'},
    centered: {type: Boolean, default: false},
    contentClass: {type: String},
    dialogClass: {type: String},
    footerBgVariant: {type: String as () => ColorVariant},
    footerBorderVariant: {type: String as () => ColorVariant},
    footerClass: {type: String},
    footerTextVariant: {type: String as () => ColorVariant},
    fullscreen: {type: [Boolean, String], default: false},
    headerBgVariant: {type: String as () => ColorVariant},
    headerBorderVariant: {type: String as () => ColorVariant},
    headerClass: {type: String},
    headerCloseLabel: {type: String, default: 'Close'},
    headerCloseWhite: {type: Boolean, default: false},
    headerTextVariant: {type: String as () => ColorVariant},
    hideBackdrop: {type: Boolean, default: false},
    hideFooter: {type: Boolean, default: false},
    hideHeader: {type: Boolean, default: false},
    hideHeaderClose: {type: Boolean, default: false},
    id: {type: String},
    modalClass: {type: String},
    modelValue: {type: Boolean, default: false},
    noCloseOnBackdrop: {type: Boolean, default: false},
    noCloseOnEsc: {type: Boolean, default: false},
    noFade: {type: Boolean, default: false},
    noFocus: {type: Boolean, default: false},
    okDisabled: {type: Boolean, default: false},
    okOnly: {type: Boolean, default: false},
    okTitle: {type: String, default: 'Ok'},
    okVariant: {type: String as () => ColorVariant, default: 'primary'},
    scrollable: {type: Boolean, default: false},
    show: {type: Boolean, default: false},
    size: {type: String},
    title: {type: String},
    titleClass: {type: String},
    titleSrOnly: {type: Boolean, default: false},
    titleTag: {type: String, default: 'h5'}
  },
  setup(props, context) {
    const busyBoolean = ref(props.busy);
    const lazyBoolean = ref(props.lazy);
    const cancelDisabledBoolean = ref(props.cancelDisabled)
    const centeredBoolean = ref(props.centered);
    const headerCloseWhiteBoolean = ref(props.headerCloseWhite);
    const hideBackdropBoolean = ref(props.hideBackdrop);
    const hideFooterBoolean = ref(props.hideFooter);
    const hideHeaderBoolean = ref(props.hideHeader);
    const hideHeaderCloseBoolean = ref(props.hideHeaderClose);
    const modelValueBoolean = ref(props.modelValue);
    const noCloseOnBackdropBoolean = ref(props.noCloseOnBackdrop);
    const noCloseOnEscBoolean = ref(props.noCloseOnEsc);
    const noFadeBoolean = ref(props.noFade);
    const noFocusBoolean = ref(props.noFocus);
    const okDisabledBoolean = ref(props.okDisabled);
    const okOnlyBoolean = ref(props.okOnly);
    const scrollableBoolean = ref(props.scrollable);
    const showBoolean = ref(props.show);
    const titleSrOnlyBoolean = ref(props.titleSrOnly);

    const lazyLoadCompleted = ref(false);

    const computedId = useId(props.id, 'modal');

    const slots = useSlots()

    const element = ref<HTMLElement>()
    const instance = ref<Modal>()
    const modalClasses = computed(() => [
      {
        fade: !noFadeBoolean.value,
        show: showBoolean.value,
      },
      props.modalClass,
    ])
    const modalDialogClasses = computed(() => [
      {
        'modal-fullscreen': typeof props.fullscreen === 'boolean' ? props.fullscreen : false,
        [`modal-fullscreen-${props.fullscreen}-down`]:
            typeof props.fullscreen === 'string' ? props.fullscreen : false,
        [`modal-${props.size}`]: props.size,
        'modal-dialog-centered': centeredBoolean.value,
        'modal-dialog-scrollable': scrollableBoolean.value,
      },
      props.dialogClass,
    ])

    const computedBodyClasses = computed(() => [
      {
        [`bg-${props.bodyBgVariant}`]: props.bodyBgVariant,
        [`text-${props.bodyTextVariant}`]: props.bodyTextVariant,
      },
      props.bodyClass,
    ])

    const computedHeaderClasses = computed(() => [
      {
        [`bg-${props.headerBgVariant}`]: props.headerBgVariant,
        [`border-${props.headerBorderVariant}`]: props.headerBorderVariant,
        [`text-${props.headerTextVariant}`]: props.headerTextVariant,
      },
      props.headerClass,
    ])

    const computedFooterClasses = computed(() => [
      {
        [`bg-${props.footerBgVariant}`]: props.footerBgVariant,
        [`border-${props.footerBorderVariant}`]: props.footerBorderVariant,
        [`text-${props.footerTextVariant}`]: props.footerTextVariant,
      },
      props.footerClass,
    ])

    const computedTitleClasses = computed(() => [
      {
        ['visually-hidden']: titleSrOnlyBoolean.value,
      },
      props.titleClass,
    ])

    const hasHeaderCloseSlot = computed<boolean>(() => !!slots['header-close'])
    const computedCloseButtonClasses = computed(() => [
      {
        [`btn-close-content`]: hasHeaderCloseSlot.value,
        [`d-flex`]: hasHeaderCloseSlot.value,
        [`btn-close-white`]: !hasHeaderCloseSlot.value && headerCloseWhiteBoolean.value,
      },
    ])

    const disableCancel = computed<boolean>(() => cancelDisabledBoolean.value || busyBoolean.value)
    const disableOk = computed<boolean>(() => okDisabledBoolean.value || busyBoolean.value)

    useEventListener(element, 'shown.bs.modal', (e) => modalShowed(e))
    useEventListener(element, 'hidden.bs.modal', (e) => modalHided(e))
    useEventListener(element, 'show.bs.modal', (e) => modalShow(e))
    useEventListener(element, 'hide.bs.modal', (e) => modalHide(e))

    const modalShowed = (e: Event) => {
      context.emit('shown', e)

      if (lazyBoolean.value === true) lazyLoadCompleted.value = true
      if (modelValueBoolean.value === false) context.emit('update:modelValue', true)
      ;(e.target as HTMLElement).focus()
    }

    const modalHided = (e: Event) => {
      context.emit('hidden', e)

      if (lazyBoolean.value === true) lazyLoadCompleted.value = false
      if (modelValueBoolean.value === true) context.emit('update:modelValue', false)

      const parentModal = document.querySelector('.modal')
      if (parentModal) {
        ;(parentModal as HTMLElement).focus()
      }
    }

    const modalShow = (e: Event) => {
      context.emit('show', e)
    }

    const modalHide = (e: Event) => {
      context.emit('hide', e)
    }

    const show = () => {
      if (modelValueBoolean.value) context.emit('update:modelValue', true)
      getInstance().show()
    }

    const hide = () => {
      if (modelValueBoolean.value) context.emit('update:modelValue', false)
      getInstance().hide()
    }

    const getInstance = () => {

      if (instance.value !== undefined) return instance.value
      instance.value = new Modal(element.value as HTMLElement, {
        backdrop: false,
        // backdrop: hideBackdropBoolean.value
        //   ? false
        //   : noCloseOnBackdropBoolean.value
        //     ? 'static'
        //     : !hideBackdropBoolean.value,
        keyboard: !noCloseOnEscBoolean.value,
        focus: !noFocusBoolean.value,
      })
      return instance.value
    }

    onMounted(() => {
      if (modelValueBoolean.value) {
        getInstance().show()
      }
    })

    watch(
        () => props.noCloseOnBackdrop,
        (newValue) => {
          ;(getInstance() as unknown as {_config: Modal.Options})._config.backdrop = props.hideBackdrop
              ? false
              : newValue
                  ? 'static'
                  : !props.hideBackdrop
        }
    )

    watch(
        () => props.noCloseOnEsc,
        (newValue) => {
          ;(getInstance() as unknown as {_config: Modal.Options})._config.keyboard = !newValue
        }
    )

    watch(() => props.modelValue,
        (value) => {
          modelValueBoolean.value = value;
        })

    watch(
        () => modelValueBoolean.value,
        (value) => {
          nextTick(() => {
            if (value) {
              show()
            } else {
              hide()
            }
          })
        }
    )

    return {
      computedId,
      element,
      modalClasses,
      modalDialogClasses,
      lazyBoolean,
      lazyLoadCompleted,
      modelValueBoolean,
      hideHeaderBoolean,
      computedHeaderClasses,
      computedTitleClasses,
      hideHeaderCloseBoolean,
      computedCloseButtonClasses,
      hide,
      computedBodyClasses,
      hideFooterBoolean,
      okOnlyBoolean,
      disableCancel,
      computedFooterClasses,
      disableOk,
      hideBackdropBoolean,
      noCloseOnBackdropBoolean
    }

  },
  emits: ['update:modelValue', 'show', 'shown', 'hide', 'hidden', 'hide-prevented', 'ok', 'cancel']

})

