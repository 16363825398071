import editable from "@/domain/decorators/editable";

export default class ComponentToolingItem extends editable {
    componentBomID!: number;
    cmpToolingID!: number;
    code!: number;
    bom!: number;
    qty!: number;
    fix!: boolean;
    rate!: number;
    extension!: number;
    comments!: string;
    toolingName!: string;
    lineNumber!: number;
    blankCalcDnd!: boolean;

    show = true; // used to refresh components

    constructor(init?: Partial<ComponentToolingItem>) {
        super();
        Object.assign(this, init);
    }

}
