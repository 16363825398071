
import { computed, defineComponent, ref } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker'


export default defineComponent({
    name: 'b-form-datepicker',
    components: {VueDatePicker },
    props: {
        cols: String,
        lg: String,
        md: String,
        sm: String,
        xs: String,
        label: String,
        disabled: Boolean,
        moreInfo: String,
        upperLimit: Date,
        lowerLimit: Date,
        modelValue: Date,
        readonly: { type: Boolean, default: () => false },
        inputFormat: { type: String, default: 'MM-dd-yyyy' },
        enableTimePicker: {
          type: Boolean,
          default: false
        },
        required: {
          type: Boolean,
          default: false,
        },
    },
    emits: ['update:modelValue', 'input'],
    setup(props, context) {
        const picked = ref(props.modelValue? props.modelValue : new Date());

        function onInput(evt: any) {
            // const { value } = evt.target;
            const value = evt as Date;
            context.emit('update:modelValue', value);
            context.emit('input', value);
        }

        const disableMinMaxNav = computed(() => props.lowerLimit || props.upperLimit ? true : false)

        return {
            picked,
            onInput,
            disableMinMaxNav
        };
    },
});
