
// import type {BFormRadioEmits, BFormRadioProps} from '../../types/components'
// import type {Booleanish, ButtonVariant, InputSize} from '../../types'
// import {getClasses, getInputClasses, getLabelClasses, useBooleanish, useId} from '../../composables'
// import getID from "@/components/bootstrap-library/utils/getID";
import {getId} from "./utils";
import {getClasses, getInputClasses, getLabelClasses} from './composables/useFormCheck';
import {computed, defineComponent, onMounted, reactive, ref, toRef} from 'vue'

export default defineComponent({
  name: 'b-form-radio',
  props: {
    ariaLabel: {type: String, required: false },
    ariaLabelledBy: {type: String, required: false },
    form: {type: String, required: false},
    id: {type: String, required: false },
    name: {type: String, required: false},
    autofocus: {type: Boolean, default: false},
    plain: {type: Boolean, default: false},
    size: {type: String, default: 'md'},
    button: {type: Boolean, default: false},
    switch: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    modelValue: {type: [Boolean, String, Object as () => Array<unknown>, Object as ()=> Record<string, unknown>, Number], default: undefined},
    state: {type: Boolean, default: undefined},
    inline: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    value: {type: [Boolean, String, Object as () => Record<string, unknown>, Number], default: true},
  }, emits: ['update:modelValue', 'input', 'change'],
  setup(props, context) {

    const autofocusBoolean = ref(props.autofocus);
    const plainBoolean = ref(props.plain);
    const sizeRef = ref(props.size);
    const disabledBoolean = ref(props.disabled);
    const requiredBoolean = ref(props.required);
    const computedId = computed(() => {return getId(props.id)});
    const input = ref<HTMLElement>(null as unknown as HTMLElement)
    const isFocused = ref<boolean>(false)

    const localValue = computed<unknown>({
      get: () => (Array.isArray(props.modelValue) ? props.modelValue[0] : props.modelValue),
      set: (newValue: any) => {
        const value = newValue ? props.value : false
        const emitValue = Array.isArray(props.modelValue) ? [value] : value
        context.emit('input', emitValue)
        context.emit('change', emitValue)
        context.emit('update:modelValue', emitValue)
      },
    })

    const isChecked = computed<unknown>(() => {
      if (Array.isArray(props.modelValue)) {
        return (props.modelValue || []).find((e) => e === props.value)
      }
      return JSON.stringify(props.modelValue) === JSON.stringify(props.value)
    })

    const classesObject = reactive({
      plain: toRef(plainBoolean, 'value'),
      size: toRef(sizeRef, 'value')
    })
    const classes = getClasses(classesObject)
    const inputClasses = getInputClasses(classesObject)
    const labelClasses = getLabelClasses(classesObject)

    onMounted(() => {
      if (autofocusBoolean.value) {
        input.value.focus()
      }
    })

    return {
      localValue,
      disabledBoolean,
      requiredBoolean,
      plainBoolean,
      computedId,
      isFocused,
      isChecked,
      classes,
      inputClasses,
      labelClasses
    }
  }
})
