export default class AllMaterial {

    public id!: number;
    public number!: string;
    public plant!: number;
    public rawMaterialLength!: number;
    public rawMaterialWidth!: number;
    public rawMaterialHeight!: number;
    public materialDescription!: string;
    public lastChangedTime!: string; // timestamp
    public lastChangedOn!: string;
    public changedBy!: string;
    public cesUnitOfMeasure!: string;
    public deletionIndicator!: boolean;
    public selectionType!: string;
    public materialTier!: number;
    public standardPrice!: number;
    public priceUnit!: number;
    public currency!: string;
    public baseUnit!: string;
    public materialColor!: string;
    public materialUse!: string;
    public materialType!: string;
    public materialThickness!: string;
    public baseUnits!: string;
    public materialProtectionClassType!: string;
    public totalQtyBaseUnit!: number;
    public minimumRequirementsUnits!: string;
    public minimumUnits!: string;
    public materialSides!: string;
    public productHierarchyNumber!: number;
    public foldOverMaterial!: boolean;
    public maxedTabsFold!: boolean;
    public manufacturingTime!: number;
    public coverStyle!: string;
    public coverType!: string;
    public coverStdOpt!: string;
    public coverSide!: string;
    public coverNumWings!: string;
    public coverVelcroAtt!: string;
    public coverTotalNumFstnr!: string;
    public coverNumFstnrSide!: string;
    public coverStaples!: string;
    public coverRivets!: string;
    public coverXtrees!: string;
    public coverContainerSize!: string;
    public xPlantMatlStatus!: string;
    public plantSPMatlStatus!: string;

    constructor(init?: Partial<AllMaterial>) {
        Object.assign(this, init);
        this.fixLocalTimesToUTC();
    }

    /**
     * This function should be run for any data returned from DB.
     * Due to a limitation of JDBI, we cannot include timezone information when retrieving data from DB (without screwing up inserts/updates),
     * so here we correct date-time fields by treating them as UTC instead of LocalTime.
     **/
    public fixLocalTimesToUTC() {
        if(this.lastChangedTime) {
            const tmpDate = new Date(this.lastChangedTime);
            tmpDate.setTime(tmpDate.getTime() - tmpDate.getTimezoneOffset() * 60 * 1000);
            this.lastChangedTime = tmpDate.toISOString();
        }
    }

    public update(init?: Partial<AllMaterial>) {
        Object.assign(this, init);
    }

}
