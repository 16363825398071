export default class ContainerModel {
    public containerID!: string;
    public containerType!: number;
    public containerDescription!: string;
    public ctypDel!: number;
    public lengthID!: number;
    public widthID!: number;
    public heightID!: number;
    public lengthOD!: number;
    public widthOD!: number;
    public heightOD!: number;
    public topLengthID!: number;
    public topWidthID!: number;
    public dunnageStaple!: string;
    public dunnageRivet!: string;
    public dunnageXmasTree!: string;
    public dunnageScrew!: string;
    public dunnageVelcro!: string;
    public fabricFstnrLsQty!: number;
    public fabricFstnrSsQty!: number;
    public secondaryFabricFastenerLs!: number;
    public secondaryFabricFastenerSs!: number;
    public rigidFstnrLsQty!: number;
    public rigidFstnrSsQty!: number;
    public coverStaple!: string;
    public coverRivet!: string;
    public coverXmasTree!: string;
    public coverScrew!: string;
    public lsIDPlate!: string;
    public lsIDPlateQty!: string;
    public lsIDPlateTopPlaneOffset!: string;
    public lsIDPlateLengthPlaneOffset!: string;
    public lsLabel!: string;
    public lsLabelQty!: string;
    public lsLabelTopPlaneOffset!: string;
    public lsLabelLengthPlaneOffset!: string;
    public lsPlacard!: string;
    public lsPlacardQty!: string;
    public lsPlacardTopPlaneOffset!: string;
    public lsPlacardLengthPlaneOffset!: string;
    public lsCardHolder!: string;
    public lsCardHolderQty!: string;
    public lsCardHolderTopPlaneOffset!: string;
    public lsCardHolderLengthPlaneOffset!: string;
    public ssIDPlate!: string;
    public ssIDPlateQty!: string;
    public ssIDPlateTopPlaneOffset!: string;
    public ssIDPlateMidShortPlaneOffset!: string;
    public ssLabel!: string;
    public ssLabelQty!: string;
    public ssLabelTopPlaneOffset!: string;
    public ssLabelMidShortPlaneOffset!: string;
    public ssPlacard!: string;
    public ssPlacardQty!: string;
    public ssPlacardTopPlaneOffset!: string;
    public ssPlacardMidShortPlaneOffset!: string;
    public ssCardHolder!: string;
    public ssCardHolderQty!: string;
    public ssCardHolderTopPlaneOffset!: string;
    public ssCardHolderMidShortPlaneOffset!: string;
    public maximusRackNumber!: string;
    public longFoamRailFstnrQty!: number;
    public shortFoamRailFstnrQty!: number;

    constructor(init?: Partial<ContainerModel>) {
        Object.assign(this, init);
    }

    public update(init?: Partial<ContainerModel>) {
        Object.assign(this, init);
    }

}
