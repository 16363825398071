import SidebarLogStore from "@/views/sidebar/store/SidebarLogStore";
import rootStore from "@/store/RootStore";

export default class LogService {
    private logStore: SidebarLogStore;

    constructor() {
        this.logStore = rootStore.getInstance().sidebarLogStore;
    }

    public async logEvent(event: { header: string, body: any }): Promise<boolean> {
        try {
            debugger
            this.logStore.errors.push(event);
            return true;
        } catch(e: any) {
            console.log(e);
        }
        return false;
    }

    public async clearLog(): Promise<boolean> {
        this.logStore.errors = [];
        return true;
    }
}