
// Internal component that decomposes the internals of BCardHeader & BCardFooter
// (they do the same thing, with the only difference of one static class)

// import type {BCardHeaderProps} from '../../types/components'
import {computed, defineComponent} from 'vue'
import type {ColorVariant, TextColorVariant} from '../../types'

export default defineComponent({
  name: 'b-card-head-foot',
  props: {
    text: {type: String},
    bgVariant: {type: Object as () => ColorVariant},
    borderVariant: {type: Object as () => ColorVariant},
    html: {type: String},
    tag: {type: String, default: 'div'},
    textVariant: {type: Object as () => TextColorVariant}
  },
  setup(props, context) {

    const classes = computed(() => ({
      [`text-${props.textVariant}`]: props.textVariant !== undefined,
      [`bg-${props.bgVariant}`]: props.bgVariant !== undefined,
      [`border-${props.borderVariant}`]: props.borderVariant !== undefined,
    }))

    return {
      classes
    }
  }
})

