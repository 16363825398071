import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c245fc94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "orderable-container" }
const _hoisted_2 = ["id", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createElementBlock("div", {
        id: "sortable-list",
        class: "sortable-list",
        key: _ctx.modelValue
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (def) => {
          return (_openBlock(), _createElementBlock("div", {
            key: def.key,
            class: "dropdown-content-row"
          }, [
            _createElementVNode("div", {
              id: def.key,
              class: "item",
              draggable: "true",
              onClick: ($event: any) => (def.hidden = !def.hidden)
            }, [
              _createVNode(_component_b_form_checkbox, {
                inline: "",
                label: _ctx.getLabelFromFormDefinition(def),
                "model-value": _ctx.dynamicColumns.some(x => _ctx.isEqual(x, def.key)),
                onChange: (value) => _ctx.toggleColumn(value, def.key)
              }, null, 8, ["label", "model-value", "onChange"])
            ], 8, _hoisted_2)
          ]))
        }), 128))
      ]))
    ])
  ]))
}