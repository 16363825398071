
import {defineComponent, computed, reactive, watch, onBeforeMount} from 'vue';
import {urgencyOptions, searchProposalByOptions, Option} from "@/data/options";
import {WILDCARD_SEARCH_RULES} from "@/config/env";
import TextInput from "@/views/core/components/inputs/TextInput.vue";
import CheckboxInput from "@/views/core/components/inputs/CheckboxInput.vue";
import SelectInput from "@/views/core/components/inputs/SelectInput.vue";
import SearchByMultipleProposals from "@/domain/proposal/SearchByMultipleProposals";
import {ProposalRules} from "@/utility/form-rules";
import rootStore from "@/store/RootStore";
import router from "@/router";
import VersionService from "@/service/VersionService";

type FilterState = {
    sortBy: string;
    sortDesc: boolean;
    list: string;
}

type State = {
    searchValueOneProposal: string;
    searchObject: SearchByMultipleProposals;
    searchBy: string;
    busy: boolean;
    searchType: string;
    versionService: VersionService;
    version: string;

    autoBuildDisabled: boolean;
    containerDisabled: boolean;
    customerNameDisabled: boolean;
    customerPartDescDisabled: boolean;
    customerProductNumberDisabled: boolean;
    designReleasedDisabled: boolean;
    designerDisabled: boolean;
    estimatorDisabled: boolean;
    plantDisabled: boolean;
    fobPlantDisabled: boolean;
    proposalDisabled: boolean;
    reasonCodeDisabled: boolean;
    salesEngineerDisabled: boolean;
    salesRepNameDisabled: boolean;
    sampleMakerCodeDisabled: boolean;
    sampleStatusDisabled: boolean;
    statusDisabled: boolean;
    urgencyLevelDisabled: boolean;
}

export default defineComponent({
    name: 'SearchScreen',
    components: {TextInput, CheckboxInput, SelectInput},
    setup() {
        const state = reactive<State>({
            searchValueOneProposal: "",
            searchObject: new SearchByMultipleProposals(),
            searchBy: "",
            versionService: new VersionService(),
            version: "",
            busy: false,
            searchType: "multiple",
            autoBuildDisabled: false,
            containerDisabled: false,
            customerNameDisabled: false,
            customerPartDescDisabled: false,
            customerProductNumberDisabled: false,
            designReleasedDisabled: false,
            designerDisabled: false,
            estimatorDisabled: false,
            plantDisabled: false,
            fobPlantDisabled: false,
            proposalDisabled: false,
            reasonCodeDisabled: false,
            salesEngineerDisabled: false,
            salesRepNameDisabled: false,
            sampleMakerCodeDisabled: false,
            sampleStatusDisabled: false,
            statusDisabled: false,
            urgencyLevelDisabled: false
        })

        const filterState = reactive<FilterState>({
            sortBy: "proposalNumber",
            sortDesc: false,
            list: ""
        })

        const listPodFilters = getListPodFilters();

        onBeforeMount(() => {
          getCurrentVersion();
        })

        async function getCurrentVersion() {
          state.busy = true;
          const response = await state.versionService.currentVersion();
          if (response.success) {
            state.busy = false;
            state.version = response.version;
          }
        }

        function getListPodFilters() {
            const filters: Array<Option> = [];

            for (const filter of rootStore.getInstance().maintenanceStore.proposalSearchFilters) {
                filters.push({
                    text: filter.filterName,
                    value: filter.searchFilterID,
                })
            }
            return filters;
        }

        watch(() =>state.searchType, () => {
            if (state.searchType === 'single') {
                showAllFields();
            }
        })

        function changeColumns(filter: Option) {
            //showAllFields();
            state.searchObject.filter = filter.text;
            searchMultipleProposals();

            /*switch (filter.text.toLowerCase()) {
                case 'designer':
                    disableNonDesignerFields();
                    break;
                case 'released/estimator':
                    disableNonReleasedEstimatorFields();
                    break;
                case 'mentor pod':
                    disableNonMentorPodFields();
                    break;
                case 'troy pod':
                    disableNonTroyPodFields();
                    break;
                case 'northwood pod':
                    disableNonNorthwoodPodFields();
                    break;
                case 'greenville/piedmont pod':
                    disableNonGreenvillePiedmontPodFields();
                    break;
                case 'sample makers':
                    disableNonSampleMakerFields();
                    break;
            }*/
        }

        function disableNonDesignerFields() {
            state.fobPlantDisabled = true;
        }

        function disableNonReleasedEstimatorFields() {
            state.designReleasedDisabled = true;
            state.sampleMakerCodeDisabled = true;
            state.sampleStatusDisabled = true;
        }

        function disableNonMentorPodFields() {
            state.sampleStatusDisabled = true;
            state.searchObject.alternateOwner = 'Scott Katz'
        }

        function disableNonTroyPodFields() {
            state.sampleStatusDisabled = true;
        }

        function disableNonNorthwoodPodFields() {
            state.sampleStatusDisabled = true;
        }

        function disableNonGreenvillePiedmontPodFields() {
            state.sampleStatusDisabled = true;
        }

        function disableSilaoPodFields() {
            state.sampleStatusDisabled = true;
        }

        function disableNonSampleMakerFields() {
            state.autoBuildDisabled = true;
            state.estimatorDisabled = true;
        }

        function showAllFields() {
            state.autoBuildDisabled = false;
            state.containerDisabled = false;
            state.customerNameDisabled = false;
            state.customerPartDescDisabled = false;
            state.customerProductNumberDisabled = false;
            state.designReleasedDisabled = false;
            state.designerDisabled = false;
            state.estimatorDisabled = false;
            state.plantDisabled = false;
            state.fobPlantDisabled = false;
            state.proposalDisabled = false;
            state.reasonCodeDisabled = false;
            state.salesEngineerDisabled = false;
            state.salesRepNameDisabled = false;
            state.sampleMakerCodeDisabled = false;
            state.sampleStatusDisabled = false;
            state.statusDisabled = false;
            state.urgencyLevelDisabled = false;
        }

        function handleSearchProposal() {
          router.push({name: 'Proposal', params: {proposalNumber: state.searchValueOneProposal}});
            //getCurrentInstance()?.proxy.$router.push({name: 'Proposal', params: {proposalNumber: state.searchValueOneProposal}});

            //context.root.$router.push({name: 'Proposal', params: {proposalNumber: state.searchValueOneProposal}})
        }

        function searchMultipleProposals() {
            removeEndingSpaces();
            escapePercentSigns();
            setEmptyStringsToOneSpace();
            router.push({
                name: 'ProposalTable', params: {
                    customerMarketingProgram: state.searchObject.customerMarketingProgram,
                    customerMarketingProgramAndOr: state.searchObject.customerMarketingProgramAndOr,
                    containerID: state.searchObject.containerID,
                    containerIdAndOr: state.searchObject.containerIdAndOr,
                    customerName: state.searchObject.customerName,
                    customerNameAndOr: state.searchObject.customerNameAndOr,
                    customerPartDesc: state.searchObject.customerPartDesc,
                    customerPartDescAndOr: state.searchObject.customerPartDescAndOr,
                    productNumber: state.searchObject.productNumber,
                    productNumberAndOr: state.searchObject.productNumberAndOr,
                    designReleased: state.searchObject.designReleased.toString(),
                    designReleasedAndOr: state.searchObject.designReleasedAndOr,
                    designer: state.searchObject.designer,
                    designerAndOr: state.searchObject.designerAndOr,
                    estimator: state.searchObject.estimator,
                    estimatorAndOr: state.searchObject.estimatorAndOr,
                    plantLocation: state.searchObject.plantLocation,
                    plantLocationAndOr: state.searchObject.plantLocationAndOr,
                    fobPlant: state.searchObject.fobPlant,
                    fobPlantAndOr: state.searchObject.fobPlantAndOr,
                    proposalID: state.searchObject.proposalID,
                    proposalIdAndOr: state.searchObject.proposalIdAndOr,
                    salesRepName: state.searchObject.salesRepName,
                    salesRepNameAndOr: state.searchObject.salesRepNameAndOr,
                    alternateOwner: state.searchObject.alternateOwner,
                    alternateOwnerAndOr: state.searchObject.alternateOwnerAndOr,
                    opportunityOwner: state.searchObject.opportunityOwner,
                    opportunityOwnerAndOr: state.searchObject.opportunityOwnerAndOr,
                    location: state.searchObject.location,
                    locationAndOr: state.searchObject.locationAndOr,
                    sampleStatus: state.searchObject.sampleStatus,
                    sampleStatusAndOr: state.searchObject.sampleStatusAndOr,
                    qaStatus: state.searchObject.qaStatus,
                    qaStatusAndOr: state.searchObject.qaStatusAndOr,
                    reasonCode: state.searchObject.reasonCode,
                    reasonCodeAndOr: state.searchObject.reasonCodeAndOr,
                    filter: state.searchObject.filter,
                    urgencyLevel: state.searchObject.urgencyLevel,
                    urgencyLevelAndOr: state.searchObject.urgencyLevelAndOr
                }
            })
        }

        function removeEndingSpaces() {
            if (state.searchObject.customerMarketingProgram != null) {
                state.searchObject.customerMarketingProgram = state.searchObject.customerMarketingProgram.trim();
            }
            if (state.searchObject.containerID != null) {
                state.searchObject.containerID = state.searchObject.containerID.trim();
            }
            if (state.searchObject.customerName != null) {
                state.searchObject.customerName = state.searchObject.customerName.trim();
            }
            if (state.searchObject.customerPartDesc != null) {
                state.searchObject.customerPartDesc = state.searchObject.customerPartDesc.trim();
            }
            if (state.searchObject.productNumber != null) {
                state.searchObject.productNumber = state.searchObject.productNumber.trim();
            }
            if (state.searchObject.designer != null) {
                state.searchObject.designer = state.searchObject.designer.trim();
            }
            if (state.searchObject.estimator != null) {
                state.searchObject.estimator = state.searchObject.estimator.trim();
            }
            if (state.searchObject.plantLocation != null) {
                state.searchObject.plantLocation = state.searchObject.plantLocation.trim();
            }
            if (state.searchObject.fobPlant != null) {
                state.searchObject.fobPlant = state.searchObject.fobPlant.trim();
            }
            if (state.searchObject.proposalID != null) {
                state.searchObject.proposalID = state.searchObject.proposalID.trim();
            }
            if (state.searchObject.salesRepName != null) {
                state.searchObject.salesRepName = state.searchObject.salesRepName.trim();
            }
            if (state.searchObject.alternateOwner != null) {
                state.searchObject.alternateOwner = state.searchObject.alternateOwner.trim();
            }
            if (state.searchObject.opportunityOwner != null) {
                state.searchObject.opportunityOwner = state.searchObject.opportunityOwner.trim();
            }
            if (state.searchObject.location != null) {
                state.searchObject.location = state.searchObject.location.trim();
            }
            if (state.searchObject.sampleStatus != null) {
                state.searchObject.sampleStatus = state.searchObject.sampleStatus.trim();
            }
            if (state.searchObject.qaStatus != null) {
                state.searchObject.qaStatus = state.searchObject.qaStatus.trim();
            }
            if (state.searchObject.reasonCode != null) {
                state.searchObject.reasonCode = state.searchObject.reasonCode.trim();
            }
        }

        function escapePercentSigns() {
            if (state.searchObject.customerMarketingProgram != null) {
                state.searchObject.customerMarketingProgram = state.searchObject.customerMarketingProgram.replace("%", "%25");
            }
            if (state.searchObject.containerID != null) {
                state.searchObject.containerID = state.searchObject.containerID.replace("%", "%25");
            }
            if (state.searchObject.customerName != null) {
                state.searchObject.customerName = state.searchObject.customerName.replace("%", "%25");
            }
            if (state.searchObject.customerPartDesc != null) {
                state.searchObject.customerPartDesc = state.searchObject.customerPartDesc.replace("%", "%25");
            }
            if (state.searchObject.productNumber != null) {
                state.searchObject.productNumber = state.searchObject.productNumber.replace("%", "%25");
            }
            if (state.searchObject.designer != null) {
                state.searchObject.designer = state.searchObject.designer.replace("%", "%25");
            }
            if (state.searchObject.estimator != null) {
                state.searchObject.estimator = state.searchObject.estimator.replace("%", "%25");
            }
            if (state.searchObject.plantLocation != null) {
                state.searchObject.plantLocation = state.searchObject.plantLocation.replace("%", "%25");
            }
            if (state.searchObject.fobPlant != null) {
                state.searchObject.fobPlant = state.searchObject.fobPlant.replace("%", "%25");
            }
            if (state.searchObject.proposalID != null) {
                state.searchObject.proposalID = state.searchObject.proposalID.replace(/%/g, "");
            }
            if (state.searchObject.salesRepName != null) {
                state.searchObject.salesRepName = state.searchObject.salesRepName.replace("%", "%25");
            }
            if (state.searchObject.alternateOwner != null) {
                state.searchObject.alternateOwner = state.searchObject.alternateOwner.replace("%", "%25");
            }
            if (state.searchObject.opportunityOwner != null) {
                state.searchObject.opportunityOwner = state.searchObject.opportunityOwner.replace("%", "%25");
            }
            if (state.searchObject.location != null) {
                state.searchObject.location = state.searchObject.location.replace("%", "%25");
            }
            if (state.searchObject.sampleStatus != null) {
                state.searchObject.sampleStatus = state.searchObject.sampleStatus.replace("%", "%25");
            }
            if (state.searchObject.qaStatus != null) {
                state.searchObject.qaStatus = state.searchObject.qaStatus.replace("%", "%25");
            }
            if (state.searchObject.reasonCode != null) {
                state.searchObject.reasonCode = state.searchObject.reasonCode.replace("%", "%25");
            }
        }

        function setEmptyStringsToOneSpace() {
            if (state.searchObject.customerMarketingProgram == null || state.searchObject.customerMarketingProgram.length === 0) {
                state.searchObject.customerMarketingProgram = ' ';
            }
            if (state.searchObject.containerID == null || state.searchObject.containerID.length === 0) {
                state.searchObject.containerID = ' ';
            }
            if (state.searchObject.customerName == null || state.searchObject.customerName.length === 0) {
                state.searchObject.customerName = ' ';
            }
            if (state.searchObject.customerPartDesc == null || state.searchObject.customerPartDesc.length === 0) {
                state.searchObject.customerPartDesc = ' ';
            }
            if (state.searchObject.productNumber == null || state.searchObject.productNumber.length === 0) {
                state.searchObject.productNumber = ' ';
            }
            if (state.searchObject.designer == null || state.searchObject.designer.length === 0) {
                state.searchObject.designer = ' ';
            }
            if (state.searchObject.estimator == null || state.searchObject.estimator.length === 0) {
                state.searchObject.estimator = ' ';
            }
            if (state.searchObject.plantLocation == null || state.searchObject.plantLocation.length === 0) {
                state.searchObject.plantLocation = ' ';
            }
            if (state.searchObject.fobPlant == null || state.searchObject.fobPlant.length === 0) {
                state.searchObject.fobPlant = ' ';
            }
            if (state.searchObject.proposalID == null || state.searchObject.proposalID.length === 0) {
                state.searchObject.proposalID = ' ';
            }
            if (state.searchObject.salesRepName == null || state.searchObject.salesRepName.length === 0) {
                state.searchObject.salesRepName = ' ';
            }
            if (state.searchObject.alternateOwner == null || state.searchObject.alternateOwner.length === 0) {
                state.searchObject.alternateOwner = ' ';
            }
            if (state.searchObject.opportunityOwner == null || state.searchObject.opportunityOwner.length === 0) {
                state.searchObject.opportunityOwner = ' ';
            }
            if (state.searchObject.location == null || state.searchObject.location.length === 0) {
                state.searchObject.location = ' ';
            }
            if (state.searchObject.sampleStatus == null || state.searchObject.sampleStatus.length === 0) {
                state.searchObject.sampleStatus = ' ';
            }
            if (state.searchObject.qaStatus == null || state.searchObject.qaStatus.length === 0) {
                state.searchObject.qaStatus = ' ';
            }
            if (state.searchObject.reasonCode == null || state.searchObject.reasonCode.length === 0) {
                state.searchObject.reasonCode = ' ';
            }
            if (state.searchObject.urgencyLevel == null || state.searchObject.urgencyLevel.length === 0) {
                state.searchObject.urgencyLevel = ' ';
            }
            if (state.searchObject.filter == null || state.searchObject.filter.length === 0) {
                state.searchObject.filter = ' ';
            }
        }

        const allowSearchOne = computed(() => {
            return state.searchValueOneProposal.length > 0
        })

        function handleCustomerMarketingProgramChange(e: string) {
            state.searchObject.customerMarketingProgramAndOr = e;
        }

        function handleContainerIDChange(e: string) {
            state.searchObject.containerIdAndOr = e;
        }

        function handleCustomerNameChange(e: string) {
            state.searchObject.customerNameAndOr = e;
        }

        function handleCustomerPartDescChange(e: string) {
            state.searchObject.customerPartDescAndOr = e;
        }

        function handleProductNumberChange(e: string) {
            state.searchObject.productNumberAndOr = e;
        }

        function handleDesignReleasedChange(e: string) {
            state.searchObject.designReleasedAndOr = e;
        }

        function handleDesignerChange(e: string) {
            state.searchObject.designerAndOr = e;
        }

        function handleEstimatorChange(e: string) {
            state.searchObject.estimatorAndOr = e;
        }

        function handlePlantLocationChange(e: string) {
            state.searchObject.plantLocationAndOr = e;
        }

        function handleFobPlantChange(e: string) {
            state.searchObject.fobPlantAndOr = e;
        }

        function handleProposalIDChange(e: string) {
            state.searchObject.proposalIdAndOr = e;
        }

        function handleSalesRepNameChange(e: string) {
            state.searchObject.salesRepNameAndOr = e;
        }

        function handleAlternateOwnerChange(e: string) {
            state.searchObject.alternateOwnerAndOr = e;
        }

        function handleOpportunityOwnerChange(e: string) {
            state.searchObject.opportunityOwnerAndOr = e;
        }

        function handleLocationChange(e: string) {
            state.searchObject.locationAndOr = e;
        }

        function handleSampleStatusChange(e: string) {
            state.searchObject.sampleStatusAndOr = e;
        }

        function handleQAStatusChange(e: string) {
            state.searchObject.qaStatusAndOr = e;
        }

        function handleUrgencyLevelChange(e: string) {
            state.searchObject.urgencyLevelAndOr = e;
        }

        function handleReasonCodeChange(e: string) {
            state.searchObject.reasonCodeAndOr = e;
        }

        return {
            state,
            filterState,
            handleSearchProposal,
            searchMultipleProposals,
            allowSearchOne,
            urgencyOptions,
            searchProposalByOptions,
            listPodFilters,
            changeColumns,
            ProposalRules,
            WILDCARD_SEARCH_RULES,
            handleCustomerMarketingProgramChange,
            handleAlternateOwnerChange,
            handleUrgencyLevelChange,
            handleContainerIDChange,
            handleCustomerNameChange,
            handleCustomerPartDescChange,
            handleDesignerChange,
            handleDesignReleasedChange,
            handleEstimatorChange,
            handleFobPlantChange,
            handleLocationChange,
            handleReasonCodeChange,
            handlePlantLocationChange,
            handleProductNumberChange,
            handleQAStatusChange,
            handleSalesRepNameChange,
            handleSampleStatusChange,
            handleProposalIDChange,
        }
    }
})
