
import {defineComponent, reactive, watch} from 'vue';
import SideBarNav from "@/views/sidebar/SideBarNav.vue";
import sidebarStore from "@/views/sidebar/store/SidebarStore";
import Loading from "@/views/core/components/Loading.vue";
import rootStore from "@/store/RootStore";
import Alert from "@/views/core/components/Alert.vue";
import ReAuth from "@/views/auth/components/ReAuth.vue";
import ApiConfig from "@/service/api/api-config";
import LoadingProgress from "@/views/core/components/LoadingProgress.vue";
import ProfileSummary from "@/views/user/ProfileSummary.vue";
import AuthService from "@/service/AuthService";

type State = {
    routesHideSideAndNavBar: Array<string>;
    routesHideProposalInfo: Array<string>;
    form_dirty: boolean;
}

export default defineComponent({
    components: {ProfileSummary, SideBarNav, Loading, LoadingProgress, Alert, ReAuth},

    beforeRouteLeave (to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInDirtyForm()){
      next(false)
    } else {
      // Navigate to next view
      next()
    }
    },

    created() {
      window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy() {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },

    methods: {
      confirmLeave() {
        return window.confirm('Do you really want to leave? You have unsaved changes!')
      },

      confirmStayInDirtyForm() {
        if (this.proposalStore.proposal.proposalNumber) {
          return this.state.form_dirty && !this.confirmLeave()
        }
      },

      beforeWindowUnload(e: any) {
        if (this.confirmStayInDirtyForm()) {
          // Cancel the event
          e.preventDefault()
          // Chrome requires returnValue to be set
          e.returnValue = ''
        }
      },
    },
    setup() {
        window.onload = function() {
          window.addEventListener("beforeunload", function (e) {
            if (!state.form_dirty) {
              return undefined;
            }

            var confirmationMessage = 'It looks like you have been editing something. '
              + 'If you leave before saving, your changes will be lost.';

            (e || window.event).returnValue = confirmationMessage;
            return confirmationMessage;
          });
        };

        const loadingStore = rootStore.getInstance().loadingStore;
        const profileStore = rootStore.getInstance().profileStore;
        const proposalStore = rootStore.getInstance().proposalStore;
        const authService = new AuthService();

        const state = reactive<State>({
            routesHideSideAndNavBar: ['LoginScreen'],
            routesHideProposalInfo: ['CopyProposal', 'Search', 'EditMaintenanceItemsScreen', 'ProposalTable', 'AdvancedSearch', "CopyProposalV2",'WorkflowScreen', "DesignerSkills", "CustomerProducts", "DesignQueue", "DesignerQueue"],
            form_dirty: rootStore.getInstance().proposalStore.isDirty
        })

        watch(() => [rootStore.getInstance().proposalStore.isDirty], () => {
          state.form_dirty = rootStore.getInstance().proposalStore.isDirty
        });

        function toggleNav() {
            sidebarStore.getInstance().toggleSidebar();
        }

        function logout() {
            //AuthStore.getInstance().signOut('logout')
            authService.logout()
        }
//depreciated
        function expireToken() {
            ApiConfig.getInstance().setToken('broke')
            ApiConfig.getInstance().setToken('broke')
        }

        return {
            state,
            toggleNav,
            logout,
            loadingStore,
            profileStore,
            proposalStore,
            expireToken
        }
    }
})
