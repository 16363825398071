
import {computed, defineComponent, reactive, ref, toRef, useSlots} from 'vue'
import BFormRadio from './BFormRadio.vue'
// import getID from "@/components/bootstrap-library/utils/getID";
import {getId} from './utils';
import {getGroupAttr, getGroupClasses, optionToElement, slotsToElements, bindGroupProps} from './composables/useFormCheck';
export default defineComponent({
  name: 'b-form-radio-group',
  props: {
    form: {type: String, required: false},
    id: {type: String, required: false},
    modelValue: {type: [String, Boolean, Object as () => Array<unknown>, Object as () => Record<string, unknown>, Number], default: '' },
    autofocus: {type: Boolean, default: false},
    buttons: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    disabledField: {type: String, defalut: 'disabled'},
    htmlField: {type:String, default: 'html'},
    options: {type: Object as () => Array<unknown>, default: []}, // Objects are not supported yet
    plain: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    stacked: {type: Boolean, default: false},
    inline: {type: Boolean, default: true},
    state: {type: Boolean, default: false},
    textField: {type: String, default: 'text'},
    validated: {type: Boolean, default: false},
    valueField: {type: String, default: 'value'}
  },
  emits: ['update:modelValue', 'input', 'change'],
  setup(props, context) {
    const buttonsBoolean = ref(props.buttons);
    const disabledBoolean = ref(props.disabled);
    const requiredBoolean = ref(props.required);
    const stackedBoolean = ref(props.stacked);
    const stateBoolean = ref(props.state);
    const validatedBoolean = ref(props.validated);

    const slots = useSlots()

    const slotsName = 'BFormRadio'

    const computedId = computed( () => {return getId('radio')});

    const computedName = computed(()=> {return getId('checkbox')});

    const localValue = computed<string | boolean | Array<unknown> | Record<string, unknown> | number>({
      get: () => props.modelValue,
      set: (newValue: any) => {
        context.emit('input', newValue)
        context.emit('update:modelValue', newValue)
        context.emit('change', newValue)
      },
    })

    const checkboxList = computed<Array<any>>(() =>
        (slots.first ? slotsToElements(slots.first(), slotsName, disabledBoolean.value) : [])
            .concat(props.options.map((e) => optionToElement(e, props)))
            .concat(slots.default ? slotsToElements(slots.default(), slotsName, disabledBoolean.value) : [])
            .map((e: any, idx: any) => bindGroupProps(e, idx, props, computedName, computedId))
            .map((e: any) => ({
              ...e,
            }))
    )

    const classesObject = reactive({
      required: toRef(requiredBoolean, 'value'),
      state: toRef(stateBoolean, 'value'),
      validated: toRef(validatedBoolean, 'value'),
      buttons: toRef(buttonsBoolean, 'value'),
      stacked: toRef(stackedBoolean, 'value'),
    })

    const attrs = getGroupAttr(classesObject)
    const classes = getGroupClasses(classesObject)

    return {
      computedId,
      checkboxList,
      localValue,
      attrs,
      classes
    }
  }

})
