
// import type {BCardSubtitleProps} from '../../types/components'
import type {TextColorVariant} from '../../types'
import {computed, defineComponent} from 'vue'

export default defineComponent({
  name: 'b-card-subtitle',
  props: {
    text: {type: String},
    tag: {type: String, default: 'h6'},
    textVariant: {type: String as () => TextColorVariant, default: 'muted'}
  },
  setup(props, context) {

    const classes = computed(() => ({
      [`text-${props.textVariant}`]: !!props.textVariant,
    }))

    return {
      classes
    }
  }
})

