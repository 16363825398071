export default class StatusLogEntry {

    public id!: number;
    public proposalID!: number;
    public loggedUser!: string;
    public fromQaStatus!: string;
    public toQaStatus!: string;
    public timestamp!: string;
    public fromTransition!: string;
    public toTransition!: string;

    public readableTimestamp!: string;

    constructor(init?: Partial<StatusLogEntry>) {
        Object.assign(this, init);
        this.fixLocalTimesToUTC();
    }

    /**
     * This function should be run on any proposal returned from DB.
     * Due to a limitation of JDBI, we cannot include timezone information when retrieving data from DB (without screwing up inserts/updates),
     * so here we correct all date-time fields by treating them as UTC instead of LocalTime.
     **/
    public fixLocalTimesToUTC() {
        if(this.timestamp) {
            const tmpDate = new Date(this.timestamp);
            tmpDate.setTime(tmpDate.getTime() - tmpDate.getTimezoneOffset() * 60 * 1000);
            this.timestamp = tmpDate.toISOString();
        }
    }
}