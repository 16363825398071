type StringRules = {
    maxLength?: number;
    required?: boolean;
}

type NumberRules = {
    min?: number;
    max?: number;
    allowDecimal?: boolean;
    required?: boolean;
}

export class ProposalRules {
    static approvedBy: StringRules = {
        maxLength: 15
    }
    static approvedOn: NumberRules = {} //timestamp
    static customerMarketingProgram: StringRules = {
        maxLength: 150
    }
    static competition: StringRules = {
        maxLength: 75
    }
    static productDescription: StringRules = {
        maxLength: 80
    }
    static containerID: StringRules = {
        maxLength: 75
    }
    static containerType: StringRules = {
        maxLength: 75
    }
    static currency: StringRules = {
        maxLength: 15
    }
    static customerName: StringRules = {
        maxLength: 75
    }
    static customerPartDesc: StringRules = {
        maxLength: 75
    }
    static customerPartNum: StringRules = {
        maxLength: 75
    }
    static customerProjectNum: StringRules = {
        maxLength: 75
    }
    static dateEntered: StringRules = {
        maxLength: 75
    } // date
    static dateRequested: StringRules = {
        maxLength: 75
    } // date
    static designerStartDate: StringRules = {
        maxLength: 75
    } // date
    static dunnageAttachment: StringRules = {
        maxLength: 15
    }
    static dunnageDescription: StringRules = {
        maxLength: 75
    }
    static externalText: StringRules = {
        maxLength: 4000
    }
    static opportunity: StringRules = {
        maxLength: 75
    }
    static packDensity: NumberRules = {}
    static dunnageWeight: NumberRules = {
        allowDecimal: true
    }
    static plantLocation: StringRules = {} //fk
    static productHierarchy: StringRules = {
        maxLength: 25
    }
    static proposalID: NumberRules = {} // might be duplicate of proposalNumber
    static proposalNumber: NumberRules = {} // might be duplciate of proposalId
    static projectRequirements: StringRules = {
        maxLength: 250
    }
    static recycleCode: StringRules = {
        maxLength: 15
    }
    static salesOrganization: StringRules = {
        maxLength: 15
    }
    static sampleRequestedDate: StringRules = {
        maxLength: 75
    } // date
    static sampleRequirements: StringRules = {
        maxLength: 250
    }
    static sampleUrgencyLevel: StringRules = {
        maxLength: 15
    }
    static sampleCompleteDate: StringRules = {
        maxLength: 75
    } // date
    static samplePromiseDate: StringRules = {
        maxLength: 75
    } // date
    static validFrom: StringRules = {
        maxLength: 75
    } // date
    static validTo: StringRules = {
        maxLength: 75
    } // date
    static customerContainerModel: StringRules = {
        maxLength: 75
    }
    static customerDunNum: StringRules = {
        maxLength: 75
    }
    static salesOrderNumber: StringRules = {
        maxLength: 75
    }
    //static designWtforVendrQts!: boolean;
    static designer: StringRules = {
        maxLength: 15
    }
    static designerDueDate: StringRules = {
        maxLength: 75
    } // date
    static designerReleaseDate: StringRules = {
        maxLength: 75
    } // date
    static effortCode: StringRules = {
        maxLength: 15
    }
    static estimator: StringRules = {
        maxLength: 15
    }
    static estimatorDueDate: StringRules = {
        maxLength: 75
    } // date
    static holdCode: NumberRules = {}
    static internalComments: StringRules = {
        maxLength: 250
    }
    static projectManager: StringRules = {
        maxLength: 15
    }
    static proposalCompleteDate: StringRules = {
        maxLength: 75
    } // date
    static reasonCode: NumberRules = {} // fk
    static urgencyLevel: StringRules = {
        maxLength: 75
    }
    static productNumber: StringRules = {
        maxLength: 50
    }
    static quantity1: NumberRules = {}
    static quantity2: NumberRules = {}
    static quantity3: NumberRules = {}
    static quantity4: NumberRules = {}
    static quantity5: NumberRules = {}
    static ramorSAM: StringRules = {
        maxLength: 15,
    }
    static sewingTimeFactor: NumberRules = {
        allowDecimal: true
    }
    static customerAssemblyNumber: StringRules = {
        maxLength: 75
    }
    static designComplete: StringRules = {
        maxLength: 75
    }
    static sampleShipDate: StringRules = {
        maxLength: 75
    } // timestamp
    static sampleStartDate: StringRules = {
        maxLength: 75
    } // timestamp
    static sampleQuantity: NumberRules = {}
    static attentionReason: StringRules = {
        maxLength: 25
    }
    static actionRequiredBy: StringRules = {
        maxLength: 25
    }
}
