export default class SideBarMenuChild {
    public static TYPE = {
        HEADER: 'header',
        BUTTON: 'button',
        PARENT: 'parent',
    };

    public title: string = '';
    public id: number = 0;
    public icon?: string = '';
    public routeName: string = ''; // route that you are navigated to on click
    public activeRouteNameArray: string[] = []; // array of routes that makes the child nav "active"
    public children?: SideBarMenuChild[] = [];
    public type: string = ''; // header, parent, button
    public show?: boolean;
    public key?: string;

    constructor(init?: Partial<SideBarMenuChild>) {
        Object.assign(this, init);
    }
}
