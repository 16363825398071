import AmortCode from "@/domain/maintenance/AmortCode";
import editable from "@/domain/decorators/editable";

export default class ProdAmortItem extends editable {
    public amortItemID!: number;
    public amortCode!: number;
    public lineNumber!: number;
    public description!: string;
    public costQty1!: number;
    public costQty2!: number;
    public costQty3!: number;
    public costQty4!: number;
    public costQty5!: number;
    public eachQty1!: number;
    public eachQty2!: number;
    public eachQty3!: number;
    public eachQty4!: number;
    public eachQty5!: number;
    public productBomID!: number;
    public productBomLineNumber!: number; // From productBom
    public quantity!: number; //Client side only/moved to Product BOM
    public comments!: string;
    public overridePrice!: string;

    //Amort_Codes table
    public amortCodeObj!: AmortCode; // Deprecated. We already have  amortCode, this object is not used to make any DB changes, and we aren't using this object for anything on the front-end

    constructor(init?: Partial<ProdAmortItem>) {
        super();
        Object.assign(this, init);
    }

}
