import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5df2eab6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subhead" }
const _hoisted_2 = { class: "subhead-title-buttons" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "subhead-options" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.goBack)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "button button-go-back",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-arrow-left" })
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "title", {}, () => [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
      ], true),
      _renderSlot(_ctx.$slots, "title-buttons", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "options", {}, undefined, true)
    ])
  ]))
}