

import {defineComponent} from "vue";
import rootStore from "@/store/RootStore";

export default defineComponent({
    name: 'loading-progress',
    components: {},
    props: {},
    setup() {

        const loadingStore = rootStore.getInstance().loadingStore;

        return {
            loadingStore,
        }
    }
})

