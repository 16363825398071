import {reactive} from "vue";
import {store} from "@/store/store-decorators";
import {RootStore} from "@/store/RootStore";

export enum LoadingType {
    default,
    copying,
    compBomTable,
    progress,
}

type State = {
    show: boolean;
    type: number;
    busy: boolean;
    showProgress: boolean;
    progressPercent: number;
    progressMessage: string;
}

@store
export default class LoadingStore {
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    // state
    private state = reactive<State>({
        show: false,
        type: LoadingType.default,
        busy: false,
        progressPercent: 0,
        progressMessage: "Loading Startup Data...",
        showProgress: false,
    });

    // getters
    get isLoading(): boolean {
        return this.state.show;
    }

    get loadingType(): number {
        return this.state.type;
    }

    get isBusy(): boolean {
        return this.state.busy
    }

    get progressPercent(): number {
        return this.state.progressPercent
    }

    set progressPercent(value: number) {
        this.state.progressPercent = value;
    }

    get progressMessage(): string {
        return this.state.progressMessage;
    }

    get isLoadingProgress(): boolean {
        return this.state.showProgress
    }

    public showLoadingProgressScreen() {
        this.state.progressPercent = 0;
        this.state.showProgress = true;
    }

    public hideLoadingProgressScreen() {
        setTimeout(() => {
            this.state.showProgress = false;
        },750)
    }

    set isBusy(value: boolean) {
        if (value) {
            //cursor: progress !important;
            (document.getElementById("body") as HTMLElement).classList.add("busy")
        } else {
            (document.getElementById("body") as HTMLElement).classList.remove("busy")
        }
        this.state.busy = value;
    }

    // setters
    public showLoadingScreen(type?: number) {
        if (type) {
            this.state.type = type;
        }
        this.state.show = true;
    }

    public hideLoadingScreen() {
        setTimeout(() => {
            this.state.show = false;
            this.state.type = LoadingType.default;
        }, 0)
    }


}
