import SampleMaker from "@/domain/maintenance/SampleMaker";

export default class Plant {
    public plantID!: number;
    public name!: string;
    public city!: string;
    public state!: string;
    public country!: string;
    public sOrg!: number;
    public rigid!: boolean;
    public fabric!: boolean;
    public maximus!: boolean;
    public samples!: boolean;
    public valueAdded!: boolean;
    public racks!: boolean;
    public currency!: string;
    public foamDie!: boolean;
    public foamWJC!: boolean;
    public foamSaw!: boolean;
    public pioneerDie!: boolean;
    public clamshellDie!: boolean;
    public marumatsuDie!: boolean;
    public sampleMakers!: Array<SampleMaker>;

    constructor(init?: Partial<Plant>) {
        Object.assign(this, init);
    }

}
