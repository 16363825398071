import TimeZoneTranslator from "@/utility/TimeZoneTranslator";

export default class SalesforceProposalData {

    public proposalID!: number;
    public opportunityNumber!: string;
    public opportunityID!: number;
    public opportunityName!: string;
    public opportunityOwner!: string;
    public customerName!: string;
    public alternateOwner!: string;
    public estShipDate!: string; // timestamp
    public projectManager!: string;
    public salesRep!: string;
    public customerMarketingProgram!: string;
    public action!: string;
    public orbisProgramCode!: string;
    public oemProgramName!: string;

    constructor(init?: Partial<SalesforceProposalData>) {
        Object.assign(this, init);
        this.fixLocalTimesToUTC();
    }

    /**
     * This function should be run on any proposal returned from DB.
     * Due to a limitation of JDBI, we cannot include timezone information when retrieving data from DB (without screwing up inserts/updates),
     * so here we correct all date-time fields by treating them as UTC instead of LocalTime.
     **/
    public fixLocalTimesToUTC() {
        this.estShipDate = TimeZoneTranslator.toClientTime(this.estShipDate);
    }

}
