export default class WorkCenter {
    public plantCode!: string;
    public workCenter!: string;
    public workCenterDescription!: string;
    public setupRate!: number;
    public manRate!: number;
    public machineRate!: number;
    public changedBy!: string;
    public mainWorkCenter!: number;
    public formulaCode!: number;
    public machineRateBurdenO!: number;
    public machineRateBurdenP!: number;
    public operationUOfM!: string;
    public deletionIndicator!: boolean;
    public shortDescription!: string;

    constructor(init?: Partial<WorkCenter>) {
        Object.assign(this, init);
    }
}
