import SideBarMenuChild from "./SideBarMenuChild";

export default class SideBarMenuItem {
    public static TYPE = {
        HEADER: 'header',
        BUTTON: 'button',
        PARENT: 'parent',
    };

    public id: number = 0;
    public title: string = '';
    public icon?: string = '';
    public routeName?: string = '';
    public children?: SideBarMenuChild[] = [];
    public type: string = ''; // header, parent, button
    public show?: boolean = true;

    constructor(init?: Partial<SideBarMenuItem>) {
        Object.assign(this, init);
    }
}
