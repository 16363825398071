export default class HierarchyType {

    public description!: string;

    constructor(init?: Partial<HierarchyType>) {
        Object.assign(this, init)
    }

    public update(init?: Partial<HierarchyType>) {
        Object.assign(this, init)
    }

}
