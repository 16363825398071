
import { computed, defineComponent, reactive } from 'vue';
// import useScreenSize from '@/composable/useScreenSize';

type MenuStyle = {
    bottom?: number | string;
    right?: number | string;
    left?: number | string;
}

export default defineComponent({
    name: 'b-dropdown',
    props: {
        title: { type: String, default: undefined },
        right: { type: Boolean, default: true },
        left: Boolean,
        closeOnClick: Boolean,
        noCaret: { type: Boolean, default: false },
        dropUp: { type: Boolean, default: false },
        openOnHover: { type: Boolean, default: false },
        isButton: { type: Boolean, default: false },
        customIcon: {type: String}
    },
    setup(props) {
        // const { isMobile } = useScreenSize();
        const displayIcon = props.customIcon? props.customIcon : props.dropUp? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down';

        const state = reactive({
            show: false,
        });

        const menuClass = props.dropUp ? 'dropup' : 'dropdown';

        const menuStyle = computed((): MenuStyle => {
            if (props.dropUp) {
                if (props.left) {
                    return {
                        bottom: '2.4rem',
                        right: 0,
                        left: 'auto',
                    };
                }
                return {
                    bottom: '2.4rem',
                    right: 'auto',
                    left: 0,
                };
            }
            if (props.left) {
                return {
                    right: 0,
                    left: 'auto',
                };
            }
            return {
                right: 'auto',
                left: 0,
            };
        });

        function handleClickOutside() {
            if (state.show) {
                state.show = false;
            }
        }

        function handleClick() {
            if (props.closeOnClick) {
                state.show = false;
            }
        }

        function handleMouseOver() {
            // if (props.openOnHover && !isMobile.value) {
            //     state.show = true;
            // }
        }

        return {
            state,
            menuClass,
            menuStyle,
            handleClickOutside,
            handleClick,
            handleMouseOver,
            displayIcon
        };
    },
});
