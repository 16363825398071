
import {defineComponent} from "vue";
import rootStore from "@/store/RootStore";

export default defineComponent({
  name: "ProfileSummary",
  props: {
    hasFooterSlot: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const profileStore = rootStore.getInstance().profileStore;
    return {
      profileStore
    }
  }
})
