import MaintenanceApiService from "@/service/api/MaintenanceApiService";
import BaseService from "@/service/BaseService";
import {useNotification} from "@/views/core/functions/useNotification";
import {MaintenanceClassName} from "@/service/api/types/ApiResponseTypes";
import WorkflowGroupAction from "@/domain/maintenance/workflow/WorkflowGroupAction";
import WorkflowCondition from "@/domain/maintenance/workflow/WorkflowCondition";
import WfField from "@/domain/maintenance/workflow/WfField";
import WorkflowAction from "@/domain/maintenance/workflow/WorkflowAction";
import WorkflowGARule from "@/domain/maintenance/workflow/WorkflowGARule";
import WorkflowGroup from "@/domain/maintenance/workflow/WorkflowGroup";
import User from "@/domain/profile/User";
import MomentumCustomer from "@/domain/maintenance/MomentumCustomer";
import MomentumProduct from "@/domain/MomentumProduct";
import UserLocation from "@/domain/maintenance/UserLocation";
import Location from "@/domain/maintenance/Location";
import LogService from "@/service/LogService";

export default class MaintenanceService extends BaseService {

    private maintenanceApiService!: MaintenanceApiService;
    private logService: LogService;

    constructor() {
        super()
        this.maintenanceApiService = new MaintenanceApiService();
        this.logService = new LogService();
    }

    public async getAllMaterials(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAllMaterials();
        if (response.success) {
            this.rootStore.maintenanceStore.materials = response.items;
        }
        return response.success;
    }

    public async getAllContainerModels(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAllContainerModels();
        if (response.success) {
            this.rootStore.maintenanceStore.containerModels = response.items;
        }
        return response.success;
    }

    public async getAllAirCellRules(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAirCellRules();
        if (response.success) {
            this.rootStore.maintenanceStore.airCellRules = response.items;
        }
        return response.success;
    }

    public async getAllAmortCodes(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAllAmortCodes();
        if (response.success) {
            this.rootStore.maintenanceStore.amortCodes = response.items;
        }
        return response.success;
    }

    public async getAllCustomerPartTypes(): Promise<boolean> {
        const response = await this.maintenanceApiService.getCustomerPartTypes();
        if (response.success) {
            this.rootStore.maintenanceStore.customerPartTypes = response.items;
        }
        return response.success;
    }

    public async getAllDesignAttentions(): Promise<boolean> {
        const response = await this.maintenanceApiService.getDesignAttentions();
        if (response.success) {
            this.rootStore.maintenanceStore.designAttentions = response.items;
        }
        return response.success;
    }

    // Deprecated. Being replaced by ProposalStatuses.
    public async getQaStatuses(): Promise<boolean> {
        const response = await this.maintenanceApiService.getQaStatuses();
        if (response.success) {
            let index = 0;
            for (const item of response.items) {
                this.rootStore.maintenanceStore.qaStatuses.push({ name: item, value: index});
                index++;
            }
            //this.rootStore.maintenanceStore.qaStatuses = response.items;
        }
        return response.success;
    }

    public async getProposalStatuses(): Promise<boolean> {
        const response = await this.maintenanceApiService.getProposalStatuses();
        if(response.success) {
            this.rootStore.maintenanceStore.proposalStatuses = response.items;
        }
        return response.success;
    }

    public async getProductNumbers(): Promise<boolean> {
        const response = await this.maintenanceApiService.getProductNumbers();
        if (response.success) {
            this.rootStore.maintenanceStore.productNumbers = response.items;
        }
        return response.success;
    }

    public async getCustomerPartDescs(): Promise<boolean> {
        const response = await this.maintenanceApiService.getCustomerPartDescs();
        if (response.success) {
            this.rootStore.maintenanceStore.customerPartDescs = response.items;
        }
        return response.success;
    }

    public async getAllHeatSealingRules(): Promise<boolean> {
        const response = await this.maintenanceApiService.getHeatSealingRules();
        if (response.success) {
            this.rootStore.maintenanceStore.heatSealingRules = response.items;
        }
        return response.success;
    }

    public async getAllHierarchies(): Promise<boolean> {
        const response = await this.maintenanceApiService.getHierarchies();
        if (response.success) {
            this.rootStore.maintenanceStore.hierarchies = response.items;
        }
        return response.success;
    }

    public async getAllHoldCodes(): Promise<boolean> {
        const response = await this.maintenanceApiService.getHoldCodes();
        if (response.success) {
            this.rootStore.maintenanceStore.holdCodes = response.items;
        }
        return response.success;
    }

    public async getAllMaterialBlanks(): Promise<boolean> {
        const response = await this.maintenanceApiService.getMaterialBlanks();
        if (response.success) {
            this.rootStore.maintenanceStore.materialBlanks = response.items;
        }
        return response.success;
    }

    public async getMaterialUseCodes(): Promise<boolean> {
        const response = await this.maintenanceApiService.getMaterialUseCodes();
        if (response.success) {
            this.rootStore.maintenanceStore.materialUseCodes = response.items;
        }
        return response.success;
    }

    public async getAllMfgFactors(): Promise<boolean> {
        const response = await this.maintenanceApiService.getMFGFactor();
        if (response.success) {
            this.rootStore.maintenanceStore.mfgFactors = response.items;
        }
        return response.success;
    }

    public async getAllMfgRates(): Promise<boolean> {
        const response = await this.maintenanceApiService.getMFGRates();
        if (response.success) {
            this.rootStore.maintenanceStore.mfgRates = response.items;
        }
        return response.success;
    }

    public async getAllPlants(): Promise<boolean> {
        const response = await this.maintenanceApiService.getPlants();
        if (response.success) {
            this.rootStore.maintenanceStore.plants = response.items;
        }
        return response.success
    }

    public async getAllProposalSearchFilters(): Promise<boolean> {
        const response = await this.maintenanceApiService.getProposalSearchFilters();
        if (response.success) {
            this.rootStore.maintenanceStore.proposalSearchFilters = response.items;
        }
        return response.success
    }

    public async getAllProjectDefaults(): Promise<boolean> {
        const response = await this.maintenanceApiService.getProjectDefaults();
        if (response.success) {
            this.rootStore.maintenanceStore.projectDefaults = response.items;
        }
        return response.success;
    }

    public async getAllProjectTypeCodes(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAllProjectTypeCodes();
        if (response.success) {
            this.rootStore.maintenanceStore.projectTypeCodes = response.items;
        }
        return response.success;
    }

    public async getAllReasons(): Promise<boolean> {
        const response = await this.maintenanceApiService.getReasons();
        if (response.success) {
            this.rootStore.maintenanceStore.reasons = response.items;
        }
        return response.success
    }

    public async getAllRecycleCodes(): Promise<boolean> {
        const response = await this.maintenanceApiService.getRecycleCodes();
        if (response.success) {
            this.rootStore.maintenanceStore.recycleCodes = response.items;
        }
        return response.success;
    }

    public async getAllWorkCenters(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAllWorkCenters();
        if (response.success) {
            this.rootStore.maintenanceStore.workCenters = response.items;
        }
        return response.success;
    }

    public async getAllWCOperations(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAllWCOperations();
        if (response.success) {
            this.rootStore.maintenanceStore.wcOperations = response.items;
        }
        return response.success;
    }

    public async getAllWCMaint(): Promise<boolean> {
        const response = await this.maintenanceApiService.getWCMaints();
        if (response.success) {
            this.rootStore.maintenanceStore.wcMaints = response.items;
        }
        return response.success;
    }

    public async getAllToolingRates(): Promise<boolean> {
        const response = await this.maintenanceApiService.getToolingRates();
        if (response.success) {
            this.rootStore.maintenanceStore.toolingRates = response.items;
        }
        return response.success;
    }

    public async getAllRoutingPaths(): Promise<boolean> {
        const response = await this.maintenanceApiService.getRoutingPaths();
        if (response.success) {
            this.rootStore.maintenanceStore.routingPaths = response.items;
        }
        return response.success;
    }

    public createHierarchyTypes() {
        this.rootStore.maintenanceStore.createHierarchyTypes();
    }

    public async getAllCurrencies(): Promise<boolean> {
        const response = await this.maintenanceApiService.getCurrencies();
        if (response.success) {
            this.rootStore.maintenanceStore.currencies = response.items;
        }
        return response.success;
    }

    public async getCustomerNames(): Promise<boolean> {
        const response = await this.maintenanceApiService.getCustomerNames();
        if (response.success) {
            this.rootStore.maintenanceStore.customerNames = response.items;
        }
        return response.success;
    }

    public async getSampleAddresses(): Promise<boolean> {
        const response = await this.maintenanceApiService.getSampleAddresses();
        if (response.success) {
            this.rootStore.maintenanceStore.sampleAddresses = response.items;
        }
        return response.success;
    }

    public async getSampleCities(): Promise<boolean> {
        const response = await this.maintenanceApiService.getSampleCities();
        if (response.success) {
            this.rootStore.maintenanceStore.sampleCities = response.items;
        }
        return response.success;
    }

    public async getSampleStates(): Promise<boolean> {
        const response = await this.maintenanceApiService.getSampleStates();
        if (response.success) {
            this.rootStore.maintenanceStore.sampleStates = response.items;
        }
        return response.success;
    }

    public async getSampleCountries(): Promise<boolean> {
        const response = await this.maintenanceApiService.getSampleCountries();
        if (response.success) {
            this.rootStore.maintenanceStore.sampleCountries = response.items;
        }
        return response.success;
    }

    public async getSampleZips(): Promise<boolean> {
        const response = await this.maintenanceApiService.getSampleZips();
        if (response.success) {
            this.rootStore.maintenanceStore.sampleZips = response.items;
        }
        return response.success;
    }

    public async getSampleAttentionTos(): Promise<boolean> {
        const response = await this.maintenanceApiService.getSampleAttentionTos();
        if (response.success) {
            this.rootStore.maintenanceStore.sampleAttentionTos = response.items;
        }
        return response.success;
    }

    public async getSamplePhones(): Promise<boolean> {
        const response = await this.maintenanceApiService.getSamplePhones();
        if (response.success) {
            this.rootStore.maintenanceStore.samplePhones = response.items;
        }
        return response.success;
    }

    public async getProjectManagers(): Promise<boolean> {
        const response = await this.maintenanceApiService.getProjectManagers();
        if (response.success) {
            this.rootStore.maintenanceStore.projectManagers = response.items;
        }
        return response.success;
    }

    public async getRamSam(): Promise<boolean> {
        const response = await this.maintenanceApiService.getRamSam();
        if (response.success) {
            this.rootStore.maintenanceStore.ramSam = response.items;
        }
        return response.success;
    }

    public async getCrmCsm(): Promise<boolean> {
        const response = await this.maintenanceApiService.getCrmCsm();
        if (response.success) {
            this.rootStore.maintenanceStore.crmCsm = response.items;
        }
        return response.success;
    }

    public async getActiveUsers(): Promise<boolean> {
        const response = await this.maintenanceApiService.getActiveUsers();
        if (response.success) {
            this.rootStore.maintenanceStore.activeUsers = response.items;
        }
        return response.success;
    }

    public async getApprovedBy(): Promise<boolean> {
        const response = await this.maintenanceApiService.getApprovedBy();
        if (response.success) {
            this.rootStore.maintenanceStore.approvedBy = response.items;
        }
        return response.success;
    }

    public async getDesigners(): Promise<boolean> {
        const response = await this.maintenanceApiService.getDesigners();
        if (response.success) {
            const designers: User[] = [];
            response.items.forEach((userJSON) => {
                designers.push(new User(userJSON));
            })
            this.rootStore.maintenanceStore.designers = designers;
        }
        return response.success;
    }

    /**
     * Only updates skills for the designer in current implementation
     * @param designer
     */
    public async updateDesigner(designer: User): Promise<boolean> {
        const original = this.rootStore.maintenanceStore.designers.find((des) => {
            return des.userID == designer.userID;
        })
        const response = await this.maintenanceApiService.updateDesigner(designer);

        if(response.success) {
            const index = original? this.rootStore.maintenanceStore.designers.indexOf(original) : -1;
            if(index >= 0) {
                this.rootStore.maintenanceStore.designers[index] = designer;
            } else {
                useNotification().showSimple('Error')
                console.log("Couldn't refresh designer after successful update");
            }
        }
        return response.success;

    }

    public async getEstimators(): Promise<boolean> {
        const response = await this.maintenanceApiService.getEstimators();
        if (response.success) {
            this.rootStore.maintenanceStore.estimators = response.items;
        }
        return response.success;
    }

    public async getSampleLocations(): Promise<boolean> {
        const response = await this.maintenanceApiService.getSampleLocations();
        if (response.success) {
            this.rootStore.maintenanceStore.sampleLocations = response.items;
        }
        return response.success;
    }

    public async getSampleCodes(): Promise<boolean> {
        const response = await this.maintenanceApiService.getSampleCodes();
        if (response.success) {
            this.rootStore.maintenanceStore.sampleCodes = response.items;
        }
        return response.success;
    }

    public async getSampleMakers(): Promise<boolean> {
        const response = await this.maintenanceApiService.getSampleMakers();
        if(response.success) {
            this.rootStore.maintenanceStore.sampleMakers = response.items
        }
        return response.success;
    }

    public async  getDunnageAttachments(): Promise<boolean> {
        const response = await this.maintenanceApiService.getDunnageAttachments();
        if (response.success) {
            this.rootStore.maintenanceStore.dunnageAttachments = response.items;
        }
        return response.success;
    }

    public async getCommentTypes(): Promise<boolean> {
        const response = await this.maintenanceApiService.getCommentTypes();
        if (response.success) {
            this.rootStore.maintenanceStore.commentTypes = response.items;
        }
        return response.success;
    }

    public async getSampleStatuses(): Promise<boolean> {
        const response = await this.maintenanceApiService.getSampleStatuses();
        if (response.success) {
            this.rootStore.maintenanceStore.sampleStatuses = response.items;
        }
        return response.success;
    }

    /*
        Typically the services handle all the actions/store changes.  To avoid duplicate code the table services
        handle the individual changes while this layer handles errors
     */

    public async addItem<T>(item: T, className: MaintenanceClassName): Promise<{ success: boolean; item?: T }> {
        const response = await this.maintenanceApiService.insertMaintenanceItem<T>(item, className)
        if (response.success) {
            useNotification().showSimple('Saved')
            return {success: response.success, item: response.item}
        } else {
            useNotification().showSimple('Error');
            return {success: false}
        }
    }

    public async editItem<T>(item: T, className: MaintenanceClassName, rowIdentifier: String): Promise<{ success: boolean; item?: T }> {
        const response = await this.maintenanceApiService.updateMaintenanceItem<T>(item, className, rowIdentifier);
        if (response.success) {
            useNotification().showSimple('Saved')
            return {success: response.success, item: response.item}
        } else {
            useNotification().showSimple('Error');
            return {success: false}
        }
    }

    public async deleteItem<T>(item: T, className: MaintenanceClassName): Promise<{ success: boolean }> {
        const response = await this.maintenanceApiService.deleteMaintenanceItem<T>(item, className)
        if (response.success) {
            useNotification().showSimple('Saved')
            return {success: response.success}
        } else {
            useNotification().showSimple('Error');
            return {success: false}
        }
    }

    public async getAllWorkflowGroups(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAllWorkflowGroups();
        if (response.success) {
            this.rootStore.maintenanceStore.workflowGroups = response.items;
        }
        return response.success;
    }

    public async duplicateGroupAction(actionId: number): Promise<boolean> {
        const response = await this.maintenanceApiService.duplicateGroupAction(actionId);
        if (response.success) {
            this.rootStore.maintenanceStore.workflowGroupActions = response.items;
        }
        return response.success;
    }

    public async deleteGroupAction(action: WorkflowGroupAction): Promise<boolean> {
        const response = await this.maintenanceApiService.deleteGroupAction(action);
        if (response.success) {
            this.rootStore.maintenanceStore.workflowGroupActions = response.items;
        }
        return response.success;
    }

    public async deleteWorkflowRule(rule: WorkflowGARule): Promise<boolean> {
        const response = await this.maintenanceApiService.deleteWorkflowRule(rule);
        if (response.success && response.item) {
            this.rootStore.maintenanceStore.workflowGARules = response.item.workflowGARules;
            this.rootStore.maintenanceStore.workflowConditions = response.item.workflowConditions;
        }
        return response.success;
    }

    public async deleteWorkflowCondition(condition: WorkflowCondition): Promise<boolean> {
        const response = await this.maintenanceApiService.deleteWorkflowCondition(condition);
        if (response.success) {
            this.rootStore.maintenanceStore.workflowConditions = response.items;
        }
        return response.success;
    }

    public async deleteWorkflowAction(action: WorkflowAction): Promise<boolean> {
        const response = await this.maintenanceApiService.deleteWorkflowAction(action);
        if (response.success) {
            this.rootStore.maintenanceStore.workflowActions = response.items;
        }
        return response.success;
    }

    public async getAllWorkflowActions(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAllWorkflowActions();
        if (response.success) {
            this.rootStore.maintenanceStore.workflowActions = response.items;
        }
        return response.success;
    }

    public async getAllWorkflowGroupActions(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAllWorkflowGroupActions();
        if (response.success) {
            this.rootStore.maintenanceStore.workflowGroupActions = response.items;
        }
        return response.success;
    }

    public async getAllWorkflowConditions(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAllWorkflowConditions();
        if (response.success) {
            this.rootStore.maintenanceStore.workflowConditions = response.items;
        }
        return response.success;
    }

    public async getWorkflowGARulesByActionId(workflowGroupActionId: number): Promise<boolean> {
        if (workflowGroupActionId) {
            const response = await this.maintenanceApiService.getWorkflowGARulesByActionId(workflowGroupActionId);
            if (response.success) {
                this.rootStore.maintenanceStore.workflowGARules = response.items;
            }
            return response.success;
        }
        return false;
    }

    public async getWorkflowGARules(): Promise<boolean> {
        const response = await this.maintenanceApiService.getWorkflowGARules();
        if (response.success && response.item) {
            this.rootStore.maintenanceStore.workflowGARules = response.item.workflowGARules;
            this.rootStore.maintenanceStore.workflowConditions = response.item.workflowConditions;
        }
        return response.success;
    }

    public async updateWorkflowRuleParentCondition(workflowGARule: WorkflowGARule, cond: WorkflowCondition) {
        if (cond.parentCond) {
            workflowGARule.workflowConditionId = cond.workflowConditionId;
        } else {
            workflowGARule.workflowConditionId = -1;
        }
        await this.saveWorkflowGARules([workflowGARule]);
    }

    public async copyWorkflowRule(workflowRule: WorkflowGARule): Promise<boolean> {
        const response = await this.maintenanceApiService.copyWorkflowActionRule(workflowRule);
        if (response.success && response.item) {
            this.rootStore.maintenanceStore.workflowGARules = response.item?.workflowGARules;
            this.rootStore.maintenanceStore.workflowConditions = response.item?.workflowConditions;
        }
        return response.success;
    }
    //pending review of refactor (not used)
    public async getAllWorkflowActionStatuses(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAllWorkflowActionStatuses();
        if (response.success) {
            this.rootStore.maintenanceStore.workflowActionStatuses = response.items;
        }
        return response.success;
    }

    //pending review of refactor (not used)
    public async getAllWfActivityTypes(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAllWfActivityTypes();
        if (response.success) {
            this.rootStore.maintenanceStore.workflowActivityTypes = response.items;
        }
        return response.success;
    }

    public async saveWorkflowAction(workflowAction: WorkflowGroupAction): Promise<boolean> {
        const response = await this.maintenanceApiService.saveWorkflowAction(workflowAction);
        if (response.success) {
            useNotification().showSimple('Saved Actions')
            this.rootStore.maintenanceStore.workflowGroupActions = response.items;
        } else {
            useNotification().showSimple('Error');
        }
        return response.success;
    }

    public async saveWorkflowFields(workflowFields: Array<WfField>): Promise<boolean> {
        const response = await this.maintenanceApiService.saveWorkflowFields(workflowFields);
        if (response.success) {
            useNotification().showSimple('Saved Fields')
            this.rootStore.maintenanceStore.workflowCondFields = response.items;
        } else {
            useNotification().showSimple('Error');
        }
        return response.success;
    }

    public async saveWorkflowActionConditions(workflowConditions: Array<WorkflowCondition>): Promise<boolean> {
        const response = await this.maintenanceApiService.saveWorkflowConditions(workflowConditions);
        if (response.success) {
            useNotification().showSimple('Saved');
            this.rootStore.maintenanceStore.workflowConditions = response.items.sort((a,b) => {
                return a.sortOrder > b.sortOrder ? 1: -1;
            });
        } else {
            useNotification().showSimple('Error');
        }
        return response.success;
    }

    public async getWorkflowFieldData(): Promise<boolean> {
        const response = await this.maintenanceApiService.getWorkflowFieldData();
        if (response.success) {
            if (response.item?.wfFields) {
                this.rootStore.maintenanceStore.workflowCondFields = response.item.wfFields;
            }
            if (response.item?.wfFieldTypes) {
                this.rootStore.maintenanceStore.workflowFieldTypes = response.item.wfFieldTypes;
            }
            if (response.item?.wfOperands) {
                this.rootStore.maintenanceStore.workflowOperands = response.item.wfOperands;
            }
            if (response.item?.wfTables) {
                this.rootStore.maintenanceStore.workflowTables = response.item.wfTables;
            }
        }
        return response.success;
    }

    public async validateWorkflowFieldData(): Promise<boolean> {
        const response = await this.maintenanceApiService.validateWorkflowFieldData();
        if (response.success) {
            await this.getWorkflowFieldData();
        }
        return response.success;
    }

    public async saveWorkflowGARules(workflowGARules: Array<WorkflowGARule>): Promise<boolean> {
        const response = await this.maintenanceApiService.saveWorkflowGARules(workflowGARules);
        if (response.success) {
            useNotification().showSimple('Saved')
            this.rootStore.maintenanceStore.workflowGARules = response.items;
        } else {
            useNotification().showSimple('Error');
        }
        return response.success;
    }

    public async saveWorkflowActions(actions: Array<WorkflowAction>): Promise<boolean> {
        const response = await this.maintenanceApiService.saveWorkflowActions(actions);
        if (response.success) {
            useNotification().showSimple('Saved')
            this.rootStore.maintenanceStore.workflowActions = response.items;
        } else {
            useNotification().showSimple('Error');
        }
        return response.success;
    }

    public async saveWorkflowGroups(groups: Array<WorkflowGroup>): Promise<boolean> {
        const response = await this.maintenanceApiService.saveWorkflowGroups(groups);
        if (response.success) {
            useNotification().showSimple('Saved')
            this.rootStore.maintenanceStore.workflowGroups = response.items;
        } else {
            useNotification().showSimple('Error');
        }
        return response.success;
    }

    public async getPriceHoldList(): Promise<boolean> {
        const response = await this.maintenanceApiService.getPriceHoldList();
        if (response.success) {
            this.rootStore.maintenanceStore.priceHolds = response.items;
        }
        return response.success;
    }

    public async getProposalControlRules(): Promise<boolean> {
        const response = await this.maintenanceApiService.getProposalControlRules();
        if (response.success) {
            this.rootStore.maintenanceStore.controlRules = response.items;
        }

        return response.success;
    }

    public async getAllSampleCarriers(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAllSampleCarriers();
        if (response.success) {
            this.rootStore.maintenanceStore.sampleCarriers = response.items;
        }
        return response.success;
    }

    public async getAllSkillGroups(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAllSkillGroups();
        if(response.success) {
            this.rootStore.maintenanceStore.skillGroups = response.items;
        }
        return response.success;
    }

    public async getAllMomentumProducts(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAllMomentumProducts();
        if(response.success) {
            this.rootStore.maintenanceStore.momentumProducts = response.items;
        }
        return response.success;
    }

    public async getAllMomentumCustomers(): Promise<boolean> {
        const response = await this.maintenanceApiService.getAllMomentumCustomers();
        if(response.success) {
            const customers: MomentumCustomer[] = [];
            response.items.forEach((customerJSON) => {
                customers.push(new MomentumCustomer(customerJSON));
            })
            console.log(customers);
            this.rootStore.maintenanceStore.momentumCustomers = customers;
        }
        return response.success;
    }

    public async addMomentumCustomer(customer: MomentumCustomer): Promise<boolean> {
        const response = await this.maintenanceApiService.addMomentumCustomer(customer);
        if(response.success) {
            this.rootStore.maintenanceStore.momentumCustomers.push(new MomentumCustomer(response.item));
            useNotification().showSimple("Saved");
        } else {
            useNotification().showSimple("Error");
        }
        return response.success;
    }

    public async addMomentumProductType(productType: MomentumProduct): Promise<boolean> {
        const response = await this.maintenanceApiService.addMomentumProductType(productType);
        if(response.success) {
            this.rootStore.maintenanceStore.momentumProducts.push(response.item as MomentumProduct);
            useNotification().showSimple("Saved");
        } else {
            useNotification().showSimple("Error");
        }
        return response.success;
    }

    public async removeMomentumCustomer(customerID: number): Promise<boolean> {
        const response = await this.maintenanceApiService.removeMomentumCustomer(customerID);
        if(response.success) {
            this.rootStore.maintenanceStore.momentumCustomers = this.rootStore.maintenanceStore.momentumCustomers.filter((customer) => {
                return customer.id != customerID;
            })
            useNotification().showSimple("Saved")
        } else {
            useNotification().showSimple("Error");
        }
        return response.success;
    }

    public async removeMomentumProductType(productTypeID: number): Promise<boolean> {
        const response = await this.maintenanceApiService.removeMomentumProductType(productTypeID);
        if(response.success) {
            this.rootStore.maintenanceStore.momentumProducts = this.rootStore.maintenanceStore.momentumProducts.filter((productType) => {
                return productType.id != productTypeID;
            })
            useNotification().showSimple("Saved");
        } else {
            useNotification().showSimple("Error");
        }
        return response.success;
    }

    public async updateMomentumCustomer(customer: MomentumCustomer) {
        const response = await this.maintenanceApiService.updateMomentumCustomer(customer);
        if(response.success) {
            useNotification().showSimple("Saved");
        } else {
            useNotification().showSimple("Error");
        }
        return response.success;
    }

    public async getLocations(): Promise<boolean> {
        const response = await this.maintenanceApiService.getLocations();
        if (response.success) {
            this.rootStore.userLocationStore.locations = response.items;
        }
        return response.success;
    }

    public async getUserLocations(): Promise<boolean> {
        const response = await this.maintenanceApiService.getUserLocations();
        if (response.success) {
            this.rootStore.userLocationStore.userLocations = response.items;
        }
        return response.success;
    }

    public async addLocation(locationName: string): Promise<boolean> {
        let location = new Location();
        location.active = true;
        location.description = locationName;
        const response = await this.maintenanceApiService.addLocation(location);
        if (response.success) {
            console.log(response);
            this.rootStore.userLocationStore.locations = response.items;
            useNotification().showSimple('Saved')
        } else {
            useNotification().showSimple('Error');
        }
        return response.success;
    }

    public async addUserLocations(userLocations: Array<UserLocation>): Promise<boolean> {
        const response = await this.maintenanceApiService.addUserLocations(userLocations);
        if (response.success) {
            this.rootStore.userLocationStore.userLocations = response.items;
            useNotification().showSimple('Saved')
        } else {
            useNotification().showSimple('Error');
        }
        return response.success;
    }

    public async deleteLocation(location: Location): Promise<boolean> {
        const response = await this.maintenanceApiService.deleteLocation(location.locationId);
        if (response.success) {
            this.rootStore.userLocationStore.locations = response.items;
            useNotification().showSimple('Saved')
        } else {
            useNotification().showSimple('Error');
        }
        return response.success;
    }

    public async deleteUserLocation(userLocation: UserLocation): Promise<boolean> {
        if (userLocation.userLocationId) {
            const response = await this.maintenanceApiService.deleteUserLocation(userLocation.userLocationId);
            if (response.success) {
                this.rootStore.userLocationStore.userLocations = response.items;
                useNotification().showSimple('Saved')
            } else {
                useNotification().showSimple('Error');
            }
            return response.success;
        }
        useNotification().showSimple('Error');
        return false;
    }
}
