
// import type {BCardBodyProps} from '../../types/components'
import {computed, defineComponent, ref} from 'vue'
import type {ColorVariant, TextColorVariant} from '../../types'

export default defineComponent({
  name: 'b-card-body',
  props: {
    bodyBgVariant: {type: Object as () => ColorVariant},
    bodyTag: {type: String, default: 'div'},
    bodyTextVariant: {type: Object as () => TextColorVariant},
    overlay: {type: Boolean, default: false},
    subTitle: {type: String},
    subTitleTag: {type: String, default: 'h4'},
    subTitleTextVariant: {type: Object as () => TextColorVariant},
    title: {type: String},
    titleTag: {type: String, default: 'h4'},
    text: {type: String}
  },
  setup(props, context) {

    const overlayBoolean = ref(props.overlay);

    const classes = computed(() => ({
      'card-img-overlay': overlayBoolean.value,
      [`text-${props.bodyTextVariant}`]: props.bodyTextVariant !== undefined,
      [`bg-${props.bodyBgVariant}`]: props.bodyBgVariant !== undefined,
    }))

    return {
      classes
    }
  }
})
