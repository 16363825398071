import {store} from "@/store/store-decorators";
import {RootStore} from "@/store/RootStore";
import {reactive} from "vue";

type State = {
    lastUpdateTime: string;
}

@store
export default class DesignQueueStore {

    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    private state = reactive<State>({
        lastUpdateTime: ''
    })

    set lastUpdateTime(value: string) {
        this.state.lastUpdateTime = value;
    }

    get lastUpdateTime() {
        return this.state.lastUpdateTime;
    }
}