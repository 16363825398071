

import {defineComponent} from "vue";
import { useNotification} from "@/views/core/functions/useNotification";

export default defineComponent({
  name: "alert",
  components: {},
  props: {},
  setup() {

    const {notifications} = useNotification()

    return {
      notifications
    }
  }
})

