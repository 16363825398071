
import { defineComponent } from 'vue';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default defineComponent({
  name: 'warning-icon',
  components: { FontAwesomeIcon },
  props: {
    message: { type: [String], default: undefined },
  },
});
