

import {computed, defineComponent, onBeforeMount} from 'vue';
import BFormRadioGroup from '@/components/bootstrap-library/BFormRadioGroup.vue';
import BFormRadio from '@/components/bootstrap-library/BFormRadio.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue'
import BFormTextarea from "@/components/bootstrap-library/BFormTextarea.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default defineComponent({
    name: 'text-input',
    components: {BFormRadio, BFormRadioGroup, BFormInput, BFormTextarea, FontAwesomeIcon},
    props: {
        modelValue: {
            type: [String, Number],
        },
        cols: String,
        lg: String,
        md: String,
        sm: String,
        xs: String,
        label: String,
        max: Number,
        maxlength: Number,
        minlength: Number,
        disabled: Boolean,
        sensitive: {
            type: Boolean,
            default: false
        },
        placeholder: String,
        moreInfo: String,
        fontSize: {
            type: Number,
            default: 10,
        },
        textArea: Boolean,
        readonly: Boolean,
        rows: [String, Number],
        maxRows: [String, Number],
        radioLabel: String,
        needsValue: Boolean,
        onlyUppercase: {
          type: Boolean,
          default: false
        },
        fillContainer: {
          type: Boolean,
          default: false
        }
    },
    setup(props, context) {

      /**
       * Returns false if no error, true if violating requirement to have a nonzero, non-empty string value
       */
      function checkNonzeroError() {
        if(props.needsValue && !props.disabled && (props.modelValue === 0 || (typeof props.modelValue === 'string' && props.modelValue.length === 0))) {
          return true;
        } else {
          return false;
        }
      }

      /**
       * Returns false if no error, true if violating minimum length requirement
       */
      function checkMinLengthError() {
        if (props.minlength) {
          return typeof (props.modelValue) == 'string' ? props.modelValue.trim().length < props.minlength : false;
        }
        return false;
      }

      const hasErrors = computed(() => {
        if(checkNonzeroError() || checkMinLengthError()) {
          return true;
        } else {
          return false;
        }
      })

      const errorMessage = computed(() => {
        let e = ""
        if(checkNonzeroError())
          e = e.concat("Must have nonzero value");
        if(checkMinLengthError())
          e = e.concat(`SAP requires a minimum of ${props.minlength} characters in this field.`)
        return e;
      })

      onBeforeMount(() => {
          handleChange(props.modelValue)
      })

      const options = [
          {
              text: "AND",
              value: "and"
          },
          {
              text: "OR",
              value: "or"
          }
      ]

      const and = "and";


      function handleChange(e: any) {
          if(props.onlyUppercase && e) {
            e = (e as string).toUpperCase();
          }
          context.emit('update:modelValue', e); // vue 3 v-model update
          context.emit('input', e) // updates v-model
          context.emit('change', e); // triggers optional function in parent
      }

      function handleRadioChange(e: any) {
          context.emit('radioChange', e); // triggers optional function in parent
      }

      return {
          hasErrors,
          errorMessage,
          handleChange,
          handleRadioChange,
          options,
          and,
      }

    }
})

