import ProposalSearchFilter from "../components/filters/ProposalSearchFilter";
import SearchFilterBase from "@/components/filters/SearchFilterBase";

export type SharedPermission = {
    userID: number;
    groupID: number;
    canEdit: boolean;
}

export default class SaveableSearch {
    public searchID: number = 0; // ID of 0 should trigger identity insert.
    public searchName!: string; // For saving new searches, should prompt user for this and set it. Should trigger failure if not possible.
    public ownerID!: number; // For saving new searches, should be set by logged in user. Should trigger failure if not possible.
    public editable: boolean = false; // Whether this user can edit. Only relevant when this user is not owner
    public tableName!: string; // For saving new searches, should set by active table. Should trigger failure if not possible.
    public searchParameters!: SearchFilterBase;
    public sharedWith: Array<SharedPermission> = []; // Permissions for other users being able to edit. Should only need to deal with this field if this user is owner.


    constructor(init?: Partial<SaveableSearch>) {
        if(init) Object.assign(this, init);
    }

    public toDTO() {
        return new SaveableSearchDTO(this);
    }

}

export class SaveableSearchDTO {
    public searchID!: number;
    public searchName!: string;
    public ownerID!: number;
    public editable!: boolean;
    public tableName!: string;
    public searchParameters!: string;
    public sharedWith!: Array<SharedPermission>;

    constructor(init: Partial<SaveableSearch>) {
        if(init.searchID) this.searchID = init.searchID;
        if(init.searchName) this.searchName = init.searchName;
        if(init.ownerID) this.ownerID = init.ownerID;
        if(init.editable) this.editable = init.editable;
        if(init.tableName) this.tableName = init.tableName;
        if(init.searchParameters) this.searchParameters = JSON.stringify(init.searchParameters);
        if(init.sharedWith) this.sharedWith = init.sharedWith;
    }
}