let BASE_API_URL = 'http://localhost:8090/api';
let USER_SERVICE_TOKEN_URL = 'https://userservice.orbiscorporation.com/userservice/oauth/token';
let USER_SERVICE_URL = 'https://userservice.orbiscorporation.com/userservice/api/user';
let OKTA_SERVICE_URL = 'https://orbiscorporation.oktapreview.com/api/v1/authn';
let OKTA_CLIENT_ID = '0oabf8qqqpwkOf2Yy1d7'; //add prod client id when ready
let ISSUER = 'https://orbiscorporation.oktapreview.com/oauth2/default';
const ORBIS_LOGO_URI = 'ORBIS_Powered_By_MC_4C.png';
let MOMENTUM_LOGO_URI = 'Final_Momentum_Logo_0318215.png';
let DRIVEWORKS_PROPOSAL_URL = 'https://momentum-pdm.orbiscorporation.com/integration?run=Opportunity&';
const WILDCARD_SEARCH_RULES = 'wildcard_search_rules.png';

let BASE_URL = 'momentum';

if (process.env.NODE_ENV === 'stage') {
    BASE_API_URL = '/momentum/api';
    USER_SERVICE_TOKEN_URL = 'https://userservice-stg.orbiscorporation.com/userservice/oauth/token';
    USER_SERVICE_URL = 'https://userservice-stg.orbiscorporation.com/userservice/api/user';
    OKTA_SERVICE_URL = 'https://orbiscorporation.oktapreview.com/api/v1/authn';
    OKTA_CLIENT_ID = '0oabf06vngiVl34w91d7';
}

if (process.env.NODE_ENV === 'uat') {
    BASE_API_URL = '/momentum-uat/api';
    USER_SERVICE_TOKEN_URL = 'https://userservice-stg.orbiscorporation.com/userservice/oauth/token';
    USER_SERVICE_URL = 'https://userservice-stg.orbiscorporation.com/userservice/api/user';
    DRIVEWORKS_PROPOSAL_URL = 'https://momentum-dwuat.orbiscorporation.com/integration?run=Opportunity&';
    OKTA_SERVICE_URL = 'https://orbiscorporation.oktapreview.com/api/v1/authn';
    OKTA_CLIENT_ID = '0oabf06vngiVl34w91d7';
    BASE_URL = 'momentum-uat';
}

if (process.env.NODE_ENV === 'production') {
    BASE_API_URL = '/momentum/api';
    MOMENTUM_LOGO_URI = 'Final_Momentum_Logo_0318212.png';
    DRIVEWORKS_PROPOSAL_URL = 'https://momentum-DW.orbiscorporation.com/integration?run=Opportunity&';
    OKTA_SERVICE_URL = 'https://orbiscorporation.okta.com/api/v1/authn';
    ISSUER = 'https://orbiscorporation.oktapreview.com/oauth2/default';

}

export { BASE_API_URL, USER_SERVICE_URL, USER_SERVICE_TOKEN_URL, ORBIS_LOGO_URI, MOMENTUM_LOGO_URI, DRIVEWORKS_PROPOSAL_URL, WILDCARD_SEARCH_RULES, OKTA_CLIENT_ID, OKTA_SERVICE_URL, ISSUER, BASE_URL }