import ProfileStore from "@/store/ProfileStore";
import rootStore from "@/store/RootStore";
import UserApiService from "@/service/api/UserApiService";
import SaveableUITablePreferences, {SaveableUITablePreferencesDTO} from "@/dto/SaveableUITablePreferencesDTO";

export default class UserService {
    private profileStore: ProfileStore;
    private userApiService: UserApiService;

    constructor() {
        this.profileStore = rootStore.getInstance().profileStore;
        this.userApiService = new UserApiService();
    }

    public async getUserOrderableTableSetups(): Promise<boolean> {
        const response = await this.userApiService.getUserOrderableTableSetups(this.profileStore.userID);
        if (response) {
            await this.updateProfileStore(response.items);
        }
        return response.success;
    }

    public async saveUserTableColumnOrder(tableColumnOrder: SaveableUITablePreferences): Promise<boolean> {
        const response = await this.userApiService.saveUserTableColumnSetup(tableColumnOrder.toDTO());
        if (response.success) {
            await this.updateProfileStore(response.items);
        }
        return response.success;
    }

    public async deleteTableColumnOrder(tablePreferences: SaveableUITablePreferences): Promise<boolean> {
        let index = this.profileStore.ownTblColumnSetups.indexOf(tablePreferences);
        const response = await this.userApiService.deleteUserTableColumnOrder(tablePreferences.toDTO());
        this.profileStore.ownTblColumnSetups.splice(index, 1);
        return response.success;
    }

    private async updateProfileStore(items: SaveableUITablePreferencesDTO[]) {
        const ownTblColumnSetups: SaveableUITablePreferences[] = [];
        if (items) {
            for (const item of items) {
                try {
                    let columns = item.columns.replaceAll("'","").split(",");
                    if (columns) {
                        let uiTablePreference = new SaveableUITablePreferences({
                            tablePreferenceId: item.tablePreferenceId,
                            userId: item.userId,
                            tablePreferenceName: item.tablePreferenceName,
                            tableName: item.tableName,
                            columns: columns,
                            active: item.active
                        });
                        ownTblColumnSetups.push(uiTablePreference);
                    }
                } catch (e: any) {
                    console.log(e);
                }
            }
            this.profileStore.ownTblColumnSetups = ownTblColumnSetups;
        }
    }
}