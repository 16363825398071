// import {Route} from 'vue-router';
import rootStore from "@/store/RootStore";

export default class RouterGuards {

    public async ifAuthenticated(to: any, from: any, next: any) {
        if (rootStore.getInstance().profileStore.loggedIn) {
            next();
        } else {
            next({name: 'LoginScreen'});
        }
    }

    public async ifAuthorized(to: any, from: any, next: any) {
        if (rootStore.getInstance().profileStore.loggedIn) {
            if (rootStore.getInstance().profileStore.groups.find(g => g.userGroupName.toLowerCase() === 'workflow administrators')) {
                next();
            } else {
                //user has no access, re-route back to search screen
                next({name: 'AdvancedSearch'});
            }
        } else {
            next({name: 'LoginScreen'});
        }
    }

    public async ifAdmin(to: any, from: any, next: any) {
        if(rootStore.getInstance().profileStore.loggedIn) {
            if(rootStore.getInstance().profileStore.groups.find(g => g.userGroupName.toLowerCase().includes('momentumadmin'))) {
                next();
            } else {
                //re-route user back to search screen
                next({name: 'AdvancedSearch'});
            }
        } else {
            //user session stale/not logged in, must re-auth
           // await AuthStore.getInstance().initUserProfile();
            next({name: 'AdvancedSearch'});
        }
    }

    public async ifDesignLead(to: any, from: any, next: any) {
        if(rootStore.getInstance().profileStore.loggedIn) {
            if(rootStore.getInstance().profileStore.groups.find(g =>
                g.userGroupName.toLowerCase().includes('momentumadmin') ||
                g.userGroupName.toLowerCase().includes('design lead'))) {
                next();
            } else {
                //user has no access, re-route back to search screen
                next({name: 'AdvancedSearch'});
            }
        } else {
          //  await AuthStore.getInstance().initUserProfile();
            next({name: 'AdvancedSearch'});
        }
    }

    public async ifDesigner(to: any, from: any, next: any) {
        if(rootStore.getInstance().profileStore.loggedIn) {
            if(rootStore.getInstance().profileStore.groups.find(g =>
                g.userGroupName.toLowerCase().includes('designers'))) {
                next();
            } else {
                //user has no access, re-route back to search screen
                next({name: 'AdvancedSearch'});
            }
        } else {
            //  await AuthStore.getInstance().initUserProfile();
            next({name: 'AdvancedSearch'});
        }
    }

    public ifNotAuthenticated(to: any, from: any, next: any) {
        if (!rootStore.getInstance().profileStore.loggedIn) {
            next();
        } else {
            next('/');
        }
    }
}
