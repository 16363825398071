export default class DesignData {
    public proposalID!: number;
    public estQA!: string;
    public qa!: string;
    public sampleQA!: string;
    public attentionRequiredBy!: string;
    public attentionRequired!: string;
    public attentionReason!: string;
    public attentionID!: string;
    public qaDesQuest1!: string;
    public qaDesQuest2!: string;
    public qaDesQuest3!: string;
    public qaDesQuest4!: string;
    public qaDesQuest5!: string;
    public qaDesQuest6!: string;
    public qaDesQuest7!: string;
    public qaEstQuest1!: string;
    public qaEstQuest2!: string;
    public qaEstQuest3!: string;
    public qaEstQuest4!: string;
    public qaEstQuest5!: string;
    public qaEstQuest6!: string;
    public qaEstQuest7!: string;
    public qaEstQuest8!: string;
    public qaSampleQuest1!: string;
    public qaSampleQuest2!: string;
    public qaSampleQuest3!: string;
    public qaSampleQuest4!: string;
    public qaStatus!: string;
    public checkDocReq!: boolean;
    public checkDocChangesComplete!: boolean;
    public projectRequirementKickback!: boolean;
    public checkDocChangesNeeded!: boolean;
    public estimationKickback!: boolean;

    constructor(init?: Partial<DesignData>) {
        Object.assign(this, init);
    }

}