import ComponentBomItem from "@/domain/proposal/ComponentBomItem";
import ComponentRouteItem from "@/domain/proposal/ComponentRouteItem";
import ComponentToolingItem from "@/domain/proposal/ComponentToolingItem";
import editable from "@/domain/decorators/editable";

export default class ComponentBom extends editable {
    componentBomID = 0;
    componentType!: string;
    componentNumber!: string;
    description!: string;
    partWidth!: number;
    partLength!: number;
    partThickness!: number;
    blankWidth!: number;
    blankLength!: number;
    blankThickness!: number;
    numberOfStacks!: number;
    inchesOfRule!: number;
    edgeProtection!: number;
    hs!: string;
    fs!: string;
    ep!: string;
    nestValue!: number;
    numberOfDrills!: number;
    numberOfHits!: number;
    numberOfHeads!: number;
    numberOfHwb!: number;
    dieNumber!: string;
    dieDescription!: string;
    stitchTrimSlotCount!: number;
    productBomID!: number;
    lineNumber!: number; //From productBom
    quantity!: number; //From productBom
    line10BomItem?: ComponentBomItem; // Deprecated
    text!: string;
    laminationSides!: number;

    totalBomCost1!: number;
    totalBomCost2!: number;
    totalBomCost3!: number;
    totalBomCost4!: number;
    totalBomCost5!: number;
    totalRouteCost1!: number;
    totalRouteCost2!: number;
    totalRouteCost3!: number;
    totalRouteCost4!: number;
    totalRouteCost5!: number;
    componentsPerDie!: number;
    tSlotter = false; // required field in db, default to false

    //CMP_BOM_Items table
    componentBomItems: Array<ComponentBomItem> = [];

    //CMP_Route_Items table
    componentRouteItems: Array<ComponentRouteItem> = [];

    //CMP_TOOLING_Items table
    componentToolingItems: Array<ComponentToolingItem> = [];



    constructor(init?: Partial<ComponentBom>) {
        super();
        Object.assign(this, init)
        if(init?.componentBomItems && init.componentBomItems.length > 0) this.componentBomItems = ComponentBom.initComponentBomItems(init.componentBomItems);
        if(init?.componentRouteItems && init.componentRouteItems.length > 0) this.componentRouteItems = ComponentBom.initComponentRouteItems(init.componentRouteItems);
        if(init?.componentToolingItems && init.componentToolingItems.length > 0) this.componentToolingItems = ComponentBom.initComponentToolingItems(init.componentToolingItems);
    }

    private static initComponentBomItems(bomItems: ComponentBomItem[]): Array<ComponentBomItem> {
        const array: ComponentBomItem[] = [];
        for(const bomItem of bomItems) {
            array.push(new ComponentBomItem(bomItem));
        }
        return array;
    }

    private static initComponentRouteItems(routeItems: ComponentRouteItem[]): Array<ComponentRouteItem> {
        const array: ComponentRouteItem[] = [];
        for(const routeItem of routeItems) {
            array.push(new ComponentRouteItem(routeItem));
        }
        return array;
    }

    private static initComponentToolingItems(toolingItems: ComponentToolingItem[]): Array<ComponentToolingItem> {
        const array: ComponentToolingItem[] = [];
        for(const toolingItem of toolingItems) {
            array.push(new ComponentToolingItem(toolingItem));
        }
        return array;
    }
}
