
import {computed, defineComponent, PropType, reactive, watch} from "vue";
import {ColumnDefinition} from "@/types";
import useStringFormatter from "@/composable/useStringFormatter";
import {isEqual} from 'lodash';
import clone from "lodash.clone";

type State = {
  mouseUp: boolean;
  element: Element | undefined;
  nextElement: Element | undefined;
}
export default defineComponent ({
  name: "form-orderable",
  props: {
    modelValue: {
      type: Array as PropType<Array<ColumnDefinition<any>>>,
      default: () => []
    },
    dynamicColumns: {
      type: Array as PropType<Array<string>>,
      default: () => []
    }
  },
  mounted() {
    window.addEventListener('dragstart', () => { this.setMouseState(false); });
    window.addEventListener('dragend', () => { this.setMouseState(true); });

    const sortableList = document.getElementById("sortable-list");
    const items = sortableList?.querySelectorAll(".item");
      items?.forEach(item => {
        item.addEventListener("dragstart", () => {
              // Adding dragging class to item after a delay
              setTimeout(() => item.classList.add("dragging"), 0)
          });
        // Removing dragging class from item on dragend event
        item.addEventListener("dragend", () => item.classList.remove("dragging"));
        });
    sortableList?.addEventListener("dragover", this.initSortableList);
    sortableList?.addEventListener("dragenter", e => e.preventDefault());
  },
  methods: {
    initSortableList(e: any) {
      e.preventDefault();
      const sortableList = document.querySelector(".sortable-list");
      const draggingItem = document.querySelector(".dragging");
      if (sortableList) {
        // Getting all items except currently dragging and making array of them
        let siblings = [...sortableList.querySelectorAll(".item:not(.dragging)")];
        if (siblings) {
          // Finding the sibling after which the dragging item should be placed
          let nextSibling = siblings.find(sibling => {
            let siblingBoundaryY = (sibling as HTMLElement).getBoundingClientRect().y;
            let siblingElY =  siblingBoundaryY;
            return e.clientY <= siblingElY;
          });

          if (draggingItem) {
            //console.log(nextSibling?.id + " " + ((nextSibling as HTMLElement).getBoundingClientRect().y + " " + (nextSibling as HTMLElement).offsetTop) + " mouse: " + e.clientY);
            this.updateElementState(draggingItem, nextSibling);
          }
        }
      }
    }
  },
  emits: ['columnChange'],
  setup(props, context) {
    const { getLabelFromFormDefinition } = useStringFormatter();

    const state = reactive<State>({
      mouseUp: true,
      element: undefined,
      nextElement: undefined
    })

    const columns = computed(() => {
      let orderedColumnDefs = [];
      for (const col of props.dynamicColumns) {
        let colDef = props.modelValue.find(c => c.key === col);
        if (colDef) {
          orderedColumnDefs.push(colDef);
        }
      }
      for (const colDef of props.modelValue) {
        if (props.dynamicColumns.includes(colDef.key.toString())) {
          //already added
        } else {
          orderedColumnDefs.push(colDef);
        }
      }
      return orderedColumnDefs;
    })

    watch(state, () => {
      if (state.element && state.mouseUp) {
        updateOrder(state.element, state.nextElement);
      }
    })

    function setMouseState(val: boolean) {
      state.mouseUp = val;
    }

    function updateElementState(el: Element | undefined, elSibling: Element | undefined) {
      state.element = el;
      state.nextElement = elSibling;
    }

    function updateOrder(el: Element, elSibling: Element | undefined) {
      let col = props.modelValue?.find(c => c.key === el.id);
      if (col) {
        let colIndex = props.modelValue?.indexOf(col);
        let tmpCol = clone(col);
        props.modelValue?.splice(colIndex, 1);
        if (elSibling) {
          let nextCol = props.modelValue?.find(c => c.key === elSibling?.id);
          if (nextCol) {
            let nextColIndex = props.modelValue?.indexOf(nextCol);
            props.modelValue?.splice(nextColIndex, 0, tmpCol);
          }
        } else {
          props.modelValue?.push(tmpCol);
        }
      }
      context.emit('columnChange', props.modelValue.map(c => c.key));
    }

    function toggleColumn(value: boolean, key: string) {
      let col = props.modelValue.find(x => isEqual(key,x.key));
      if (col) {
        let colIndex = props.modelValue?.indexOf(col);
        if (value && !props.dynamicColumns.find((x) => isEqual(key, x))) {
          props.dynamicColumns.splice(colIndex, 0, key);
        }
        if (!value) {
          const index = props.dynamicColumns.findIndex((k) => isEqual(k, key));
          if (index > -1) props.dynamicColumns.splice(index, 1);
        }
      }
    }

    return {
      columns,
      getLabelFromFormDefinition,
      toggleColumn,
      isEqual,
      setMouseState,
      updateElementState,
      state
    }
  }
})
