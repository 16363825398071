

import {defineComponent} from "vue";
import Login from "@/views/auth/components/Login.vue";

export default defineComponent({
    name: "re-auth",
    components: {Login},
    props: {},
    setup() {

        return {}
    }
})

