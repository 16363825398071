export default class MomentumProduct {
    public id!: number;
    public name!: string;
    public associatedCustomerID!: number; // Only used on front end to allow referencing the Customer object it is nested inside. Undefined if it is not nested in a customer.

    constructor(init?: Partial<MomentumProduct>) {
        Object.assign(this, init);
    }


}