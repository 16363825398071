export default class ProposalComment {
    public commentID = 0;
    public productNumber!: string;
    public comment!: string;
    public opportunity!: string;
    public revision!: number;
    public lastModified!: string; // timestamp
    public modifiedBy!: string; // Joined from momentum users table
    public userId!: number;
    public commentTypeID!: number | undefined;
    public commentTypeName!: string | undefined; // Joined from comment types table
    public proposalID!: string;
    public fileHyperLink!: string; // Deprecated. Appears to be completely unused, not referencing any values in DB.


    constructor(init?: Partial<ProposalComment>) {
        Object.assign(this, init);
        this.fixLocalTimesToUTC();
    }

    /**
     * This function should be run for any data returned from DB.
     * Due to a limitation of JDBI, we cannot include timezone information when retrieving data from DB (without screwing up inserts/updates),
     * so here we correct date-time fields by treating them as UTC instead of LocalTime.
     **/
    public fixLocalTimesToUTC(): void {
        if(this.lastModified) {
            const tmpDate = new Date(this.lastModified);
            tmpDate.setTime(tmpDate.getTime() - tmpDate.getTimezoneOffset() * 60 * 1000);
            this.lastModified = tmpDate.toISOString();
        }
    }

}
