import MomentumProduct from "@/domain/MomentumProduct";

export default class MomentumCustomer {
    public id!: number;
    public name!: string;
    public productTypes!: MomentumProduct[];

    constructor(init?: Partial<MomentumCustomer>) {
        Object.assign(this, init);
        if(init?.productTypes) {
            const prods: Array<MomentumProduct> = [];
            init.productTypes.forEach((productType) => {
                const newProduct = new MomentumProduct(productType);
                newProduct.associatedCustomerID = this.id;
                prods.push(newProduct);
            })
            this.productTypes = prods;
        } else {
            this.productTypes = [];
        }
    }
}