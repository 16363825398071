
import {defineComponent, ref, computed, watch, reactive} from 'vue';
import BFormInput from "@/components/bootstrap-library/BFormInput.vue";
import BFormSelectOption from "@/components/bootstrap-library/BFormSelectOption.vue";
import BButton from "@/components/bootstrap-library/BButton.vue";
import useScreenDetector from "@/composable/useScreenDetector";
import BCol from "@/components/bootstrap-library/BCol.vue";

type State = {
  componentKey: number
  isOpen: boolean
  direction: 'up' | 'down';
}

export default defineComponent({
  name: "combo-box-input",
  props: {
    options: {type: Array, required: true},
    label: String,
    textField: String,
    valueField: String,
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Array as () => Array<string>]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    cols: {
      type: String,
      default: undefined,
    },
    lg: {
      type: String,
      default: undefined,
    },
    md: {
      type: String,
      default: undefined,
    },
    sm: {
      type: String,
      default: undefined,
    },
    xs: {
      type: String,
      default: undefined,
    }
  },
  components: {
    BButton,
    BCol,
    BFormInput,
    BFormSelectOption
  },
  computed: {
    getFlag() {
      return () => {
        if (this.required) {
          if (typeof (this.modelValue) == 'string' ? this.modelValue.trim() : Number.isInteger(this.modelValue)) {
            return false;
          } else {
            return true;
          }
        }
        return false;
      }
    }
  },
  setup(props, context) {
    const state = reactive<State>({
      componentKey: 0,
      isOpen: false,
      direction: "down"
    })

    const { element: comboBoxRef, getPosition } = useScreenDetector();

    const textInput = ref(props.modelValue ? getTextFromValue(props.modelValue) : "");

    // Filters option list for predictive text
    const filteredOptions = computed(function(){
      // options should be an array of string objects, or objects with textField property of type string
      if(props.textField) {
        // Block for options that are object type
        return props.options.filter((elem: any) => {
              if(props.options.filter((elem: any) => {
                return (elem as any)[props.textField as string] == textInput.value;
              }).length === 1) {
                // With an exact match, return all options (so user can choose from dropdown if they wish to change it).
                //TODO: consider ordering options showing exact text match on top, followed by any contains match, followed by all remaining
                return true;
              } else if (textInput.value != "") {
                // With some input, filter only results containing input
                return ((elem as any)[props.textField as string].toLowerCase()).includes(textInput.value.toLowerCase());
              } else {
                return true; // With no input, return full options list
              }
            }
        );
      } else if(Array.isArray(props.options) && props.options.every((entry:any) => {return typeof entry === "string"})) {
        //Block for options that are String type
        return props.options.filter((elem:any) => {
          if(props.options.filter((elem:any) => {
            return (elem as string) == textInput.value;
          }).length === 1) {
            //TODO: consider ordering options showing exact text match on top, followed by any contains match, followed by all remaining
            return true; // If input exactly matches a single option, return full list
          } else if (textInput.value != "") {
            return ((elem as String).toLowerCase()).includes(textInput.value.toLowerCase()); // If input is not empty, return partial matches by 'contains' method
          } else {
            return true; // If no input, return full list
          }
        })
      }
      return props.options; // Default return unfiltered options. Reaching this line indicates options may not be of a supported type or an unexpected condition occurred.
    })

    function toggleList() {
      state.isOpen? hideList() : showList();
    }

    function showList() {
      if(!props.disabled) {
        state.direction = getPosition() === 'top' ? 'down' : 'up';
        state.isOpen = true;
      } else {
        hideList();
      }
    }

    function hideList() {
      state.direction = getPosition() === 'top' ? 'down' : 'up';
      state.isOpen=false;
    }

    function setInput(value: string) {
      textInput.value = value;
      emitChange();
    }

    // Used to init input field. Returns the textfield entry for an option of given valuefield. If textfield and valuefield are not send, return provided value.
    function getTextFromValue(value: string | Array<string>) : string {
      if(props.textField && props.valueField)   {
        for(const option of props.options) {
          if(typeof value == 'string') {
            if(((option as any)[props.valueField]).toLowerCase().trim() == value.toLowerCase().trim()) {
              return (option as any)[props.textField];
            }
          } else if (Array.isArray(value)) {
            if((option as any)[props.valueField] == (value as any)[props.valueField]) {
              return (option as any)[props.textField];
            }
          }
        }
        return "";
      } else
      {
        return (value as string);
      }
    }

    // Validates and returns a value from input. Options list should be strings, or objects with provided text and value fields
    function getCurrentValue(): any {
      if(props.valueField && props.textField) {
        let resultSet : any[] = props.options.filter((elem:any) => {
          return ((elem as any)[props.textField as string] as string).toLowerCase() == textInput.value.toLowerCase();
        });
        if(resultSet.length === 1) {
          return resultSet.pop()[props.valueField as string];
        } else {
          //return undefined;
          return textInput.value; // Disabling validation check if value is not found right now as there is an issue of some fields not initializing properly
        }
      } else {
        let resultSet: string[] = (props.options as string[]).filter((elem) => {
          if (elem) {
            return (elem as string).toLowerCase().trim() == textInput.value.toLowerCase().trim();
          }
        });
        if(resultSet.length === 1) {
          return resultSet.pop();
        } else {
          //return undefined;
          return textInput.value;
        }
      }
    }

    function getCurrentOption():any {
      if(props.textField && props.valueField) {
        let resultSet: any[] = props.options.filter((elem: any) => {
          return (elem as any)[props.textField as string] == textInput.value;
        });
        if(resultSet.length === 1) {
          return resultSet.pop();
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    }

    // emits change to model passed using validator function to ensure it is either in options list or undefined
    function emitChange() {
      if (props.modelValue && Array.isArray(props.modelValue)) {
        context.emit('update:modelValue', getCurrentOption())
        context.emit('change', getCurrentOption());
      } else {
        context.emit('update:modelValue', getCurrentValue());
        context.emit('change', getCurrentValue());
      }
      state.componentKey++;
    }

    function onInput(e: string) {
     textInput.value = e; // Keeps textInput updated on the input call rather than change call, allowing computed options to be updated for filtering results
    }

    return {
      textInput,
      filteredOptions,
      hideList,
      showList,
      toggleList,
      setInput,
      emitChange,
      onInput,
      state,
      comboBoxRef
    }
  }
})
