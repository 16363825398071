
import {computed, defineComponent, onBeforeUnmount, onMounted, reactive, watch} from "vue";
import SideBarMenuItem from "@/views/sidebar/domain/SideBarMenuItem";
import SideBarMenuChild from "@/views/sidebar/domain/SideBarMenuChild";
import router from "@/router";
import ComponentBom from "@/domain/proposal/ComponentBom";
import ProductBom from "@/domain/proposal/ProductBom";
import rootStore from "@/store/RootStore";

type State = {
  componentNumber: null | string;
  accordion: Array<number>;
  isStale: boolean;
}

export default defineComponent({
  name: "sidebar-nav-proposal-pboms",
  components: {},
  props: {
    item: {
      type: Object as () => SideBarMenuChild,
      required: true,
    }
  },
  setup(props, context) {
    const state = reactive<State>({
      componentNumber: null,
      accordion: [],
      isStale: true
    });

    const proposalStore = rootStore.getInstance().proposalStore;
    const profileStore = rootStore.getInstance().profileStore;

    const prodBoms = computed<ProductBom[]>(() => {
      return proposalStore.proposal.product.productBoms.filter((prodBom) => {
        if (prodBom.componentBom) {
          return prodBom;
        }
      })
    })

    const componentBoms = computed<ComponentBom[]>(() => {
      const cItems: ComponentBom[] = [];
      prodBoms.value.sort((a, b) => a.lineNumber > b.lineNumber ? 1 : -1);
      prodBoms.value.forEach((cBom) => {
        cItems.push(cBom.componentBom as ComponentBom)
      })
      return cItems;
    })

    watch(() => [proposalStore.proposal], () => {
      state.accordion = [];
      state.isStale = false;
    });

    function toggleNavItem(route: string, cmpBom: ComponentBom) {
      if (cmpBom && state.componentNumber != cmpBom.componentNumber) {
        toggleAccordion(cmpBom);
      } else {
        profileStore.addCBomView(route);
      }

    }


    function navClick(route: string, componentNumber: string, componentBomID: number): void {
      state.componentNumber = componentNumber;
      if (router.hasRoute(route)) {
        router.replace({name: route, params: {componentNumber, componentBomID}});
      } else {
        router.push({name: route, params: {componentNumber, componentBomID}});
      }
    }


    function toggleAccordion(cmpBom: ComponentBom): void {
      if (state.accordion.includes(cmpBom.componentBomID)) {
        const index = state.accordion.indexOf(cmpBom.componentBomID);
        if (index !== -1) {
          state.accordion.splice(index, 1);
        }
      } else {
        state.accordion.push(cmpBom.componentBomID);
      }

      if (cmpBom) {
        navClick(props.item.routeName, cmpBom.componentNumber, cmpBom.componentBomID);
      }
    }

    return {
      state,
      SideBarMenuItem,
      SideBarMenuChild,
      toggleNavItem,
      navClick,
      toggleAccordion,
      componentBoms,
      profileStore
    }
  }
})
