import DesignData from "@/domain/proposal/DesignData";
import ContainerModel from "@/domain/maintenance/ContainerModel";
import ReasonCode from "@/domain/maintenance/ReasonCode";
import Sample from "@/domain/proposal/Sample";
import Product from "@/domain/proposal/Product";
import CostingRecalcRequest from "@/domain/proposal/CostingRecalcRequest";
import SalesforceProposalData from "@/domain/proposal/SalesforceProposalData";
import CostHistory from "@/domain/proposal/CostHistory";
import StatusLogEntry from "@/domain/proposal/StatusLogEntry";
import TimeZoneTranslator from "@/utility/TimeZoneTranslator";

export default class Proposal {

    public approvedBy!: string;
    public approvedOn!: string; // date time
    public competition!: string;
    public containerID!: string;
    public containerReq!: boolean;
    public containerType!: string;
    public currency!: string;
    public customerOrderRecd!: boolean;
    public customerPartDesc!: string;
    public customerPartNum!: string;
    public customerPartSent!: boolean;
    public customerPartSentOn!: string //date
    public customerPartType!: string;
    public customerProjectNum!: string;
    public dateEntered!: string; // date time
    public dateRequested!: string; // date
    public designData!: DesignData;
    public designOnly!: boolean;
    public designWaitingForVendorQts!: boolean;
    public designerStartDate!: string; // date
    public dunnageAttachment!: number;
    public dunnageDescription!: string;
    public externalText!: string;
    public packDensity!: number;
    public dunnageWeight!: number;
    public plantLocation!: string; //fk
    public priceList!: boolean;
    public productHierarchy!: string;
    public hierarchyType!: string;
    public product!: Product;
    public proposalNumber!: number;
    public projectRequirements!: string;
    public quickQuote!: boolean;
    public recycleCode!: string;
    public referenceInsertSent!: boolean;
    public salesOrganization!: string;
    public sampleRequestedDate!: string; // date
    public sampleRequired!: boolean;
    public sampleRequirements!: string;
    public sampleUrgencyLevel!: string;
    public sample?: Sample;
    public sampleCompleteDate!: string; // date
    public samplePromiseDate!: string; // date
    public mathData!: boolean;
    public validFrom!: string; // date
    public validTo!: string; // date
    public containerModel!: ContainerModel;
    public customerContainerModel!: string;
    public customerDunNum!: string;
    public salesOrderNumber!: string;
    public designReleased!: boolean;
    //public designWtforVendrQts!: boolean;
    public designer!: string;
    public designerDueDate!: string; // date
    public designerReleaseDate!: string; // date time
    public effortCode!: string;
    public estimationComplete!: boolean;
    public estimationReleased!: boolean;
    public estimator!: string;
    public estimatorDueDate!: string; // date
    public hold!: boolean;
    public holdCode!: number;
    public internalComments!: string;
    public projectManager!: string;
    public projectType!: string;
    public proposalCompleteDate!: string; // date time
    public estimationCompleteDate!: string; // date time
    public reasonCode!: number; // fk
    public reasonCodeObj!: ReasonCode;
    public salesEngApproval!: boolean;
    public engApprovalNeeded!: boolean;
    public salesforceProposalData!: SalesforceProposalData;
    public urgencyLevel!: string;
    public outsideProcess!: boolean;
    public productID!: number;
    public quantity1!: number;
    public quantity2!: number;
    public quantity3!: number;
    public quantity4!: number;
    public quantity5!: number;
    public ramOrSAM!: string; // Not currently managed at all by Java code or utilized in MM. Not sure if needed for future use, but treat as deprecated for now.
    public releasetoESTfromDW!: boolean;
    public releasetoProduction!: boolean;
    public sewingTimeFactor!: number;
    public voidFlag!: boolean;
    public costingRecalcRequest!: CostingRecalcRequest;
    public customerAssemblyNumber!: string;
    public customerPartTrackingNumber!: string;
    public customerPartShipInfo!: string;
    public designComplete!: string;
    public sampleShipDate!: string; // timestamp
    public sampleStartDate!: string; // timestamp
    public sampleQuantity!: number;
    public fobPlant!: number;
    public fobDescription!: string;
    public checkDocComplete!: boolean;
    public costHistory!: CostHistory;
    public daysOnHold!: number; // Derived field
    public daysOpen!: number; // Derived field
    public targetPrice!: number | undefined;
    public targetMargin!: number | undefined;
    public subProposalFlag!: boolean;
    public majorOpportunity!: boolean;
    public attachmentList!: Array<string>;
    public designMajorOpComplete!: boolean;
    public estimationMajorOpComplete!: boolean;
    public iprMajorOpComplete!: boolean;
    public iprApprovedPrice!: number | undefined;
    public iprApprovedMargin!: number | undefined;
    public statusLogEntries!: Array<StatusLogEntry>;
    public receivedInDesign !: string;
    public currentUser!: string; // Used for a couple stored procedure calls, but not actually part of proposal. Should see if we can seperate this from the domain object
    //for search
    public proposalStatus!: string; // Deprecated. It is the qaStatus of designData and we should use that value instead.
    public customerName!: string; // Deprecated. It is the customerName from salesforce_proposal_data and we should use that value instead
    public requestedDate!: string; // Deprecated. Same value as dateRequested, we don't need two of these.
    public fullProductNumber!: string; // Deprecated. Same value as productNumber from the product. We should be referencing that instead.
    public priceListCostUpdate!: boolean;
    public createSFQQuote!: boolean;
    public designKickback!: boolean;

    //Sensitive Data Flag
    public sensitive!: boolean; // Property of salesforce proposal data in DB. We should consider moving this to the corresponding domain object and use a getter here if needed

    //auto build selection
    public priceHoldQuantity!: number | undefined;

    public priceListQty!: number;

    constructor(init?: Partial<Proposal>) {
        Object.assign(this, init);
        this.containerModel = new ContainerModel(init?.containerModel);
        this.designData = new DesignData(init?.designData);
        this.reasonCodeObj = new ReasonCode(init?.reasonCodeObj);
        this.product = new Product(init?.product);
        this.salesforceProposalData = new SalesforceProposalData(init?.salesforceProposalData);
        this.costingRecalcRequest = new CostingRecalcRequest(init?.costingRecalcRequest);
        this.costHistory = new CostHistory(init?.costHistory);
        if (init?.sample?.sampleID === 0) {
            // if sample is empty, delete it
            // we dont want to create a new one in db unless the user manually adds one later in the proposals lifecycle
            delete this.sample
        } else {
            this.sample = new Sample(init?.sample)
        }
        if(init?.statusLogEntries && init.statusLogEntries.length > 0) this.statusLogEntries = Proposal.initStatusLogs(init.statusLogEntries);
        this.fixLocalTimesToUTC();
    }

    public addSample() {
        if (!this.sample) {
            this.sample = new Sample({sampleID: 0});
        } else {
            throw new Error('Sample already exists, only one sample per proposal is allowed')
        }
    }

    /**
     * This function should be run on any proposal returned from DB.
     * Due to a limitation of JDBI, we cannot include timezone information when retrieving data from DB (without screwing up inserts/updates),
     * so here we correct all date-time fields by treating them as UTC instead of LocalTime.
    **/
    public fixLocalTimesToUTC() {
        this.approvedOn = TimeZoneTranslator.toClientTime(this.approvedOn);
        this.dateEntered = TimeZoneTranslator.toClientTime(this.dateEntered);
        this.designerReleaseDate = TimeZoneTranslator.toClientTime(this.designerReleaseDate);
        this.proposalCompleteDate = TimeZoneTranslator.toClientTime(this.proposalCompleteDate);
        this.customerPartSentOn = TimeZoneTranslator.toClientTime(this.customerPartSentOn);
        this.estimationCompleteDate = TimeZoneTranslator.toClientTime(this.estimationCompleteDate);
    }

    private static initStatusLogs(statusLogs: StatusLogEntry[]): Array<StatusLogEntry> {
        const array: StatusLogEntry[] = [];
        for(const statusLog of statusLogs) {
            array.push(new StatusLogEntry(statusLog));
        }
        return array;
    }

    // getters and setters for nested properties
    get qaStatus(): string {
        return this.designData.qaStatus;
    }

    get attentionRequiredBy(): string {
        return this.designData.attentionRequiredBy
    }

    get attentionReason(): string {
        return this.designData.attentionReason;
    }

    get checkDocChangesComplete(): boolean {
        return this.designData.checkDocChangesComplete;
    }

    get checkDocReq(): boolean {
        return this.designData.checkDocReq;
    }

    get qaDesQuest1(): string {
        return this.designData.qaDesQuest1;
    }

    get qaDesQuest2(): string {
        return this.designData.qaDesQuest2;
    }

    get qaDesQuest3(): string {
        return this.designData.qaDesQuest3;
    }

    get qaDesQuest4(): string {
        return this.designData.qaDesQuest4;
    }

    get qaDesQuest5(): string {
        return this.designData.qaDesQuest5;
    }

    get designerCheckComments(): string {
        return this.designData.qa;
    }

    get qaEstQuest1(): string {
        return this.designData.qaEstQuest1;
    }

    get qaEstQuest2(): string {
        return this.designData.qaEstQuest2;
    }

    get qaEstQuest3(): string {
        return this.designData.qaEstQuest3;
    }

    get qaEstQuest4(): string {
        return this.designData.qaEstQuest4;
    }

    get qaEstQuest5(): string {
        return this.designData.qaEstQuest5;
    }

    get qaEstQuest6(): string {
        return this.designData.qaEstQuest6;
    }

    get qaEstQuest7(): string {
        return this.designData.qaEstQuest7;
    }

    get qaEstQuest8(): string {
        return this.designData.qaEstQuest8;
    }

    get estimatorCheckComments(): string {
        return this.designData.estQA;
    }

    get qaSampleQuest1(): string {
        return this.designData.qaSampleQuest1;
    }

    get qaSampleQuest2(): string {
        return this.designData.qaSampleQuest2;
    }

    get qaSampleQuest3(): string {
        return this.designData.qaSampleQuest3;
    }

    get qaSampleQuest4(): string {
        return this.designData.qaSampleQuest4;
    }

    get sampleCheckComments(): string {
        return this.designData.sampleQA;
    }

    get productNumber(): string {
        return this.product.productNumber
    }

    get productDescription(): string {
        return this.product.productDescription;
    }

    get productNumberBase(): number {
        return this.product.productNumberBase;
    }

    get autobuild(): string {
        return this.salesforceProposalData.customerMarketingProgram;
    }


    set qaStatus(value: string) {
        this.designData.qaStatus = value;
    }

    set attentionRequiredBy(value: string) {
        this.designData.attentionRequiredBy = value;
    }

    set attentionReason(value: string) {
        this.designData.attentionReason = value;
    }

    set checkDocChangesComplete(value: boolean) {
        this.designData.checkDocChangesComplete = value;
    }

    set checkDocReq(value: boolean) {
        this.designData.checkDocReq = value;
    }

    //receives wfField fieldname and returns value of that field
    public getFieldValueByFieldName(value: any) {
        let parsedValue = value.replaceAll('_', '');
        let response = '';
        for (var i in this) {
            if (typeof this[i] == 'object') {
                if (this.getFieldValueByFieldName(parsedValue)) {
                    return response;
                }
            } else {
                if (this[i] == parsedValue) {
                    response = i;
                    return response;
                }
            }
        }
        return undefined;
    }
}
