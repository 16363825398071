import {reactive, ref} from 'vue'

type Notification = {
    visible: boolean;
    message: string;
    timed: boolean;
}

const notifications = ref<Array<Notification>>([])

export function useNotification() {

    function cleanup() {
        for (let i = 0; notifications.value.length > i; i++) {
            if (notifications.value[i].visible === false) {
                notifications.value.splice(i, 1);
            }
        }
    }

    function showSimple(message: string) {
        cleanup();
        const notification = reactive<Notification>({
            visible: true,
            message,
            timed: true,
        })

        setTimeout(() => {
            notification.visible = false
        }, 5000)

        notifications.value.push(notification);
    }

    return { notifications, showSimple }

}
