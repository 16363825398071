import {store} from "@/store/store-decorators";
import {RootStore} from "@/store/RootStore";
import {reactive} from "vue";
import ProposalAction from "@/domain/proposal/ProposalAction";

export type AttentionReasonStoreState = {
    action: string;
    proposalAction: ProposalAction;
    attentionReasons: Array<ProposalAction>;
}


@store
export default class AttentionReasonStore {
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    get action() {
        return this.state.action;
    }

    set action(actionID: string) {
        this.state.action = actionID;
        this.setProposalAction();
    }

    get proposalAction() {
        return this.state.proposalAction;
    }

    // state
    private state = reactive<AttentionReasonStoreState>({
        action: "-1",
        proposalAction: new ProposalAction(),
        attentionReasons: new Array<ProposalAction>()
    });

    public setProposalAction() {
        let proposalAction = this.state.attentionReasons.find(reason =>
            reason.actionID === parseInt(this.state.action));
        if (proposalAction) {
            this.state.proposalAction = proposalAction;
        } else {
            this.state.proposalAction = new ProposalAction();
        }
    }

    get attentionReasons(): Array<ProposalAction> {
        return this.state.attentionReasons;
    }

    public setAttentionReasons(actions: ProposalAction[]) {
        this.state.attentionReasons = actions;
    }
}