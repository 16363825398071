import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    id: _ctx.id,
    ref: "element",
    class: _normalizeClass(["collapse", _ctx.classes]),
    "data-bs-parent": _ctx.accordion || null,
    "is-nav": _ctx.isNavBoolean
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {
        visible: _ctx.modelValueBoolean,
        close: _ctx.close
      })
    ]),
    _: 3
  }, 8, ["id", "class", "data-bs-parent", "is-nav"]))
}