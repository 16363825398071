import { vModelRadio as _vModelRadio, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id", "disabled", "required", "name", "form", "aria-label", "aria-labelledby", "value", "aria-required"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    _withDirectives(_createElementVNode("input", _mergeProps({ id: _ctx.computedId }, _ctx.$attrs, {
      ref: "input",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
      class: _ctx.inputClasses,
      type: "radio",
      disabled: _ctx.disabledBoolean,
      required: !!_ctx.name && !!_ctx.requiredBoolean,
      name: _ctx.name,
      form: _ctx.form,
      "aria-label": _ctx.ariaLabel,
      "aria-labelledby": _ctx.ariaLabelledBy,
      value: _ctx.value,
      "aria-required": _ctx.name && _ctx.requiredBoolean ? true : undefined,
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFocused = true)),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isFocused = false))
    }), null, 16, _hoisted_1), [
      [_vModelRadio, _ctx.localValue]
    ]),
    (_ctx.$slots.default || !_ctx.plainBoolean)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.computedId,
          class: _normalizeClass([_ctx.labelClasses, {active: _ctx.isChecked, focus: _ctx.isFocused}])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 10, _hoisted_2))
      : _createCommentVNode("", true)
  ], 2))
}