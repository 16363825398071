import ProfileStore from "@/store/ProfileStore";
import rootStore from "@/store/RootStore";
import ApiConfig from "@/service/api/api-config";
import {TOKEN_NAME} from "@/config/properties";
import AuthApiService from "@/service/api/AuthApiService";
import StartupService from "@/service/StartupService";
import BaseService from "@/service/BaseService";
import {GeneralStatusResponse} from "@/service/api/types/ApiResponseTypes";
import router from "@/router";

//depreciated (non-OKTA)
export default class AuthService extends BaseService {
    private authApiService: AuthApiService;
    private startupService: StartupService;
    private profileStore: ProfileStore;
    private apiConfig: ApiConfig;

    constructor() {
        super()
        this.profileStore = rootStore.getInstance().profileStore;
        this.startupService = new StartupService();
        this.apiConfig = ApiConfig.getInstance();
        this.authApiService = new AuthApiService();
    }

    public async authSuccess(token: string): Promise<GeneralStatusResponse> {
        this.apiConfig.setToken(token);
        if (this.rootStore.profileStore.reauthRequired) {
            this.rootStore.profileStore.reauthSuccess();
            return {success: true}
        } else {
            // if initStartupData fails (as of this comment, only if it does not return user group "All Users") then clear token
            const status = await this.startupService.initStartupData();
            if(!status.success) {
                this.apiConfig.setToken("");
            }
            return status;
        }
    }

    public logout() {
        localStorage.removeItem(TOKEN_NAME);
        this.profileStore.clearProfile();
        router.push({name: 'LoginScreen'});
        //AuthStore.getInstance().signOut('logout');
    }
}
