import {reactive} from "vue";
import {store} from "@/store/store-decorators";
import {RootStore} from "@/store/RootStore";
import ReasonCode from "@/domain/maintenance/ReasonCode";
import WorkCenter from "@/domain/maintenance/WorkCenter";
import WCOperation from "@/domain/maintenance/WCOperation";
import AmortCode from "@/domain/maintenance/AmortCode";
import AllMaterial from "@/domain/maintenance/AllMaterial";
import MaterialUseCode from "@/domain/maintenance/MaterialUseCode";
import ProjectTypeCode from "@/domain/maintenance/ProjectTypeCode";
import ContainerModel from "@/domain/maintenance/ContainerModel";
import Plant from "@/domain/maintenance/Plant";
import AirCellRules from "@/domain/maintenance/AirCellRules";
import CustomerPartType from "@/domain/maintenance/CustomerPartType";
import DesignAttention from "@/domain/maintenance/DesignAttention";
import HeatSealingRules from "@/domain/maintenance/HeatSealingRules";
import Hierarchy from "@/domain/maintenance/Hierarchy";
import HoldCode from "@/domain/maintenance/HoldCode";
import MaterialBlank from "@/domain/maintenance/MaterialBlank";
import MFGFactor from "@/domain/maintenance/MFGFactor";
import MFGRate from "@/domain/maintenance/MFGRate";
import ProjectDefaults from "@/domain/maintenance/ProjectDefaults";
import RecycleCode from "@/domain/maintenance/RecycleCode";
import WCMaint from "@/domain/maintenance/WCMaint";
import ProposalSearchFilters from "@/domain/maintenance/ProposalSearchFilters";
import ToolingRate from "@/domain/maintenance/ToolingRate";
import RoutingPath from "@/domain/maintenance/RoutingPath";
import HierarchyType from "@/domain/maintenance/HierarchyType";
import SampleLocation from "@/domain/proposal/SampleLocation";
import SampleCode from "@/domain/maintenance/SampleCode";
import SampleMaker from "@/domain/maintenance/SampleMaker";
import DunnageAttachment from "@/domain/proposal/DunnageAttachment";
import WorkflowGroup from "@/domain/maintenance/workflow/WorkflowGroup";
import WorkflowGroupAction from "@/domain/maintenance/workflow/WorkflowGroupAction";
import WorkflowCondition from "@/domain/maintenance/workflow/WorkflowCondition";
import ActionStatus from "@/domain/maintenance/workflow/ActionStatus";
import WfOperand from "@/domain/maintenance/workflow/WfOperand";
import WfField from "@/domain/maintenance/workflow/WfField";
import WfTable from "@/domain/maintenance/workflow/WfTable";
import WfFieldType from "@/domain/maintenance/workflow/WfFieldType";
import CommentType from "@/domain/proposal/CommentType";
import WorkflowAction from "@/domain/maintenance/workflow/WorkflowAction";
import WfActivityType from "@/domain/maintenance/workflow/WfActivityType";
import ProposalStatus from "@/domain/maintenance/ProposalStatus";
import ControlRuleDTO from "@/dto/ControlRuleDTO";
import WorkflowGARule from "@/domain/maintenance/workflow/WorkflowGARule";
import User from "@/domain/profile/User";
import {SkillGroup} from "@/domain/maintenance/Skill";
import MomentumProduct from "@/domain/MomentumProduct";
import MomentumCustomer from "@/domain/maintenance/MomentumCustomer";
import SampleCarrier from "@/domain/proposal/SampleCarrier";

type State = {
    materials: Array<AllMaterial>;
    containerModels: Array<ContainerModel>;
    airCellRules: Array<AirCellRules>;
    amortCodes: Array<AmortCode>;
    customerPartTypes: Array<CustomerPartType>;
    designAttentions: Array<DesignAttention>;
    heatSealingRules: Array<HeatSealingRules>;
    hierarchies: Array<Hierarchy>;
    holdCodes: Array<HoldCode>;
    materialBlanks: Array<MaterialBlank>;
    materialUseCodes: Array<MaterialUseCode>;
    mfgFactors: Array<MFGFactor>;
    mfgRates: Array<MFGRate>;
    plants: Array<Plant>;
    proposalSearchFilters: Array<ProposalSearchFilters>;
    projectDefaults: Array<ProjectDefaults>;
    projectTypeCodes: Array<ProjectTypeCode>;
    reasons: Array<ReasonCode>;
    recycleCodes: Array<RecycleCode>;
    workCenters: Array<WorkCenter>;
    wcMaints: Array<WCMaint>;
    wcOperations: Array<WCOperation>;
    toolingRates: Array<ToolingRate>;
    routingPaths: Array<RoutingPath>;
    hierarchyTypes: Array<HierarchyType>;
    currencies: Array<string>;
    customerNames: Array<string>;
    sampleAddresses: Array<string>;
    sampleCities: Array<string>;
    sampleStates: Array<string>;
    sampleCountries: Array<string>;
    sampleZips: Array<string>;
    sampleAttentionTos: Array<string>;
    samplePhones: Array<string>;
    projectManagers: Array<User>;
    ramSam: Array<User>;
    crmCsm: Array<User>;
    approvedBy: Array<User>;
    designers: Array<User>;
    estimators: Array<User>;
    sampleLocations: Array<SampleLocation>;
    sampleCodes: Array<SampleCode>;
    sampleMakers: Array<SampleMaker>;
    qaStatuses: Array<{name: string, value: number}>; // Deprecated. Being replaced by ProposalStatuses.
    proposalStatuses: Array<ProposalStatus>;
    productNumbers: Array<string>;
    customerPartDescs: Array<string>;
    dunnageAttachments: Array<DunnageAttachment>;
    workflowGroups: Array<WorkflowGroup>;
    actionStatuses: Array<ActionStatus>;
    workflowGroupActions: Array<WorkflowGroupAction>;
    workflowConditions: Array<WorkflowCondition>;
    workflowActionStatuses: Array<ActionStatus>;
    workflowCondFields: Array<WfField>;
    workflowOperands: Array<WfOperand>;
    workflowTables: Array<WfTable>;
    workflowFieldTypes: Array<WfFieldType>;
    commentTypes: Array<CommentType>;
    workflowActions: Array<WorkflowAction>;
    workflowActivityTypes: Array<WfActivityType>;
    sampleStatuses: Array<string>;
    priceHolds: Array<string>;
    controlRules: Array<ControlRuleDTO>;
    sampleCarriers: Array<SampleCarrier>;
    workflowGARules: Array<WorkflowGARule>;
    activeUsers: Array<User>;
    skillGroups: Array<SkillGroup>;
    momentumProducts: Array<MomentumProduct>;
    momentumCustomers: Array<MomentumCustomer>;
}

@store
export default class MaintenanceStore {
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    // state
    private state = reactive<State>({
        airCellRules: [],
        customerPartTypes: [],
        designAttentions: [],
        heatSealingRules: [],
        hierarchies: [],
        holdCodes: [],
        materialBlanks: [],
        mfgFactors: [],
        mfgRates: [],
        projectDefaults: [],
        recycleCodes: [],
        wcMaints: [],
        reasons: [],
        workCenters: [],
        wcOperations: [],
        amortCodes: [],
        materials: [],
        projectTypeCodes: [],
        materialUseCodes: [],
        containerModels: [],
        plants: [],
        proposalSearchFilters: [],
        toolingRates: [],
        routingPaths: [],
        hierarchyTypes: [],
        currencies: [],
        customerNames: [],
        sampleAddresses: [],
        sampleCities: [],
        sampleStates: [],
        sampleCountries: [],
        sampleZips: [],
        sampleAttentionTos: [],
        samplePhones: [],
        projectManagers: [],
        ramSam: [],
        crmCsm: [],
        approvedBy: [],
        designers: [],
        estimators: [],
        sampleLocations: [],
        sampleCodes: [],
        sampleMakers: [],
        qaStatuses: [],
        proposalStatuses: [],
        productNumbers: [],
        customerPartDescs: [],
        dunnageAttachments: [],
        workflowGroups: [],
        actionStatuses: [],
        workflowGroupActions: [],
        workflowConditions: [],
        workflowActionStatuses: [],
        workflowCondFields: [],
        workflowOperands: [],
        workflowTables: [],
        workflowFieldTypes: [],
        commentTypes: [],
        workflowActions: [],
        workflowActivityTypes: [],
        sampleStatuses: [],
        priceHolds: [],
        controlRules: [],
        sampleCarriers: [],
        workflowGARules: [],
        activeUsers: [],
        skillGroups: [],
        momentumProducts: [],
        momentumCustomers: []
    });

    public getWorkCentersByPlant(plantLocation: number): Array<WorkCenter> {
        // TODO: on the server, proposal.plantLocation and workCenter.plantCode have mismatched types
        return this.state.workCenters.filter(workCenter => workCenter.plantCode === plantLocation.toString());
    }

    public getWCOperationsByPlant(plantLocation: number): Array<WCOperation> {
        return this.state.wcOperations.filter(wc => parseInt(wc.plantCode) === plantLocation);
    }

    public getWCOperationsByWorkCenter(workCenter: string): Array<WCOperation> {
        if (workCenter) {
            const wcOperations = new Array<WCOperation>();
            let wcOps = this.state.wcOperations.filter(wcOp => wcOp.workCenter === workCenter);
            for (const opp of wcOps) {
                if (wcOperations.find(o => o.assemblyOperation === opp.assemblyOperation)) {
                    //already added
                } else {
                    wcOperations.push(opp);
                }
            }
            return wcOperations.sort((a, b) => { return this.compareWcOperations(a,b) });
        }

        return [];
    }

    private compareWcOperations(a: WCOperation, b: WCOperation) {
        return a.assemblyOperation > b.assemblyOperation ? 1 : -1;
    }

    public getMaterialsByPlant(plantCode: number): Array<AllMaterial> {
        if (plantCode !== 0) {
            return this.state.materials.filter(material => material.plant.toString() === plantCode.toString());
        }
        else {
            return this.state.materials;
        }
    }

    get reasons(): Array<ReasonCode> {
        return this.state.reasons;
    }

    set reasons(value: Array<ReasonCode>) {
        this.state.reasons = value;
    }

    set workCenters(value: Array<WorkCenter>) {
        this.state.workCenters = value;
    }

    get workCenters(): Array<WorkCenter> {
        return this.state.workCenters
    }

    set wcOperations(value: Array<WCOperation>) {
        this.state.wcOperations = value;
    }

    get wcOperations(): Array<WCOperation> {
        return this.state.wcOperations;
    }

    get amortCodes(): Array<AmortCode> {
        return this.state.amortCodes;
    }

    set amortCodes(value: Array<AmortCode>) {
        this.state.amortCodes = value;
    }

    get materials(): Array<AllMaterial> {
        return this.state.materials;
    }

    set materials(value: Array<AllMaterial>) {
        this.state.materials = value;
    }

    get projectTypeCodes(): Array<ProjectTypeCode> {
        return this.state.projectTypeCodes;
    }

    set projectTypeCodes(value: Array<ProjectTypeCode>) {
        this.state.projectTypeCodes = value;
    }

    get materialUseCodes(): Array<MaterialUseCode> {
        return this.state.materialUseCodes
    }

    set materialUseCodes(value: Array<MaterialUseCode>) {
        this.state.materialUseCodes = value;
    }

    set containerModels(value: Array<ContainerModel>) {
        this.state.containerModels = value;
    }

    get containerModels(): Array<ContainerModel> {
        return this.state.containerModels;
    }

    set plants(value: Array<Plant>) {
        this.state.plants = value
    }

    get plants(): Array<Plant> {
        return this.state.plants;
    }

    public getSalesOrganizationByPlantLocation(plantLocation: string | number): number {
        const locationCode = typeof plantLocation === 'string' ? parseInt(plantLocation) : plantLocation;
        const plant = this.plants.find((plant) => plant.plantID === locationCode);

        if (plant) {
            return plant.sOrg;
        }

        return 0;
    }

    get proposalSearchFilters(): Array<ProposalSearchFilters> {
        return this.state.proposalSearchFilters;
    }

    set proposalSearchFilters(value: Array<ProposalSearchFilters>) {
        this.state.proposalSearchFilters = value;
    }

    get customerPartTypes(): Array<CustomerPartType> {
        return this.state.customerPartTypes
    }

    set customerPartTypes(value: Array<CustomerPartType>) {
        this.state.customerPartTypes = value;
    }

    get designAttentions(): Array<DesignAttention> {
        return this.state.designAttentions;
    }

    set designAttentions(value: Array<DesignAttention>) {
        this.state.designAttentions = value;
    }

    get heatSealingRules(): Array<HeatSealingRules> {
        return this.state.heatSealingRules;
    }

    set heatSealingRules(value: Array<HeatSealingRules>) {
        this.state.heatSealingRules = value;
    }

    get hierarchies(): Array<Hierarchy> {
        return this.state.hierarchies;
    }

    set hierarchies(value: Array<Hierarchy>) {
        this.state.hierarchies = value;
    }

    get airCellRules(): Array<AirCellRules> {
        return this.state.airCellRules;
    }

    set airCellRules(value: Array<AirCellRules>) {
        this.state.airCellRules = value;
    }

    get holdCodes(): Array<HoldCode> {
        return this.state.holdCodes;
    }

    set holdCodes(value: Array<HoldCode>) {
        this.state.holdCodes = value;
    }

    get materialBlanks(): Array<MaterialBlank> {
        return this.state.materialBlanks;
    }

    set materialBlanks(value: Array<MaterialBlank>) {
        this.state.materialBlanks = value;
    }

    get mfgFactors(): Array<MFGFactor> {
        return this.state.mfgFactors;
    }

    set mfgFactors(value: Array<MFGFactor>) {
        this.state.mfgFactors = value;
    }

    get mfgRates(): Array<MFGRate> {
        return this.state.mfgRates;
    }

    set mfgRates(value: Array<MFGRate>) {
        this.state.mfgRates = value;
    }

    get projectDefaults(): Array<ProjectDefaults> {
        return this.state.projectDefaults;
    }

    set projectDefaults(value: Array<ProjectDefaults>) {
        this.state.projectDefaults = value;
    }

    get recycleCodes(): Array<RecycleCode> {
        return this.state.recycleCodes;
    }

    set recycleCodes(value: Array<RecycleCode>) {
        this.state.recycleCodes = value;
    }

    get wcMaints(): Array<WCMaint> {
        return this.state.wcMaints;
    }

    set wcMaints(value: Array<WCMaint>) {
        this.state.wcMaints = value;
    }

    get toolingRates(): Array<ToolingRate> {
        return this.state.toolingRates;
    }

    set toolingRates(value: Array<ToolingRate>) {
        this.state.toolingRates = value;
    }

    get routingPaths(): Array<RoutingPath> {
        return this.state.routingPaths;
    }

    set routingPaths(value: Array<RoutingPath>) {
        this.state.routingPaths = value;
    }

    public getToolingRatesBySalesOrganization(salesOrganization: number): Array<ToolingRate> {
        if (salesOrganization) {
            return this.state.toolingRates.filter((toolingRate) => toolingRate.salesOrganization === salesOrganization);
        }

        return [];
    }

    public getPlantByPlantLocation(plantLocation: string): Plant {
        const plant = this.state.plants.find(data => String(data.plantID) === String(plantLocation));
        return (plant != undefined) ? plant : new Plant();
    }


    get hierarchyTypes(): Array<HierarchyType> {
        return this.state.hierarchyTypes;
    }

    public getHierarchyDescription(hierarchyNumber: string): string {
       const hierarchyDescription = hierarchyNumber ? this.state.hierarchies.find(
               (h) =>
                    hierarchyNumber.includes(h.number)
                )?.description : "";
        return hierarchyDescription ? hierarchyDescription : "";
    }

    public createHierarchyTypes() {
        let hierarchyType = new HierarchyType();
        hierarchyType.description = "Industrial";
        this.state.hierarchyTypes.push(hierarchyType);

        hierarchyType = new HierarchyType();
        hierarchyType.description = "RSC";
        this.state.hierarchyTypes.push(hierarchyType);
    }

    get currencies(): Array<string> {
        return this.state.currencies;
    }

    set currencies(value: Array<string>) {
        this.state.currencies = value;
    }

    public getContainerTypes(): Array<string> {
        const containerTypes: Array<string> = [];

        for (const containerModel of this.state.containerModels) {
            if (!containerTypes.includes(containerModel.containerDescription)) {
                containerTypes.push(containerModel.containerDescription);
            }
        }

        return containerTypes;
    }

    public getContainerIDs(): Array<string> {
        const containerIDs: Array<string> = [];

        for (const containerModel of this.state.containerModels) {
            containerIDs.push(containerModel.containerID);
        }

        return containerIDs;
    }

    public getContainerIDsByType(type: string): Array<string> {
        const containerIDs: Array<string> = [];

        for (const containerModel of this.state.containerModels) {
            if( containerModel.containerDescription === type) {
                containerIDs.push(containerModel.containerID);
            }
        }

        return containerIDs;
    }

    public getHierarchies(): Array<Hierarchy> {
        return this.state.hierarchies;
    }

    public getHierarchyOptions(): Array<string> {
        const hierarchies: Array<string> = [];

        for (const hierarchy of this.state.hierarchies) {
            hierarchies.push(hierarchy.number + " | " + hierarchy.description);
        }

        return hierarchies;
    }

    get customerNames(): Array<string> {
        return this.state.customerNames;
    }

    set customerNames(value: Array<string>) {
        this.state.customerNames = value;
    }

    get sampleAddresses(): Array<string> {
        return this.state.sampleAddresses;
    }

    set sampleAddresses(value: Array<string>) {
        this.state.sampleAddresses = value;
    }

    get sampleCities(): Array<string> {
        return this.state.sampleCities;
    }

    set sampleCities(value: Array<string>) {
        this.state.sampleCities = value;
    }

    get sampleStates(): Array<string> {
        return this.state.sampleStates;
    }

    set sampleStates(value: Array<string>) {
        this.state.sampleStates = value;
    }

    get sampleCountries(): Array<string> {
        return this.state.sampleCountries;
    }

    set sampleCountries(value: Array<string>) {
        this.state.sampleCountries = value;
    }

    get sampleZips(): Array<string> {
        return this.state.sampleZips;
    }

    set sampleZips(value: Array<string>) {
        this.state.sampleZips = value;
    }

    get sampleAttentionTos(): Array<string> {
        return this.state.sampleAttentionTos;
    }

    set sampleAttentionTos(value: Array<string>) {
        this.state.sampleAttentionTos = value;
    }

    get samplePhones(): Array<string> {
        return this.state.samplePhones;
    }

    set samplePhones(value: Array<string>) {
        this.state.samplePhones = value;
    }

    get projectManagers(): Array<User> {
        return this.state.projectManagers;
    }

    set projectManagers(value: Array<User>) {
        this.state.projectManagers = value;
    }

    get filteredProjectManagers(): Array<User> {
        const activeUserIds = this.state.activeUsers.map(u => u.userID);
        return this.state.projectManagers.filter(pm => activeUserIds.includes(pm.userID));
    }

    get ramSam(): Array<User> {
        return this.state.ramSam;
    }

    set ramSam(value: Array<User>) {
        this.state.ramSam = value;
    }

    get filteredRamSam(): Array<User> {
        const activeUserIds = this.state.activeUsers.map(u => u.userID);
        return this.state.ramSam.filter(usr => activeUserIds.includes(usr.userID));
    }

    get crmCsm(): Array<User> {
        return this.state.crmCsm;
    }

    set crmCsm(value: Array<User>) {
        this.state.crmCsm = value;
    }

    get filteredCrmCsm(): Array<User> {
        const activeUserIds = this.state.activeUsers.map(u => u.userID);
        return this.state.crmCsm.filter(usr => activeUserIds.includes(usr.userID));
    }

    get activeUsers(): Array<User> {
        return this.state.activeUsers;
    }

    set activeUsers(value: Array<User>) {
        this.state.activeUsers = value;
    }

    get approvedBy(): Array<User> {
        return this.state.approvedBy;
    }

    set approvedBy(value: Array<User>) {
        this.state.approvedBy = value;
    }

    get designers(): Array<User> {
        return this.state.designers;
    }

    set designers(value: Array<User>) {
        this.state.designers = value;
    }

    get estimators(): Array<User> {
        return this.state.estimators;
    }

    set estimators(value: Array<User>) {
        this.state.estimators = value;
    }

    get sampleLocations(): Array<SampleLocation> {
        return this.state.sampleLocations;
    }

    set sampleLocations(value: Array<SampleLocation>) {
        this.state.sampleLocations = value;
    }

    get sampleCodes(): Array<SampleCode> {
        return this.state.sampleCodes;
    }

    set sampleCodes(value: Array<SampleCode>) {
        this.state.sampleCodes = value;
    }

    get sampleMakers(): Array<SampleMaker> {
        return this.state.sampleMakers;
    }

    set sampleMakers(value: Array<SampleMaker>) {
        this.state.sampleMakers = value;
    }

    get qaStatuses(): Array<{name: string, value: number}> {
        return this.state.qaStatuses;
    }

    set qaStatuses(value: Array<{name: string, value: number}>) {
        this.state.qaStatuses = value;
    }

    get proposalStatuses(): Array<ProposalStatus> {
        return this.state.proposalStatuses;
    }

    set proposalStatuses(value: Array<ProposalStatus>) {
        this.state.proposalStatuses = value;
    }

    get productNumbers(): Array<string> {
        return this.state.productNumbers;
    }

    set productNumbers(value: Array<string>) {
        this.state.productNumbers = value;
    }

    get customerPartDescs(): Array<string> {
        return this.state.customerPartDescs;
    }

    set customerPartDescs(value: Array<string>) {
        this.state.customerPartDescs = value;
    }

    get dunnageAttachments(): Array<DunnageAttachment> {
        return this.state.dunnageAttachments;
    }

    set dunnageAttachments(value: Array<DunnageAttachment>) {
        this.state.dunnageAttachments = value;
    }

    set workflowGroups(workflowGroups: Array<WorkflowGroup>) {
        this.state.workflowGroups = workflowGroups;
    }

    get workflowGroups(): Array<WorkflowGroup> {
        return this.state.workflowGroups;
    }

    set actionStatuses(actionStatuses: Array<ActionStatus>) {
        this.state.actionStatuses = actionStatuses;
    }

    get actionStatuses(): Array<ActionStatus> {
        return this.state.actionStatuses;
    }

    set workflowGroupActions(workflowGroupActions: Array<WorkflowGroupAction>) {
        this.state.workflowGroupActions = workflowGroupActions;
    }

    get workflowGroupActions(): Array<WorkflowGroupAction> {
        return this.state.workflowGroupActions;
    }

    set workflowConditions(workflowActionCond: Array<WorkflowCondition>) {
        this.state.workflowConditions = workflowActionCond;
    }

    get workflowConditions(): Array<WorkflowCondition> {
        return this.state.workflowConditions;
    }

    set workflowActionStatuses(actionStatuses: Array<ActionStatus>) {
        this.state.actionStatuses = actionStatuses;
    }

    get workflowActionStatuses(): Array<ActionStatus> {
        return this.state.actionStatuses;
    }

    set workflowActions(actions: Array<WorkflowAction>) {
        this.state.workflowActions = actions;
    }

    get workflowActions(): Array<WorkflowAction> {
        return this.state.workflowActions;
    }

    set workflowGARules(workflowGARules: Array<WorkflowGARule>) {
        this.state.workflowGARules = workflowGARules;
    }

    get workflowGARules():Array<WorkflowGARule> {
        return this.state.workflowGARules;
    }

    set workflowCondFields(condFields: Array<WfField>) {
        this.state.workflowCondFields = condFields;
    }

    get workflowCondFields(): Array<WfField> {
        return this.state.workflowCondFields;
    }

    set workflowOperands(operands: Array<WfOperand>) {
        this.state.workflowOperands = operands;
    }

    get workflowOperands(): Array<WfOperand> {
        return this.state.workflowOperands;
    }

    set workflowTables(wfTables: Array<WfTable>) {
        this.state.workflowTables = wfTables;
    }

    get workflowTables(): Array<WfTable> {
        return this.state.workflowTables;
    }

    set workflowFieldTypes(wfFieldTypes: Array<WfFieldType>) {
        this.state.workflowFieldTypes = wfFieldTypes;
    }

    get workflowFieldTypes(): Array<WfFieldType> {
        return this.state.workflowFieldTypes;
    }

    set workflowActivityTypes(activityTypes: Array<WfActivityType>) {
        this.state.workflowActivityTypes = activityTypes;
    }

    get workflowActivityTypes(): Array<WfActivityType> {
        return this.state.workflowActivityTypes;
    }

    get commentTypes() {
        return this.state.commentTypes;
    }

    set commentTypes(value: Array<CommentType>) {
        this.state.commentTypes = value;
    }

    get sampleStatuses() {
        return this.state.sampleStatuses;
    }

    set sampleStatuses(value: Array<string>) {
        this.state.sampleStatuses = value;
    }

    get priceHolds() {
        return this.state.priceHolds;
    }

    set priceHolds(value: Array<string>) {
        this.state.priceHolds = value;
    }

    get controlRules(): Array<ControlRuleDTO> {
        return this.state.controlRules;
    }

    set controlRules(value: Array<ControlRuleDTO>) {
        this.state.controlRules = value;
    }

    get sampleCarriers(): Array<SampleCarrier> {
        return this.state.sampleCarriers;
    }

    set sampleCarriers(value: Array<SampleCarrier>) {
        this.state.sampleCarriers = value;
    }

    get skillGroups(): Array<SkillGroup> {
        return this.state.skillGroups;
    }

    set skillGroups(value: Array<SkillGroup>) {
        this.state.skillGroups = value;
    }

    get momentumProducts() {
        return this.state.momentumProducts;
    }

    set momentumProducts(value: Array<MomentumProduct>) {
        this.state.momentumProducts = value;
    }

    get momentumCustomers() {
        return this.state.momentumCustomers;
    }

    set momentumCustomers(value: Array<MomentumCustomer>) {
        this.state.momentumCustomers = value;
    }

}

