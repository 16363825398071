import {createWebHashHistory, createWebHistory} from 'vue-router'
import {createRouter,} from 'vue-router';
import {routes} from "@/router/routes";
import { navigationGuard } from '@okta/okta-vue';

const router = createRouter({
    //history: createWebHistory(),
    history: createWebHashHistory(),
    routes: routes
});

//router.beforeEach(navigationGuard);

export default router
