import axios, {AxiosResponse} from 'axios';
import {
    BASE_API_URL, USER_SERVICE_TOKEN_URL,
    USER_SERVICE_URL
} from "@/config/env";
import ProfileStore from "@/store/ProfileStore";
import rootStore from "@/store/RootStore";
import {
    GeneralStatusResponse,
    GetGroupsResult,
    GetProfileResult, GetSingleItemResult
} from "@/service/api/types/ApiResponseTypes";
import {APPLICATION_NAME, APPLICATION_SECRET} from "@/config/properties";
import ProfileData from "@/domain/profile/ProfileData";
import AuthService from "@/service/AuthService";

export default class AuthApiService {

    private profileStore: ProfileStore;

    constructor() {
        this.profileStore = rootStore.getInstance().profileStore;
    }
    public async authenticate(username: string, password: string): Promise<GeneralStatusResponse> {
        const formData = new FormData();
        formData.append('grant_type', 'password');
        formData.append('username', username);
        formData.append('password', password);

        const response: AxiosResponse = await axios({
            method: 'post',
            url: USER_SERVICE_TOKEN_URL,
            data: formData,
            auth: {
                username: APPLICATION_NAME,
                password: APPLICATION_SECRET,
            },
        })

        // User profile not returned from user service
        if (!response.data) return {success: false, message: response.data}

        try {
            const authService = new AuthService();

            return await authService.authSuccess(response.data.access_token)

        } catch {
            return {success: false, message: response.data}
        }
    }
    //depreciated
    // Get profile from userservice
    public async getProfile(): Promise<GetProfileResult> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${USER_SERVICE_URL}/profile/${APPLICATION_NAME}`,
        })
        if (!response.data) return { success: false, message: response.data, profile: {firstName: "", lastName: "", username: "", groups: []} }

        return {success: true, profile: { firstName: response.data.firstName, lastName: response.data.lastName, username: response.data.username, groups: response.data.groups} }

    }

    //depreciated
    // Get user's groups from Momentum DB
    public async getGroupsByUser(userName: string): Promise<GetGroupsResult> {
        if (userName) {
            const response: AxiosResponse = await axios( {
                method: 'get',
                url: `${BASE_API_URL}/users/get-groups/${userName}`,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if(!response.data) {
                return {success: false, groups: []};
            }
            return {success: true, groups: response.data};
        }
        return {success: false, groups: []};
    }

}
