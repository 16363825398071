
import {defineComponent, reactive} from 'vue';
import AuthApiService from "@/service/api/AuthApiService";
import rootStore from "@/store/RootStore";
import AuthService from "@/service/AuthService";

//Bootstrap imports
import BForm from "@/components/bootstrap-library/BForm.vue"
import BFormInput from "@/components/bootstrap-library/BFormInput.vue"

type State = {
    username: string;
    password: string;
    errorMsg: string;
    isDisabled: boolean;
}

export default defineComponent({
    components: {BForm, BFormInput},
    props: {
        reauth: {
            type: Boolean,
            default: false,
        }
    },

    setup() {

        const authApiService = new AuthApiService();
        const authService = new AuthService()
        const profileStore = rootStore.getInstance().profileStore;

        const state = reactive<State>({
            username: profileStore.username,
            password: '',
            errorMsg: '',
            isDisabled: false,
        })

        function formIsValid(): boolean {
            state.errorMsg = "";
            if (state.username.length > 0 && state.username.length > 0) {
                return true
            }
            state.errorMsg = 'Fill out all fields';
            return false;

        }

        async function handleSubmit() {
            if (formIsValid()) {
                const response = await authApiService.authenticate(state.username, state.password);
                if (!response.success) {
                  if(response.errMsg) {
                    state.errorMsg = response.errMsg
                  } else {
                    state.errorMsg = "Something went wrong"
                  }
                }
            }
        }



        function logout() {
            authService.logout()
        }

        function handleReset(): void {
            state.errorMsg = "";
            state.username = "";
            state.password = "";
        }

        return {
            state,
            handleReset,
            handleSubmit,
            logout,
        }
    }
})

