

import {defineComponent} from "vue";

export default defineComponent({
  name: 'b-card-title',
  props: {
    text: {type: String},
    tag: {type: String, default: 'h4'}
  }
})

