
// import type {BFormInvalidFeedbackProps} from '../../types/components'
import {computed, defineComponent, ref} from 'vue'

export default defineComponent({
  name: 'b-form-invalid-feedback',
  props: {
    ariaLive: {type: String, required: false},
    forceShow: {type: Boolean, default: false},
    id: {type: String, required: false},
    text: {type: String, required: false},
    role: {type: String, required: false},
    state: {type: Boolean, default: undefined},
    tag: {type: String, default: 'div'},
    tooltip: {type: Boolean, default: false}
  },
  setup(props, context) {
    const forceShowBoolean = ref(props.forceShow);
    const stateBoolean = ref(props.state);
    const tooltipBoolean = ref(props.tooltip);

    const computedShow = computed<boolean>(
        () => forceShowBoolean.value === true || stateBoolean.value === false
    )

    const classes = computed(() => ({
      'd-block': computedShow.value,
      'invalid-feedback': !tooltipBoolean.value,
      'invalid-tooltip': tooltipBoolean.value,
    }))

    const attrs = computed(() => ({
      'id': props.id,
      'role': props.role,
      'aria-live': props.ariaLive,
      'aria-atomic': props.ariaLive ? 'true' : undefined,
    }))

    return {
      classes,
      attrs
    }

  }
})

// interface BFormInvalidFeedbackProps {
//   ariaLive?: string
//   forceShow?: Booleanish
//   id?: string
//   text?: string
//   role?: string
//   state?: Booleanish
//   tag?: string
//   tooltip?: Booleanish
// }

// const props = withDefaults(defineProps<BFormInvalidFeedbackProps>(), {
//   forceShow: false,
//   tag: 'div',
//   state: undefined,
//   tooltip: false,
// })

// const forceShowBoolean = useBooleanish(toRef(props, 'forceShow'))
// const stateBoolean = useBooleanish(toRef(props, 'state') as Ref<Booleanish | undefined>)
// const tooltipBoolean = useBooleanish(toRef(props, 'tooltip'))
//
// const computedShow = computed<boolean>(
//     () => forceShowBoolean.value === true || stateBoolean.value === false
// )
//
// const classes = computed(() => ({
//   'd-block': computedShow.value,
//   'invalid-feedback': !tooltipBoolean.value,
//   'invalid-tooltip': tooltipBoolean.value,
// }))
//
// const attrs = computed(() => ({
//   'id': props.id,
//   'role': props.role,
//   'aria-live': props.ariaLive,
//   'aria-atomic': props.ariaLive ? 'true' : undefined,
// }))
