import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_card_img = _resolveComponent("b-card-img")!
  const _component_b_card_header = _resolveComponent("b-card-header")!
  const _component_b_card_body = _resolveComponent("b-card-body")!
  const _component_b_card_footer = _resolveComponent("b-card-footer")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass(["card", _ctx.classes])
  }, {
    default: _withCtx(() => [
      (_ctx.imgSrc && !_ctx.imgBottomBoolean)
        ? (_openBlock(), _createBlock(_component_b_card_img, _normalizeProps(_mergeProps({ key: 0 }, _ctx.imgAttr)), null, 16))
        : _createCommentVNode("", true),
      (_ctx.header || _ctx.$slots.header || _ctx.headerHtml)
        ? (_openBlock(), _createBlock(_component_b_card_header, _mergeProps({ key: 1 }, _ctx.headerAttrs, { class: _ctx.headerClass }), {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "header", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.header), 1)
              ])
            ]),
            _: 3
          }, 16, ["class"]))
        : _createCommentVNode("", true),
      (!_ctx.noBodyBoolean)
        ? (_openBlock(), _createBlock(_component_b_card_body, _mergeProps({ key: 2 }, _ctx.bodyAttrs, { class: _ctx.bodyClass }), {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.bodyText), 1)
              ])
            ]),
            _: 3
          }, 16, ["class"]))
        : _renderSlot(_ctx.$slots, "default", { key: 3 }, () => [
            _createTextVNode(_toDisplayString(_ctx.bodyText), 1)
          ]),
      (_ctx.footer || _ctx.$slots.footer || _ctx.footerHtml)
        ? (_openBlock(), _createBlock(_component_b_card_footer, _mergeProps({ key: 4 }, _ctx.footerAttrs, { class: _ctx.footerClass }), {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "footer", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.footer), 1)
              ])
            ]),
            _: 3
          }, 16, ["class"]))
        : _createCommentVNode("", true),
      (_ctx.imgSrc && _ctx.imgBottomBoolean)
        ? (_openBlock(), _createBlock(_component_b_card_img, _normalizeProps(_mergeProps({ key: 5 }, _ctx.imgAttr)), null, 16))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["class"]))
}