import {CreateStore} from "./store-decorators"
import {reactive} from "vue";

type SidebarState = {
    sideBarVisible: boolean;
}

type SidebarProps = {
    sideBarWidthDesktop: number;
    sideBarWidthMobile: number;
    mobileDesktopBreak: number;
    keepOpenWidth: number;
}

export class SidebarStore {
    // state
    private state = reactive<SidebarState>({
        sideBarVisible: false
    })
    private props = {
        sideBarWidthDesktop: 255,
        sideBarWidthMobile: 300,
        mobileDesktopBreak: 900,
        // not sure how im going to handle this, but the side bar stays open if the screen width is less than this number
        // in this application i dont think we'll ever want the side bar to dynamically open
        keepOpenWidth: 9999999,
    }

    // getters
    get sideBarVisible(): boolean {
        return this.state.sideBarVisible;
    }
    get sideBarWidthDesktop(): number {
        return this.props.sideBarWidthDesktop
    }
    get sideBarWidthMobile(): number {
        return this.props.sideBarWidthMobile
    }
    get mobileDesktopBreak(): number {
        return this.props.mobileDesktopBreak
    }
    get keepOpenWidth(): number {
        return this.props.keepOpenWidth
    }

    // actions
    set sideBarVisible(value: boolean) {
        this.state.sideBarVisible = value;
    }

    public toggleSidebar(): void {
        this.state.sideBarVisible = !this.state.sideBarVisible;

        if (this.state.sideBarVisible) {
            sessionStorage.setItem('sidebar.sideBarVisible', 'true');
        } else {
            sessionStorage.setItem('sidebar.sideBarVisible', 'false');
        }
    }

    public showSidebarIfDesktop(): void {
        if (window.innerWidth > this.props.keepOpenWidth) {
            this.state.sideBarVisible = true;
        }
    }

}
const sidebarStore = new CreateStore(new SidebarStore());
export default sidebarStore;
