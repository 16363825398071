import axios, {AxiosResponse} from 'axios';
import {
    GetGeneralListSuccessResult, GetGeneralResponseResult, GetGeneralSuccessResult,
    GetSingleItemResult, MaintenanceClassName,
} from "@/service/api/types/ApiResponseTypes";
import {BASE_API_URL} from "@/config/env";
import WorkCenter from "@/domain/maintenance/WorkCenter";
import ReasonCode from "@/domain/maintenance/ReasonCode";
import WCOperation from "@/domain/maintenance/WCOperation";
import AmortCode from "@/domain/maintenance/AmortCode";
import AllMaterial from "@/domain/maintenance/AllMaterial";
import MaterialUseCode from "@/domain/maintenance/MaterialUseCode";
import ProjectTypeCode from "@/domain/maintenance/ProjectTypeCode";
import ContainerModel from "@/domain/maintenance/ContainerModel";
import Plant from "@/domain/maintenance/Plant";
import AirCellRules from "@/domain/maintenance/AirCellRules";
import CustomerPartType from "@/domain/maintenance/CustomerPartType";
import DesignAttention from "@/domain/maintenance/DesignAttention";
import HeatSealingRules from "@/domain/maintenance/HeatSealingRules";
import Hierarchy from "@/domain/maintenance/Hierarchy";
import HoldCode from "@/domain/maintenance/HoldCode";
import MaterialBlank from "@/domain/maintenance/MaterialBlank";
import MFGFactor from "@/domain/maintenance/MFGFactor";
import MFGRate from "@/domain/maintenance/MFGRate";
import ProjectDefaults from "@/domain/maintenance/ProjectDefaults";
import RecycleCode from "@/domain/maintenance/RecycleCode";
import WCMaint from "@/domain/maintenance/WCMaint";
import APIResponseDTO from "@/dto/APIResponseDTO";
import ProposalSearchFilters from "@/domain/maintenance/ProposalSearchFilters";
import ToolingRate from "@/domain/maintenance/ToolingRate";
import RoutingPath from "@/domain/maintenance/RoutingPath";
import SampleLocation from "@/domain/proposal/SampleLocation";
import SampleCode from "@/domain/maintenance/SampleCode";
import SampleMaker from "@/domain/maintenance/SampleMaker";
import DunnageAttachment from "@/domain/proposal/DunnageAttachment";
import WorkflowCondition from "@/domain/maintenance/workflow/WorkflowCondition";
import WorkflowGroupAction from "@/domain/maintenance/workflow/WorkflowGroupAction";
import WorkflowGroup from "@/domain/maintenance/workflow/WorkflowGroup";
import ActionStatus from "@/domain/maintenance/workflow/ActionStatus";
import WfFieldDTO from "@/domain/maintenance/workflow/WfFieldDTO";
import WfField from "@/domain/maintenance/workflow/WfField";
import CommentType from "@/domain/proposal/CommentType";
import WorkflowAction from "@/domain/maintenance/workflow/WorkflowAction";
import WfActivityType from "@/domain/maintenance/workflow/WfActivityType";
import ProposalStatus from "@/domain/maintenance/ProposalStatus";
import ControlRuleDTO from "@/dto/ControlRuleDTO";
import WorkflowGARule from "@/domain/maintenance/workflow/WorkflowGARule";
import WorkflowRuleDTO from "@/dto/WorkflowRuleDTO";
import User from "@/domain/profile/User";
import {SkillGroup} from "@/domain/maintenance/Skill";
import MomentumProduct from "@/domain/MomentumProduct";
import MomentumCustomer from "@/domain/maintenance/MomentumCustomer";
import Location from "@/domain/maintenance/Location";
import UserLocation from "@/domain/maintenance/UserLocation";
import SampleCarrier from "@/domain/proposal/SampleCarrier";


export default class MaintenanceApiService {

    public async getAirCellRules(): Promise<GetGeneralListSuccessResult<AirCellRules>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/air-cell-rules/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<AirCellRules>>(response.data);

        try {
            const items: Array<AirCellRules> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getCustomerPartTypes(): Promise<GetGeneralListSuccessResult<CustomerPartType>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/customer-part-type/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<CustomerPartType>>(response.data);

        try {
            const items: Array<CustomerPartType> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getDesignAttentions(): Promise<GetGeneralListSuccessResult<DesignAttention>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/design-attention/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<DesignAttention>>(response.data);

        try {
            const items: Array<DesignAttention> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    // Deprecated. Being replaced by ProposalStatuses.
    public async getQaStatuses(): Promise<GetGeneralListSuccessResult<string>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/qa-statuses/get-all`,
        })
        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<string>>(response.data);

        try {
            const items: Array<string> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }
    
    public async getProposalStatuses(): Promise<GetGeneralListSuccessResult<ProposalStatus>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/proposal-statuses/get-all`
        })
        if(!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<ProposalStatus>>(response.data);
        const items: Array<ProposalStatus> = apiResponseDTO.payload;
        return {success: true, items};
    }

    public async getProductNumbers(): Promise<GetGeneralListSuccessResult<string>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/product-numbers/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<string>>(response.data);

        try {
            const items: Array<string> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getCustomerPartDescs(): Promise<GetGeneralListSuccessResult<string>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/customer-part-descs/get-all`,
        })


        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<string>>(response.data);

        try {
            const items: Array<string> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getHeatSealingRules(): Promise<GetGeneralListSuccessResult<HeatSealingRules>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/heat-sealing-rules/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<HeatSealingRules>>(response.data);

        try {
            const items: Array<HeatSealingRules> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getHierarchies(): Promise<GetGeneralListSuccessResult<Hierarchy>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/hierarchy/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<Hierarchy>>(response.data);

        try {
            const items: Array<Hierarchy> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getHoldCodes(): Promise<GetGeneralListSuccessResult<HoldCode>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/hold-code/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<HoldCode>>(response.data);

        try {
            const items: Array<HoldCode> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getMaterialBlanks(): Promise<GetGeneralListSuccessResult<MaterialBlank>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/material-blank/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<MaterialBlank>>(response.data);

        try {
            const items: Array<MaterialBlank> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getMFGFactor(): Promise<GetGeneralListSuccessResult<MFGFactor>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/mfg-factor/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<MFGFactor>>(response.data);

        try {
            const items: Array<MFGFactor> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getMFGRates(): Promise<GetGeneralListSuccessResult<MFGRate>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/mfg-rate/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<MFGRate>>(response.data);

        try {
            const items: Array<MFGRate> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getProjectDefaults(): Promise<GetGeneralListSuccessResult<ProjectDefaults>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/project-default/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<ProjectDefaults>>(response.data);

        try {
            const items: Array<ProjectDefaults> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getRecycleCodes(): Promise<GetGeneralListSuccessResult<RecycleCode>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/recycle-code/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<RecycleCode>>(response.data);

        try {
            const items: Array<RecycleCode> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getWCMaints(): Promise<GetGeneralListSuccessResult<WCMaint>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/wc-maint/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<WCMaint>>(response.data);

        try {
            const items: Array<WCMaint> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getReasons(): Promise<GetGeneralListSuccessResult<ReasonCode>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/reason-code/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<ReasonCode>>(response.data);

        try {
            const items: Array<ReasonCode> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getPlants(): Promise<GetGeneralListSuccessResult<Plant>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/plant/get-all`,
        })
        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<Plant>>(response.data);

        try {
            const items: Array<Plant> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getProposalSearchFilters(): Promise<GetGeneralListSuccessResult<ProposalSearchFilters>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/proposal-search-filters/get-all`,
        })
        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<ProposalSearchFilters>>(response.data);

        try {
            const items: Array<ProposalSearchFilters> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getAllContainerModels(): Promise<GetGeneralListSuccessResult<ContainerModel>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/container-models/get-all`,
        })

        if (!response.data || response.data.length === 0) return {success: false, message: response.data, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<ContainerModel>>(response.data);

        try {
            const items: Array<ContainerModel> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getAllWorkCenters(): Promise<GetGeneralListSuccessResult<WorkCenter>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/work-center/get-all`,
        })

        if (!response.data || response.data.length === 0) return {success: false, message: response.data, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<WorkCenter>>(response.data);

        try {
            const items: Array<WorkCenter> = [];
            apiResponseDTO.payload.forEach((wcPartial) => {
                items.push(new WorkCenter(wcPartial)); // Construct a WorkCenter object from each WC JSON returned from DB.
            })
            return {success: true, items}
        } catch(e: any) {
            console.log(e)
            return {success: false, items: []}
        }
    }

    public async getAllWCOperations(): Promise<GetGeneralListSuccessResult<WCOperation>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/wc-operation/get-all`,
        })

        if (!response.data || response.data.length === 0) return {success: false, message: response.data, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<WCOperation>>(response.data);

        try {
            const items: Array<WCOperation> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    async getAllAmortCodes(): Promise<GetGeneralListSuccessResult<AmortCode>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/amort-code/get-all`,
        })

        if (!response.data || response.data.length === 0) return {success: false, message: response.data, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<AmortCode>>(response.data);

        try {
            const items: Array<AmortCode> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    /*pull from search endpoint*/
    async getAllMaterials(): Promise<GetGeneralListSuccessResult<AllMaterial>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/search/material/get-all`,
        })

        if (!response.data || response.data.length === 0) return {success: false, message: response.data, items: []}
        const apiResponseDTO = new APIResponseDTO<Array<AllMaterial>>(response.data);

        try {
            const items: Array<AllMaterial> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    async getMaterialUseCodes(): Promise<GetGeneralListSuccessResult<MaterialUseCode>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/material/use-codes/get`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<MaterialUseCode>>(response.data);

        try {
            const items: Array<MaterialUseCode> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    async getAllProjectTypeCodes(): Promise<GetGeneralListSuccessResult<ProjectTypeCode>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/project-type-code/get-all`,
        })

        if (!response.data || response.data.length === 0) return {success: false, message: response.data, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<ProjectTypeCode>>(response.data);

        try {
            const items: Array<ProjectTypeCode> = [];
            for (const readProjectTypeCode of apiResponseDTO.payload) {
                const projectTypeCode = new ProjectTypeCode(readProjectTypeCode);
                items.push(projectTypeCode);
            }
            return {success: true, items};
        } catch {
            return {success: false, items: []}
        }
    }

    public async getToolingRates(): Promise<GetGeneralListSuccessResult<ToolingRate>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/tooling-rate/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<ToolingRate>>(response.data);

        try {
            const items: Array<ToolingRate> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getRoutingPaths(): Promise<GetGeneralListSuccessResult<RoutingPath>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/routing-path/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<RoutingPath>>(response.data);

        try {
            const items: Array<RoutingPath> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getCurrencies(): Promise<GetGeneralListSuccessResult<string>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/currencies/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<string>>(response.data);

        try {
            const items: Array<string> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getCustomerNames(): Promise<GetGeneralListSuccessResult<string>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/customerNames/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<string>>(response.data);

        try {
            const items: Array<string> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getSampleAddresses(): Promise<GetGeneralListSuccessResult<string>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/sampleAddresses/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<string>>(response.data);

        try {
            const items: Array<string> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getSampleCities(): Promise<GetGeneralListSuccessResult<string>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/sampleCities/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<string>>(response.data);

        try {
            const items: Array<string> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getSampleStates(): Promise<GetGeneralListSuccessResult<string>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/sampleStates/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<string>>(response.data);

        try {
            const items: Array<string> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getSampleCountries(): Promise<GetGeneralListSuccessResult<string>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/sampleCountries/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<string>>(response.data);

        try {
            const items: Array<string> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getSampleZips(): Promise<GetGeneralListSuccessResult<string>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/sampleZips/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<string>>(response.data);

        try {
            const items: Array<string> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getSampleAttentionTos(): Promise<GetGeneralListSuccessResult<string>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/sampleAttentionTos/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<string>>(response.data);

        try {
            const items: Array<string> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getSamplePhones(): Promise<GetGeneralListSuccessResult<string>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/samplePhones/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<string>>(response.data);

        try {
            const items: Array<string> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getProjectManagers(): Promise<GetGeneralListSuccessResult<User>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/projectManagers/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<User>>(response.data);

        try {
            const items: Array<User> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getRamSam(): Promise<GetGeneralListSuccessResult<User>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/ramSam/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<User>>(response.data);

        try {
            const items: Array<User> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getCrmCsm(): Promise<GetGeneralListSuccessResult<User>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/crmCsm/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<User>>(response.data);

        try {
            const items: Array<User> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getActiveUsers(): Promise<GetGeneralListSuccessResult<User>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/users/get-active`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<User>>(response.data);

        try {
            const items: Array<User> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getApprovedBy(): Promise<GetGeneralListSuccessResult<User>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/approvedBy/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<User>>(response.data);

        try {
            const items: Array<User> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getDesigners(): Promise<GetGeneralListSuccessResult<User>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/designers/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<User>>(response.data);

        try {
            const items: Array<User> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async updateDesigner(designer: User): Promise<{success: boolean}> {
        const data = JSON.stringify(designer);
        const response: AxiosResponse = await axios( {
            method: 'post',
            url: `${BASE_API_URL}/maintenance/designers/update`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if(response.status == 200) {
            return {success: true};
        } else {
            return {success: false};
        }
    }

    public async getEstimators(): Promise<GetGeneralListSuccessResult<User>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/estimators/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<User>>(response.data);

        try {
            const items: Array<User> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getSampleLocations(): Promise<GetGeneralListSuccessResult<SampleLocation>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/sampleLocations/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<SampleLocation>>(response.data);

        try {
            const items: Array<SampleLocation> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getSampleCodes(): Promise<GetGeneralListSuccessResult<SampleCode>> {

        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/sampleCodes/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<SampleCode>>(response.data);

        try {
            const items: Array<SampleCode> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getSampleMakers(): Promise<GetGeneralListSuccessResult<SampleMaker>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/sampleMakers/get-all`
        })

        if(!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<SampleMaker>>(response.data);

        try {
            const items: Array<SampleMaker> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getDunnageAttachments(): Promise<GetGeneralListSuccessResult<DunnageAttachment>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/dunnageAttachments/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<DunnageAttachment>>(response.data);

        try {
            const items: Array<DunnageAttachment> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getCommentTypes(): Promise<GetGeneralListSuccessResult<CommentType>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/commentTypes/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<CommentType>>(response.data);

        try {
            const items: Array<CommentType> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getSampleStatuses(): Promise<GetGeneralListSuccessResult<string>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/sampleStatus/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<string>>(response.data);

        try {
            const items: Array<string> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getPriceHoldList(): Promise<GetGeneralListSuccessResult<string>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/priceHolds/get-all`
        });

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<string>>(response.data);

        try {
            const items: Array<string> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    /**
     *
     * @param item - item to insert
     * @param className - classname of the maintenance item. Hardcoded value that must match a value in DomainMaintenanceFactory.java. Case insensitive.
     */
    async insertMaintenanceItem<T>(item: T, className: MaintenanceClassName): Promise<GetSingleItemResult<T>> {
        const data = JSON.stringify(item);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/modify/insert/${className}`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const apiResponseDTO = new APIResponseDTO<T>(response.data);

        if (!response.data) return {success: false, message: response.data}
        if (typeof response.data !== 'object') return { success: false, message: response.data }
        return {success: true, item: apiResponseDTO.payload};
    }

    /**
     *
     * @param item - item to update
     * @param className - classname of the maintenance item. Hardcoded value that must match a value in DomainMaintenanceFactory.java. Case insensitive.
     * @param rowIdentifier - String representation of the primary key value of this row. Composite PK's are comma delineated
     */
    async updateMaintenanceItem<T>(item: T, className: MaintenanceClassName, rowIdentifier: String): Promise<GetSingleItemResult<T>> {
        const data = JSON.stringify(item);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/modify/update/${className}/${rowIdentifier}`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const apiResponseDTO = new APIResponseDTO<T>(response.data);

        if (!response.data) return {success: false, message: response.data}
        if (typeof response.data !== 'object') return { success: false, message: response.data }
        return {success: true, item: apiResponseDTO.payload};
    }

    async deleteMaintenanceItem<T>(item: T, className: MaintenanceClassName): Promise<GetGeneralSuccessResult> {
        const data = JSON.stringify(item);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/modify/delete/${className}`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (!response.data) return {success: false, message: response.data}
        return {success: response.data};

    }

    public async getAllWorkflowGroups(): Promise<GetGeneralListSuccessResult<WorkflowGroup>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/workflows/group/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<WorkflowGroup>>(response.data);

        try {
            const items: Array<WorkflowGroup> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async duplicateGroupAction(actionId: number): Promise<GetGeneralListSuccessResult<WorkflowGroupAction>> {
        const data = JSON.stringify(actionId);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/workflows/group/group-action/copy`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const apiResponseDTO = new APIResponseDTO<Array<WorkflowGroupAction>>(response.data);

        try {
            const items: Array<WorkflowGroupAction> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async deleteGroupAction(action: WorkflowGroupAction): Promise<GetGeneralListSuccessResult<WorkflowGroupAction>> {
        const data = JSON.stringify(action);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/workflows/group/group-action/delete`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const apiResponseDTO = new APIResponseDTO<Array<WorkflowGroupAction>>(response.data);

        try {
            const items: Array<WorkflowGroupAction> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async deleteWorkflowRule(rule: WorkflowGARule): Promise<GetSingleItemResult<WorkflowRuleDTO>> {
        const data = JSON.stringify(rule);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/workflows/group/rule/delete`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (!response.data) return {success: false, item: new WorkflowRuleDTO()}

        const apiResponseDTO = new APIResponseDTO<WorkflowRuleDTO>(response.data);

        try {
            const item: WorkflowRuleDTO = apiResponseDTO.payload;
            return {success: true, item}
        } catch {
            return {success: false, item: new WorkflowRuleDTO()}
        }
    }

    public async deleteWorkflowCondition(condition: WorkflowCondition): Promise<GetGeneralListSuccessResult<WorkflowCondition>> {
        const data = JSON.stringify(condition);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/workflows/group/condition/delete`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const apiResponseDTO = new APIResponseDTO<Array<WorkflowCondition>>(response.data);

        try {
            const items: Array<WorkflowCondition> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async deleteWorkflowAction(workflowAction: WorkflowAction): Promise<GetGeneralListSuccessResult<WorkflowAction>> {
        const data = JSON.stringify(workflowAction);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/workflows/group/action/delete`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const apiResponseDTO = new APIResponseDTO<Array<WorkflowAction>>(response.data);

        try {
            const items: Array<WorkflowAction> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getWorkflowGARulesByActionId(workflowGroupActionId: number): Promise<GetGeneralListSuccessResult<WorkflowGARule>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/workflow/group/rule/` + workflowGroupActionId,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<WorkflowGARule>>(response.data);

        try {
            const items: Array<WorkflowGARule> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getWorkflowGARules(): Promise<GetSingleItemResult<WorkflowRuleDTO>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/workflow/group/rule/get-all`,
        })

        if (!response.data) return {success: false, item: new WorkflowRuleDTO()}

        const apiResponseDTO = new APIResponseDTO<WorkflowRuleDTO>(response.data);

        try {
            const item: WorkflowRuleDTO = apiResponseDTO.payload;
            return {success: true, item}
        } catch {
            return {success: false, item: new WorkflowRuleDTO()}
        }
    }

    public async getAllWorkflowGroupActions(): Promise<GetGeneralListSuccessResult<WorkflowGroupAction>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/workflows/group/group-action/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<WorkflowGroupAction>>(response.data);

        try {
            const items: Array<WorkflowGroupAction> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getWorkflowConditions(workflowGroupRuleId: number): Promise<GetGeneralListSuccessResult<WorkflowCondition>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/workflow/group/condition/` + workflowGroupRuleId,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<WorkflowCondition>>(response.data);

        try {
            const items: Array<WorkflowCondition> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getAllWorkflowConditions(): Promise<GetGeneralListSuccessResult<WorkflowCondition>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/workflow/group/condition/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<WorkflowCondition>>(response.data);

        try {
            const items: Array<WorkflowCondition> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async copyWorkflowCondition(cond: WorkflowCondition): Promise<GetGeneralListSuccessResult<WorkflowCondition>> {
        const data = JSON.stringify(cond);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/workflows/condition/copy`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const apiResponseDTO = new APIResponseDTO<Array<WorkflowCondition>>(response.data);

        try {
            const items: Array<WorkflowCondition> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async copyWorkflowActions(workflowActionId: number): Promise<GetGeneralListSuccessResult<WorkflowAction>> {
        const data = JSON.stringify(workflowActionId);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/workflows/action/copy`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const apiResponseDTO = new APIResponseDTO<Array<WorkflowAction>>(response.data);

        try {
            const items: Array<WorkflowAction> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async copyWorkflowActionRule(workflowGARule: WorkflowGARule): Promise<GetSingleItemResult<WorkflowRuleDTO>> {
        const data = JSON.stringify(workflowGARule);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/workflows/group/rule/copy`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const apiResponseDTO = new APIResponseDTO<WorkflowRuleDTO>(response.data);

        try {
            const item: WorkflowRuleDTO = apiResponseDTO.payload;
            return {success: true, item}
        } catch {
            return {success: false, item: new WorkflowRuleDTO()}
        }
    }

    public async getAllWorkflowActionStatuses(): Promise<GetGeneralListSuccessResult<ActionStatus>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/workflows/group/group-action/status/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<ActionStatus>>(response.data);

        try {
            const items: Array<ActionStatus> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getAllWorkflowActions(): Promise<GetGeneralListSuccessResult<WorkflowAction>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/workflows/group/action/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<WorkflowAction>>(response.data);

        try {
            const items: Array<WorkflowAction> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getAllWfActivityTypes(): Promise<GetGeneralListSuccessResult<WfActivityType>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/workflows/group/action/types/get-all`,
        })

        if (!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<WfActivityType>>(response.data);

        try {
            const items: Array<WfActivityType> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async saveWorkflowAction(workflowGroupAction: WorkflowGroupAction): Promise<GetGeneralListSuccessResult<WorkflowGroupAction>> {
        const data = JSON.stringify(workflowGroupAction);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/workflows/group/group-action/update`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (!response.data) {
            return {success: false, items: []}
        }

        try {
            const apiResponseDTO = new APIResponseDTO<Array<WorkflowGroupAction>>(response.data);
            const items: Array<WorkflowGroupAction> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async saveWorkflowFields(workflowFields: Array<WfField>): Promise<GetGeneralListSuccessResult<WfField>> {
        const data = JSON.stringify(workflowFields);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/workflows/field/update`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const apiResponseDTO = new APIResponseDTO<Array<WfField>>(response.data);

        try {
            const items: Array<WfField> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async saveWorkflowConditions(workflowActions: Array<WorkflowCondition>): Promise<GetGeneralListSuccessResult<WorkflowCondition>> {
        const data = JSON.stringify(workflowActions);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/workflows/group/condition/update`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const apiResponseDTO = new APIResponseDTO<Array<WorkflowCondition>>(response.data);

        try {
            const items: Array<WorkflowCondition> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getWorkflowFieldData(): Promise<GetSingleItemResult<WfFieldDTO>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/workflows/field-data/get-all`,
        })

        const apiResponseDTO = new APIResponseDTO<WfFieldDTO>(response.data);

        if (!response.data) return {success: false, message: response.data}
        if (typeof response.data !== 'object') return { success: false, message: response.data }
        return {success: true, item: apiResponseDTO.payload};
    }

    public async validateWorkflowFieldData(): Promise<GetSingleItemResult<String>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/workflows/field-data/validate`,
        })

        const apiResponseDTO = new APIResponseDTO<String>(response.data);

        if (!response.data) return {success: false, message: response.data}
        if (typeof response.data !== 'object') return { success: false, message: response.data }
        return {success: true, item: apiResponseDTO.payload};
    }

    public async saveWorkflowGARules(workflowGARules: Array<WorkflowGARule>): Promise<GetGeneralListSuccessResult<WorkflowGARule>> {
        const data = JSON.stringify(workflowGARules);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/workflows/group/rule/update`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const apiResponseDTO = new APIResponseDTO<Array<WorkflowGARule>>(response.data);

        try {
            const items: Array<WorkflowGARule> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async saveWorkflowActions(workflowActions: Array<WorkflowAction>): Promise<GetGeneralListSuccessResult<WorkflowAction>> {
        const data = JSON.stringify(workflowActions);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/workflows/group/action/update`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const apiResponseDTO = new APIResponseDTO<Array<WorkflowAction>>(response.data);

        try {
            const items: Array<WorkflowAction> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async saveWorkflowGroups(workflowGroups: Array<WorkflowGroup>): Promise<GetGeneralListSuccessResult<WorkflowGroup>> {
        const data = JSON.stringify(workflowGroups);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/workflows/group/update`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const apiResponseDTO = new APIResponseDTO<Array<WorkflowGroup>>(response.data);

        try {
            const items: Array<WorkflowGroup> = apiResponseDTO.payload;
            return {success: true, items}
        } catch {
            return {success: false, items: []}
        }
    }

    public async getProposalControlRules(): Promise<GetGeneralListSuccessResult<ControlRuleDTO>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/control-rule/get-all`
        })
        if(!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<ControlRuleDTO>>(response.data);
        const items: Array<ControlRuleDTO> = apiResponseDTO.payload;
        return {success: true, items};
    }

    public async getAllSampleCarriers(): Promise<GetGeneralListSuccessResult<SampleCarrier>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/sample-carriers/get-all`
        })
        if(!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<SampleCarrier>>(response.data);
        const items: Array<SampleCarrier> = apiResponseDTO.payload;
        return {success: true, items};
    }

    public async getAllSkillGroups(): Promise<GetGeneralListSuccessResult<SkillGroup>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/skillGroups/get-all`
        })
        if(!response.data) return {success: false, items: []}

        const apiResponseDTO = new APIResponseDTO<Array<SkillGroup>>(response.data);
        const items: Array<SkillGroup> = apiResponseDTO.payload;
        return {success: true, items};
    }

    public async getAllMomentumProducts(): Promise<GetGeneralListSuccessResult<MomentumProduct>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/momentumProductTypes/get-all`
        })
        if(!response.data) return {success: false, items: []};

        const apiResponseDTO = new APIResponseDTO<Array<MomentumProduct>>(response.data);
        const items: Array<MomentumProduct> = apiResponseDTO.payload;
        return {success: true, items};
    }

    public async getAllMomentumCustomers(): Promise<GetGeneralListSuccessResult<MomentumCustomer>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/momentumCustomers/get-all`
        })
        if(!response.data) return {success: false, items: []};

        const apiResponseDTO = new APIResponseDTO<Array<MomentumCustomer>>(response.data);
        const items: Array<MomentumCustomer> = apiResponseDTO.payload;
        return {success: true, items};
    }

    public async addMomentumCustomer(customer: MomentumCustomer): Promise<GetSingleItemResult<MomentumCustomer>> {
        const data = JSON.stringify(customer);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/momentumCustomers/add`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }

        })
        if(!response.data) return {success: false, item: new MomentumCustomer()};
        const apiResponseDTO = new APIResponseDTO<MomentumCustomer>(response.data);
        const customerResponse = apiResponseDTO.payload;
        return {success: true, item: customerResponse};
    }

    public async addMomentumProductType(productType: MomentumProduct): Promise<GetSingleItemResult<MomentumProduct>> {
        const data = JSON.stringify(productType);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/momentumProductTypes/add`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }

        })
        if(!response.data) return {success: false, item: new MomentumProduct()};
        const apiResponseDTO = new APIResponseDTO<MomentumProduct>(response.data);
        const productTypeResponse = apiResponseDTO.payload;
        return {success: true, item: productTypeResponse};
    }

    public async removeMomentumCustomer(customerID: number): Promise<GetGeneralSuccessResult> {
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/momentumCustomers/remove/${customerID}`,
        })
        if(response.status == 200)
            return {success: true};
        else
            return {success: false};
    }

    public async removeMomentumProductType(productTypeID: number): Promise<GetGeneralSuccessResult> {
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/momentumProductTypes/remove/${productTypeID}`,
        })
        if(response.status == 200)
            return {success: true};
        else
            return {success: false};
    }

    public async updateMomentumCustomer(customer: MomentumCustomer): Promise<GetGeneralSuccessResult> {
        const data = JSON.stringify(customer);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/momentumCustomers/update`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if(response.status == 200)
            return {success: true};
        else
            return {success: false};
    }

    public async getLocations(): Promise<GetGeneralListSuccessResult<Location>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/maintenance/location/get-all`
        })
        if(!response.data) return {success: false, items: []};

        const apiResponseDTO = new APIResponseDTO<Array<Location>>(response.data);
        const items: Array<Location> = apiResponseDTO.payload;
        return {success: true, items};
    }

    public async getUserLocations(): Promise<GetGeneralListSuccessResult<UserLocation>> {
        const response: AxiosResponse = await axios({
            method: 'get',
            url: `${BASE_API_URL}/users/user-location/get-all`
        })
        if(!response.data) return {success: false, items: []};

        const apiResponseDTO = new APIResponseDTO<Array<UserLocation>>(response.data);
        const items: Array<UserLocation> = apiResponseDTO.payload;
        return {success: true, items};
    }


    public async addLocation(location: Location): Promise<GetGeneralListSuccessResult<Location>> {
        const data = JSON.stringify(location);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/location/update`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if(!response.data) return {success: false, items: []};

        const apiResponseDTO = new APIResponseDTO<Array<Location>>(response.data);
        const items: Array<Location> = apiResponseDTO.payload;
        return {success: true, items};
    }

    public async deleteLocation(locationId: number): Promise<GetGeneralListSuccessResult<Location>> {
        const data = JSON.stringify(locationId);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/maintenance/location/delete`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if (!response.data) return {success: false, items: []};

        const apiResponseDTO = new APIResponseDTO<Array<Location>>(response.data);
        const items: Array<Location> = apiResponseDTO.payload;
        return {success: true, items};
    }

    public async addUserLocations(userLocations: Array<UserLocation>): Promise<GetGeneralListSuccessResult<UserLocation>> {
        const data = JSON.stringify(userLocations);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/users/user-location/add`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if (!response.data) return {success: false, items: []};

        const apiResponseDTO = new APIResponseDTO<Array<UserLocation>>(response.data);
        const items: Array<UserLocation> = apiResponseDTO.payload;
        return {success: true, items};
    }

    public async deleteUserLocation(userLocationId: number): Promise<GetGeneralListSuccessResult<UserLocation>> {
        const data = JSON.stringify(userLocationId);
        const response: AxiosResponse = await axios({
            method: 'post',
            url: `${BASE_API_URL}/users/user-location/delete`,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if (!response.data) return {success: false, items: []};

        const apiResponseDTO = new APIResponseDTO<Array<UserLocation>>(response.data);
        const items: Array<UserLocation> = apiResponseDTO.payload;
        return {success: true, items};
    }


}