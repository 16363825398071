import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_radio = _resolveComponent("b-form-radio")!

  return (_openBlock(), _createElementBlock("div", _mergeProps(_ctx.attrs, {
    id: _ctx.computedId,
    role: "radiogroup",
    class: [_ctx.classes, "bv-no-focus-ring"],
    tabindex: "-1"
  }), [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkboxList, (item, key) => {
      return (_openBlock(), _createBlock(_component_b_form_radio, _mergeProps({
        key: key,
        modelValue: _ctx.localValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event))
      }, item.props, {
        class: _ctx.inline? 'form-control-inline' : '' 
      }), {
        default: _withCtx(() => [
          (item.html)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                innerHTML: item.html
              }, null, 8, _hoisted_2))
            : (_openBlock(), _createElementBlock("span", {
                key: 1,
                textContent: _toDisplayString(item.text)
              }, null, 8, _hoisted_3))
        ]),
        _: 2
      }, 1040, ["modelValue", "class"]))
    }), 128))
  ], 16, _hoisted_1))
}