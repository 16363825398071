

import {defineComponent, computed} from 'vue';
import {LoadingType} from "@/store/LoadingStore";
import rootStore from "@/store/RootStore";

type LoadingProps = {
    message: string;
    icon: string;
    animation: string;
    fullScreen?: boolean;
    progress?: boolean;
}

export default defineComponent({
    name: 'loading',
    components: {},
    props: {},
    setup() {

        const loadingStore = rootStore.getInstance().loadingStore;

        const loadingProps = computed((): LoadingProps => {
            switch (loadingStore.loadingType) {
                case LoadingType.copying:
                    return {
                        message: "Copying...",
                        // icon: "layers-half",
                        icon: "fa-solid fa-fade fa-layer-group",
                        animation: "fade",
                    }
                case LoadingType.compBomTable:
                    return {
                        message: "loading bom data...",
                        // icon: "table",
                        icon: "fa-solid fa-fade fa-table",
                        animation: "fade",
                    }
                case LoadingType.progress:
                    return {
                        message: "Loading",
                        icon: "",
                        animation: "",
                        progress: true,
                        fullScreen: true,
                    }
                default:
                    return {
                        message: "Loading...",
                        icon: "",
                        animation: "",
                        fullScreen: true,
                    }
            }
        })

        return {
            loadingProps,
            loadingStore,
        }
    }
})

